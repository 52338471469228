import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoginService } from 'src/app/login/login.service';
import { UserService } from '../../users/user.service';
import { SalesService } from '../sales.service';

@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class EditOrderComponent implements OnInit {

  constructor(
    private salesService: SalesService,
    private userService: UserService,
    private loginService: LoginService,
    private messageService: MessageService,
    private router: Router,
    private activeroute: ActivatedRoute) { }

  availableProducts: any;
  allOrders: any;
  allUsers: any;
  allClients: any;
  selectedProduct: any;
  selectedProductMax: any;
  selectedProductQuantity: any;
  selectedClient: any;
  currentUser: any;

  orderId: any;
  orderInfo: any;

  productRowSelected: any;

  loadingStatus: boolean = true;
  wrongStatus: boolean = false;
  clientStatus: boolean = false;
  adminStatus: boolean = false;

  msg: any

  orderForm = {
    Client: '',
    ClientId: null,
    Invoice: false,
    Location: '',
    Status: null,
    Price: 0,
    Salesman: null,
    SuggestedPrice: 0,
    Extras: '',
    Phone: null,
    Products: []
  }

  ngOnInit(): void {
    this.salesService.getProductsAvailable().subscribe(data => { this.availableProducts = data; this.initFunction(); });
    this.salesService.getClients().subscribe(data => { this.allClients = data; this.initFunction(); });
    this.userService.getUsersInformation().subscribe(data => {
      this.allUsers = data
      this.initFunction();
      this.loginService.getUserId().subscribe(res => {
        let userId = res.clientid;
        let userModules = res.modules;
        userModules.forEach(item => { if (item == "dashboard") { this.adminStatus = true; } });
        this.currentUser = null;
        this.allUsers.forEach(item => { if (userId == item.clientId) { this.currentUser = item; } });
        this.orderForm.Salesman = this.currentUser;
      })
    });

    this.activeroute.params.subscribe(params => {
      if (this.activeroute.snapshot.params.id > 0) {
        this.orderId = this.activeroute.snapshot.params.id;
        this.salesService.getOrderDetails(this.orderId).subscribe(data => { this.orderInfo = data; this.initFunction(); });
      }
    });
  }

  initFunction() {
    if (this.allClients != null && this.allUsers != null && this.orderInfo != null && this.availableProducts != null) {
      if (this.orderInfo.orderStatus == 1) {
        this.setOrderInformation();
      } else {
        this.loadingStatus = false;
        this.wrongStatus = true;
      }
    }
  }
  sendActionNotification(data)
  {
    let notification = {
      ID: data.id,
      Acción: data.name ,
      Productos: data.products,
      Vendedor: this.orderForm.Salesman.name,
      Precio: this.orderForm.Price,
      "Precio Sugerido": this.orderForm.SuggestedPrice,
      Cliente: this.orderForm.Client
    }

    this.salesService.sendNotification(notification).subscribe();
  }
  isAdmin()
  {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    let userModules = currentUser.modules
    userModules.forEach(obj => {
      if (obj == "dashboard")
      {return true}
      else
      {return false}
     });
    return false
  }
  setOrderInformation() {
    this.orderForm = {
      Client: this.orderInfo.client,
      ClientId: null,
      Invoice: this.orderInfo.invoice,
      Location: this.orderInfo.location,
      Status: this.orderInfo.orderStatus,
      Price: this.orderInfo.price,
      Salesman: null,
      SuggestedPrice: this.orderInfo.suggestedPrice,
      Extras: this.orderInfo.extras,
      Phone: this.orderInfo.clientPhone,
      Products: []
    }

    let selected: any;

    if (this.orderInfo.clientId) {
      this.clientStatus = true;
      this.allClients.forEach(item => { if (item.id == this.orderInfo.clientId) { selected = item; } });
      this.selectedClient = selected;
    }

    this.allUsers.forEach(item => { if (item.clientId == this.orderInfo.salesman.id) { selected = item; } });
    this.orderForm.Salesman = selected

    this.orderInfo.products.forEach(item => {
      let obj = {
        id: item.id,
        pieces: item.quantity,
        name: item.name,
        netcontent: null,
        price: item.priceAtTheTime,
        priceNight: item.priceAtTheTime,
        totalpieces: item.quantity
      };
      this.orderForm.Products.push(obj);

      let productInList = false
      this.availableProducts.forEach(product => {
        if (product.id == item.id) {
          product.totalpieces = product.totalpieces + item.quantity;
          productInList = true;
        }
      });
      if (!productInList) { this.availableProducts.push(obj); }
    });


    this.loadingStatus = false;
  }

  clearForm() {
    this.orderForm = {
      Client: '',
      ClientId: null,
      Invoice: false,
      Location: '',
      Status: null,
      Price: 0,
      Salesman: '',
      SuggestedPrice: 0,
      Extras: '',
      Phone: null,
      Products: []
    }
  }

  onRowSelect(event) {
    this.productRowSelected = event.data;
  }

  onRowUnselect(event) {
    this.productRowSelected = null;

  }

  clientChange() {
    this.orderForm = {
      Client: this.selectedClient.client,
      ClientId: this.selectedClient.id,
      Invoice: false,
      Location: this.selectedClient.location,
      Status: null,
      Price: 0,
      Salesman: '',
      SuggestedPrice: 0,
      Extras: '',
      Phone: this.selectedClient.phone,
      Products: []
    }
  }

  productChange() {
    this.selectedProductMax = this.selectedProduct.totalpieces;
    if (this.selectedProductMax < 20) {
      this.selectedProductQuantity = this.selectedProductMax;
    } else { this.selectedProductQuantity = 20; }
  }

  addProductValidation() {
    let addedProduct = false;
    this.msg = [];

    if (this.selectedProduct != null) {
      if (this.orderForm.Products.length > 0) {
        this.orderForm.Products.forEach(item => {
          if (this.selectedProduct.id == item.id) { addedProduct = true; }
        })
      }
    } else {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Seleccione un producto' });
    }
    if (this.selectedProductQuantity == null || this.selectedProductQuantity == 0) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Ingrese cantidad de producto' });
    } else if (addedProduct) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Producto ya en lista' });
    } else if (this.selectedProductQuantity > this.selectedProductMax) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Cantidad excede las piezas existentes' });
    } else {
      this.addProductToOrder();
    }
  }

  addProductToOrder() {
    let item = {
      id: this.selectedProduct.id,
      name: this.selectedProduct.name,
      netcontent: this.selectedProduct.netcontent,
      price: this.selectedProduct.price,
      pieces: this.selectedProductQuantity
    }
    var currentTime: Date = new Date();
    // CHECK IF PASS AFETER 22HRS TO CHOOSE PRICE
    if (currentTime.getHours() >= 9 && currentTime.getHours() < 22) {
      Object.assign(item, { price: this.selectedProduct.price });
    } else {
      Object.assign(item, { price: this.selectedProduct.priceNight });
    }
    this.orderForm.Products.push(item);
    this.updateTotalPrice();
  }

  deleteProduct(item) {
    let index = this.orderForm.Products.indexOf(item)
    this.orderForm.Products.splice(index, 1);
    this.updateTotalPrice();
  }

  updateTotalPrice() {
    this.orderForm.SuggestedPrice = 0;
    this.orderForm.Products.forEach(item => {
      this.orderForm.SuggestedPrice += item.price * item.pieces;
    })
    this.orderForm.Price = this.orderForm.SuggestedPrice;
  }

  editOrderValidation() {
    this.msg = [];
    const { Client, Location, Price, Products, Phone, Invoice } = this.orderForm;

    if (this.clientStatus && this.selectedClient == null) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Seleccione Cliente existente' });
    } else if (Client == '') {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Cliente es requerido' });
    } else if (Location == '') {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Ubicación es requerida' });
    } else if (Price == null) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Precio es requerida' });
    } else if (Products.length == 0) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Ingrese al menos un producto' });
    } else if (Invoice && Phone < 999999999) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Telefono requerido si factura' });
    } else {
      this.createOrder();
    }
  }

  formatProducts() {
    let list = [];
    let stringProducts = "";
    this.orderForm.Products.forEach(item => {
      let obj = {
        id: item.id,
        quantity: item.pieces,
        price: item.price,
      };
      stringProducts =  stringProducts + "," + item.name + ":" + item.pieces
      list.push(obj);
    })
    return [list, stringProducts];
  }

  createOrder() {
    const [formatedProducts,stringProducts] = this.formatProducts();
    if (this.orderForm.Salesman.clientId == null) { this.orderForm.Salesman = this.currentUser }

    let data = {
      action: "update",
      id: this.orderId,
      client: this.orderForm.Client,
      price: this.orderForm.Price,
      suggestedPrice: this.orderForm.SuggestedPrice,
      invoice: this.orderForm.Invoice,
      location: this.orderForm.Location,
      products: formatedProducts,
      salesman: this.orderForm.Salesman.clientId
    }
    if (this.orderForm.Phone) { Object.assign(data, { clientPhone: this.orderForm.Phone }); }
    if (this.orderForm.Extras) { Object.assign(data, { extras: this.orderForm.Extras }); }
    if (this.clientStatus) { Object.assign(data, { clientId: this.orderForm.ClientId }); }


    this.salesService.OrderActions(data).subscribe(ID => {
      let action = {
        id: ID,
        name: 'Orden Editada',
        products: stringProducts
      }
      if(!this.isAdmin()) {this.sendActionNotification(action)}
      this.router.navigateByUrl('/system/sales/orderList?ID=' + ID)
    })
  }

}
