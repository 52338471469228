import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.baseUrl;

  getDashboardInfo() {
    return this.http.get(`${this.baseUrl}Dashboard`, { responseType: 'json' })
  }
}
