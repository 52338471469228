<div class="orderListContainer">
    <div style="display: flex;justify-content: end;">
        <button pButton type="button" class="phoneShow" label="Mostrar detalles" style="margin-right: 10px;"
            (click)="openOrderDetails(orderRowSelected)" [disabled]="!orderRowSelected"></button>
    </div>

    <p-table #dt1
    [value]="allOrders" [paginator]="true"
    [lazy]="true"
    (onLazyLoad)="getLazyOrders($event)"
    [totalRecords]="totalRecords"
    [rows]="9" [scrollable]="true" selectionMode="single" [(selection)]="orderRowSelected"
        dataKey="id" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)">

        <ng-template pTemplate="header">

            <div class="p-d-flex p-jc-start">
                <div class="p-pr-2">Factura:</div>
                <p-tristatecheckbox class="custom-tristate-checkbox" [ngClass]="{'selected': isSelectedInvoice}"
                (click)="invoiceFilter()"></p-tristatecheckbox>
            </div>

            <tr>
                <th class="phoneHide">N. de Orden</th>
                <th>Cliente
                    <p-columnFilter field="clientId" display="menu" [showMatchModes]="false"
                        [showOperator]="false" [showAddButton]="false" [showApplyButton]="false" [showClearButton] ="false">
                        <ng-template pTemplate="filter">
                            <p-dropdown [options]="allClients" [(ngModel)]="this.lazyData.client" optionLabel="client"
                            optionValue="id" placeholder="Any" [filter]="true" (onChange)="tableFilter()">
                          </p-dropdown>
                          <div class="p-column-filter-buttonbar">
                            <button pButton class="p-button-outlined idFilter" (click)="clearClient()">Clear</button>
                          </div>
                        </ng-template>
                    </p-columnFilter>
                </th>
                <th>Vendedor</th>
                <th>Fecha y hora</th>
                <th>Status</th>
                <th class="phoneHide">Detalles</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order>
            <tr [pSelectableRow]="order">
                <td class="phoneHide">{{order.id}}</td>
                <td>{{order.client}}</td>
                <td>{{order.salesman}}</td>
                <td>{{order.orderDate | date:'short'}}</td>
                <td class="centerText">
                    <i class="pi pi-circle-on" [ngStyle]="{'color': order.orderStatusColor}"></i>
                </td>
                <td class="phoneHide"><button pButton pRipple type="button" icon="pi pi-book" class="p-button-rounded"
                        (click)="openOrderDetails(order)"></button></td>
            </tr>
        </ng-template>
    </p-table>
</div>


<p-dialog header="Detalles de orden" [(visible)]="orderDetailsModalStatus" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <!-- [style]="{width: '35vw'}" -->
    <p-messages [(value)]="msg"></p-messages>
    <div class="p-grid">
        <div class="p-col-6"><span>ID : </span>{{orderForm.Id}}</div>
        <div class="p-col-6"><span>ClientId : </span>{{orderForm.ClientId}}</div>
        <div class="p-col-6"><span>Cliente : </span>{{orderForm.Client}}</div>
        <div class="p-col-6"><span>Fecha : </span>{{orderForm.Date | date:'short'}}</div>
        <div class="p-col-6"><span>Status : </span>
            <span [style]="{'color': orderForm.Color}">
                <i class="pi {{orderForm.Icon}}"></i>
                {{orderForm.Status}}
                <span *ngIf="orderForm.Delivery">{{getDeliveryTime()}}</span>
            </span>

        </div>
        <div class="p-col-6"><span>Factura : </span>{{orderForm.Invoice}}</div>
        <div class="p-col-6"><span>Vendedor : </span>{{orderForm.Salesman}}</div>
        <div class="p-col-6"><span>Ubicación : </span>
            <a *ngIf="orderForm.Location!=null && isURL" href="{{orderForm.Location}}" target="_blank"
                style="text-decoration: none;color: #CAA462;">Abrir en Maps</a>
            <span *ngIf="!isURL">{{orderForm.Location}}</span>
        </div>
        <div class="p-col-6"><span>Telefono : </span>{{orderForm.Phone}}</div>
        <div class="p-col-6 padding-0">

        </div>
        <p-table [value]="orderForm.Products" styleClass="p-col-12" [scrollable]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th>Cantidad</th>
                    <th>Nombre</th>
                    <th>Precio de compra</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
                <tr>
                    <td>{{order.quantity}}</td>
                    <td>{{order.name}}</td>
                    <td>{{order.priceAtTheTime}}</td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-col-12 p-d-flex p-jc-between">
            <span>Extras : {{orderForm.Extras}}</span>
            <p-button *ngIf="orderForm.OrderStatus!=3" label="Origen de productos" styleClass="p-button-secondary"
                (click)="productsOriginModalStatus=true">
            </p-button>
        </div>
        <div class="p-col-12 padding-0">
            <span style="float: right;">Precio sugerido : {{orderForm.SuggestedPrice}}</span>
        </div>
        <div class="p-col-12 padding-0">
            <span style="float: right;font-weight: bolder;">Precio final : {{orderForm.Price}}</span>
        </div>

    </div>
    <ng-template pTemplate="footer">
        <div *ngIf="orderForm.OrderStatus==1" class="p-d-flex p-jc-between">
            <p-button label="Cancelar Orden" styleClass="p-button-secondary" (click)="CancelOrder()">
            </p-button>
            <p-button label="Editar Orden" styleClass="p-button-secondary" (click)="editOrder()">
            </p-button>
            <p-button label="Confirmar entrega" styleClass="p-button-secondary" (click)="confirmDelivery()">
            </p-button>
        </div>
        <p-button *ngIf="orderForm.OrderStatus!=1" label="Cerrar ventana" styleClass="p-button-secondary"
            (click)="orderDetailsModalStatus=false">
        </p-button>
    </ng-template>
</p-dialog>

<p-dialog header="Origen de Producto" [(visible)]="productsOriginModalStatus" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <p-messages [(value)]="msg"></p-messages>
    <div class="p-grid">

        <p-table [value]="orderForm.Origin" styleClass="p-datatable-striped p-col-12" [scrollable]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th>Cantidad</th>
                    <th>Producto</th>
                    <th>Almacen</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
                <tr>
                    <td>{{order.substracted}}</td>
                    <td>{{order.productName}}</td>
                    <td>{{order.warehouseName}}</td>
                </tr>
            </ng-template>
        </p-table>

    </div>
    <ng-template pTemplate="footer">
        <p-button label="Cerrar ventana" styleClass="p-button-secondary" (click)="productsOriginModalStatus=false">
        </p-button>
    </ng-template>
</p-dialog>
