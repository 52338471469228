import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/login/login.service';
import { MenuService } from './menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {

  constructor(private loginservice: LoginService, private menuService: MenuService, private router: Router) { }

  menuModules: any;
  userId: any;
  display: boolean = false;
  InventorySubmodule: boolean = false;
  SalesSubmodule: boolean = false;

  mobDeviceFlag: boolean = false;

  Logo = "assets/Images/menuLogo.svg"
  logoOnly = "assets/Images/logoOnly.svg";
  horizontalLogo = "assets/Images/horizontalLogo.svg";

  ngOnInit(): void {
    if (window.innerWidth < 768) { this.mobDeviceFlag = true }
    this.loginservice.getUserId().subscribe(res => {
      this.userId = res.clientid;
      this.menuService.getUserModulesById(this.userId).subscribe(res => {
        this.menuModules = res;
      });
    });
  }

  @HostListener('window:resize', ['$event'])
  onresize(event) {
    if (window.innerWidth < 768) { this.mobDeviceFlag = true }
    else { this.mobDeviceFlag = false }
  }

  logout() {
    this.loginservice.logout()
    this.router.navigateByUrl('');
  }
}
