import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from './login/login.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private loginService: LoginService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.loginService.currentUserValue;
        if (currentUser) {
            let currentModule = state.url.split("/")
            // verify if the user has access to the requested module
            let grantedAccess = false;
            currentUser.modules.forEach(route => {
                if (currentModule.length > 3) {
                    if (currentModule[2] == route) { grantedAccess = true }
                } else {
                    if (currentModule[2] == "sales") { this.router.navigate(['/system/sales/newOrder']); }
                    if (state.url == '/system/' + route) { grantedAccess = true }
                }
            });
            if (grantedAccess) { return true; }
            else {
                this.router.navigate(['/system/' + currentUser.modules[0]]);
                return false;
            }
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['login']);
        return false;
    }
}