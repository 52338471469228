import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.baseUrl;

  getUsersInformation() {
    return this.http.get(`${this.baseUrl}Users`, { responseType: 'json' })
  }

  createUser(data) {
    return this.http.post(`${this.baseUrl}Users`, data, { responseType: 'text' })
  }

  deleteUser(data) {
    return this.http.post(`${this.baseUrl}Users`, data, { responseType: 'text' })
  }

  editUser(data) {
    return this.http.post(`${this.baseUrl}Users`, data, { responseType: 'text' })
  }

  getRoles() {
    return this.http.get(`${this.baseUrl}Roles`, { responseType: 'json' })
  }

  deleteRole(data) {
    return this.http.post(`${this.baseUrl}Roles`, data, { responseType: 'text' })
  }

  createRole(data) {
    return this.http.post(`${this.baseUrl}Roles`, data, { responseType: 'text' })
  }

  editRole(data) {
    return this.http.post(`${this.baseUrl}Roles`, data, { responseType: 'text' })
  }

  getModules() {
    return this.http.get(`${this.baseUrl}MenuModules`, { responseType: 'json' })
  }

}
