<div class="userMainContainer">

    <div class="salesTitle">Usuarios</div>

    <div class="userSubContainer">
        <div class="p-d-flex p-jc-between ">
            <span style="padding:8px;">Administracion de usuarios</span>
            <div class="phoneHide">
                <button pButton type="button" label="Cargos" class="p-button-secondary" style="margin-right: 10px;"
                    (click)=rolesModal()></button>
                <button pButton type="button" class="p-button-help" label="Crear usuario" icon="pi pi-user-plus "
                    (click)="createUserModal()"></button>
            </div>
        </div>

        <p-table class="userList" [paginator]="true" [paginatorPosition]="isMobileDevice ? 'top' : 'bottom'"
          [rows]="10" [value]="userList" [scrollable]="true" selectionMode="single"
            [(selection)]="userRowSelected" dataKey="clientId" (onRowSelect)="onRowSelect($event)"
            (onRowUnselect)="onRowUnselect($event)">
            <ng-template pTemplate="header">
                <tr>
                    <th>Nombre</th>
                    <th>Usuario</th>
                    <th>Telefono</th>
                    <th class="phoneHide">Cargo</th>
                    <th class="phoneHide">Opciones</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-user>
                <tr [pSelectableRow]="user">
                    <td>{{user.fullname}}</td>
                    <td>{{user.username}}</td>
                    <td>{{user.phone}}</td>
                    <td class="phoneHide">{{user.role}}</td>
                    <td class="phoneHide">
                        <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded"
                            style="margin-right: 10px;" pTooltip="Editar" tooltipPosition="top"
                            (click)="editUserModal(user)"></button>
                        <button pButton pRipple type="button" icon="pi pi-trash"
                            class="p-button-rounded p-button-danger" pTooltip="Eliminar" tooltipPosition="top"
                            (click)="deleteUserModal(user.username)"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>


        <div class="phoneShow" style="height: 50px;position: fixed; bottom: 0px;width: 100%; background: #0d111c;">
            <div class="p-d-flex p-ai-center" style="height: 100%;padding: 8px;">
                <button pButton type="button" label="Cargos" class="p-button-secondary" style="margin-right: 10px;"
                    (click)=rolesModal()></button>
                <button pButton type="button" class="p-button-help" label="Crear usuario" icon="pi pi-user-plus "
                    (click)="createUserModal()"></button>

                <button class="trashIcon" (click)="deleteUserModal(userRowSelected.username)" pTooltip="Eliminar"
                    tooltipPosition="top" style="margin-left: 12px; height: 25px;width: 23px;"
                    [ngStyle]="{'opacity': !userRowSelected ? '0.3' : '1' }" [disabled]="!userRowSelected">
                </button>
                <button class="optionsIcon" (click)="editUserModal(userRowSelected)" pTooltip="Modificar"
                    tooltipPosition="top" style="margin-left: 12px;height: 25px;width: 25px;"
                    [ngStyle]="{'opacity': !userRowSelected ? '0.3' : '1' }" [disabled]="!userRowSelected"></button>
            </div>


        </div>
    </div>


    <p-dialog header="Crear Usuario" [(visible)]="UserModalStatus" [modal]="true" [baseZIndex]="10000"
        [draggable]="false" [resizable]="false">
        <p-messages [(value)]="msg"></p-messages>
        <div class="p-grid" style="padding: 8px;">
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">Nombre:</div>
                <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="createForm.Name">
            </div>
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">Apellidos:</div>
                <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="createForm.LastName">
            </div>
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">Telefono:</div>
                <p-inputNumber [(ngModel)]="createForm.Phone" mode="decimal" [style]="{width: '100%'}"
                    [useGrouping]="false" [maxlength]="10" [minlength]="10"></p-inputNumber>
            </div>
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">Nombre de usuario:</div>
                <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="createForm.Username">
            </div>
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">Clave:</div>
                <input type="password" pInputText [style]="{width: '100%'}" [(ngModel)]="createForm.Password">
            </div>
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">Repita clave:</div>
                <input type="password" pInputText [style]="{width: '100%'}" [(ngModel)]="createForm.PasswordRepeat">
            </div>
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">Cargo:</div>
                <p-dropdown [options]="roles" [(ngModel)]="createForm.Role" optionLabel="role"
                    placeholder="Seleccione un cargo" [style]="{width: '100%'}"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">Modulos:</div>
                <p-multiSelect [options]="modules" [(ngModel)]="createForm.Modules" defaultLabel="Seleccione Modulos"
                    optionLabel="module" [filter]="false" [style]="{width: '100%'}"></p-multiSelect>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="createUserValidation()" label="Agregar" styleClass="p-button-success">
            </p-button>
        </ng-template>
    </p-dialog>

    <p-dialog header="Editar Usuario" [(visible)]="UserEditStatus" [modal]="true" [baseZIndex]="10000"
        [draggable]="false" [resizable]="false">
        <p-messages [(value)]="msg"></p-messages>
        <div class="p-grid" style="padding: 8px;">
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">Nombre:</div>
                <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="editForm.Name">
            </div>
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">Apellidos:</div>
                <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="editForm.LastName">
            </div>
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">Telefono:</div>
                <p-inputNumber [(ngModel)]="editForm.Phone" mode="decimal" [style]="{width: '100%'}"
                    [useGrouping]="false" [maxlength]="10"></p-inputNumber>
            </div>
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">Nombre de usuario:</div>
                <input disabled="true" type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="editForm.Username">
            </div>
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">Cargo:</div>
                <p-dropdown [options]="roles" [(ngModel)]="editForm.Role" optionLabel="role"
                    placeholder="Seleccione un cargo" [style]="{width: '100%'}"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">Modulos:</div>
                <p-multiSelect [options]="modules" [(ngModel)]="editForm.Modules" defaultLabel="Seleccione Modulos"
                    optionLabel="module" [filter]="false" [style]="{width: '100%'}"></p-multiSelect>
            </div>
            <div class="p-col-12">
                <span style="margin: 10px;">Cambiar contraseña</span>
                <p-inputSwitch [(ngModel)]="changePassword"></p-inputSwitch>
            </div>
            <div class="p-grid padding-0" style="width: 100%;" *ngIf="changePassword">
                <div class="p-col-12 p-md-6">
                    <div class="inputLabel">Nueva clave:</div>
                    <input type="password" pInputText [style]="{width: '100%'}" [(ngModel)]="editForm.Password">
                </div>
                <div class="p-col-12 p-md-6">
                    <div class="inputLabel">Repita clave:</div>
                    <input type="password" pInputText [style]="{width: '100%'}" [(ngModel)]="editForm.PasswordRepeat">
                </div>
            </div>

        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="editUserValidation()" label="Guardar" styleClass="p-button-success">
            </p-button>
        </ng-template>
    </p-dialog>

    <p-dialog header="Borrar Usuario" [(visible)]="UserDeleteStatus" [modal]="true" [baseZIndex]="10000"
        [draggable]="false" [resizable]="false">
        <div style="padding: 8px;">¿Seguro que desea eliminar el usuario?</div>
        <ng-template pTemplate="footer">
            <p-button (click)="UserDeleteStatus=false" label="No" styleClass="p-button-text"></p-button>
            <p-button (click)="deleteUser()" label="Si" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>

    <p-dialog header="Cargos" [(visible)]="RolesModalStatus" [modal]="true" [baseZIndex]="10000" [draggable]="false"
        [resizable]="false">
        <div class="p-d-flex p-jc-end">
            <button pButton type="button" class="p-button-help" label="Crear cargo" icon="pi pi-users "
                (click)="RoleCreateStatus=true" style="margin: 8px;"></button>
        </div>
        <p-table class="roleList" [value]="roles" [scrollable]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th>Cargo</th>
                    <th>Usuarios con ese cargo</th>
                    <th>Opciones</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-role>
                <tr>
                    <td>{{role.role}}</td>
                    <td>{{role.count}}</td>
                    <td>
                        <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded"
                            style="margin-right: 10px;" pTooltip="Editar" tooltipPosition="top"
                            (click)="openRoleEditModal(role)"></button>
                        <button *ngIf="role.count == 0" pButton pRipple type="button" icon="pi pi-trash"
                            class="p-button-rounded p-button-danger" pTooltip="Eliminar" tooltipPosition="top"
                            (click)="deleteRoleModal(role)"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <ng-template pTemplate="footer">
            <p-button (click)="RolesModalStatus=false" label="Cerrar" styleClass="p-button-secondary"></p-button>
        </ng-template>
    </p-dialog>

    <p-dialog header="Borrar cargo" [(visible)]="RoleDeleteStatus" [modal]="true" [baseZIndex]="10001"
        [draggable]="false" [resizable]="false">
        <div style="padding: 8px;">¿Seguro que desea eliminar el cargo?</div>
        <ng-template pTemplate="footer">
            <p-button (click)="RoleDeleteStatus=false" label="No" styleClass="p-button-text"></p-button>
            <p-button (click)="deleteRole()" label="Si" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>

    <p-dialog header="Crear cargo" [(visible)]="RoleCreateStatus" [modal]="true" [baseZIndex]="10001"
        [draggable]="false" [resizable]="false">
        <p-messages [(value)]="msg"></p-messages>
        <div class="p-col-12">
            <div class="inputLabel">Nombre para el nuevo cargo:</div>
            <input type="text" pInputText [(ngModel)]="roleName" [style]="{width: '100%'}">
        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="RoleCreateStatus=false" label="No" styleClass="p-button-text"></p-button>
            <p-button (click)="createRole()" label="Crear" styleClass="p-button-success"></p-button>
        </ng-template>
    </p-dialog>

    <p-dialog header="Editar cargo" [(visible)]="RoleEditStatus" [modal]="true" [baseZIndex]="10001" [draggable]="false"
        [resizable]="false">
        <p-messages [(value)]="msg"></p-messages>
        <div class="p-col-12">
            <div class="inputLabel">Nombre para el cargo:</div>
            <input id="roleName" type="text" pInputText [(ngModel)]="RoleEditedName" [style]="{width: '100%'}">
        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="RoleEditStatus=false" label="No" styleClass="p-button-text"></p-button>
            <p-button (click)="editRole()" label="Editar" styleClass="p-button-success"></p-button>
        </ng-template>
    </p-dialog>



    <p-toast position="top-right"></p-toast>

</div>
