import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { CatalogPublicComponent } from './homepage/catalog-public/catalog-public.component';
import { ContactComponent } from './homepage/contact/contact.component';
import { HomeComponent } from './homepage/home/home.component';
import { LoginComponent } from './login/login.component';
import { CatalogComponent } from './system/catalog/catalog.component';
import { DashboardComponent } from './system/dashboard/dashboard.component';
import { AcquisitionsComponent } from './system/inventory/acquisitions/acquisitions.component';
import { InventoryComponent } from './system/inventory/inventory.component';
import { ProductsManageComponent } from './system/inventory/products-manage/products-manage.component';
import { ProductsComponent } from './system/inventory/products/products.component';
import { MainComponent } from './system/main/main.component';
import { OrdersComponent } from './system/orders/orders.component';
import { ClientsComponent } from './system/sales/clients/clients.component';
import { EditOrderComponent } from './system/sales/edit-order/edit-order.component';
import { NewOrderComponent } from './system/sales/new-order/new-order.component';
import { OrderListComponent } from './system/sales/order-list/order-list.component';
import { SalesComponent } from './system/sales/sales.component';
import { UsersComponent } from './system/users/users.component';


const routes: Routes = [

  // SYSTEM PATHS
  { path: "login", component: LoginComponent },
  {
    path: 'system', component: MainComponent, canActivate: [AuthGuard], children: [
      { path: "users", component: UsersComponent },
      { path: "sales", component: SalesComponent, children: [
        { path: "newOrder", component: NewOrderComponent },
        { path: "orderList", component: OrderListComponent },
        { path: "clients", component: ClientsComponent },
        { path: "editOrder/:id", component: EditOrderComponent }]},
      { path: "inventory", component: InventoryComponent,children: [
        { path: "products", component: ProductsComponent },
        { path: "acquisitions", component: AcquisitionsComponent },
        { path: "productManage", component: ProductsManageComponent }]},
      { path: "dashboard", component: DashboardComponent },
      { path: "orders", component: OrdersComponent },
      { path: "catalog", component: CatalogComponent },
    ]
  },

  // HOMEPAGE PATHS
  { path: '', component: HomeComponent },
  { path: 'Catalogo', component: CatalogPublicComponent },
  { path: 'Contacto', component: ContactComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
