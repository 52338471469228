<div class="header">
    <div class="verticalCenter">
        <div class="inlineBlock">
            <a class="phoneDiv" href="tel:+526675738483">
                <i class="pi pi-phone" style="font-size: 1rem"></i>
                <span class="padding-5">Presiona aqui para llamar</span>
            </a>
        </div>
        <div class="headerSeparator">o</div>
        <div class="inlineBlock">
            <a class="phoneDiv" href=" https://wa.me/526675738483">
                <span class="whatsappIcon"></span>
                <span class="padding-5">aqui para mandar mensaje Whatsapp</span>
            </a>
        </div>
        <div class="headerLabel">
            Servicio las 24 horas los 7 dias de la semana
        </div>
    </div>

</div>
