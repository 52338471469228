import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  options: any;
  pieOptions: any;
  dashboardInfo: any;

  chartData: any;
  chartLabels: string[] = [];
  chartIncomes: number[] = [];
  chartOutcomes: number[] = [];

  topCarouselData: any;
  availabilityData: any;
  soldCategoryProductsData: any;
  soldCategoryIncomeData: any[] = [];

  soldCategoryIncomeInfo: any;


  loadingStatus: boolean = true;

  constructor(private dashboardService: DashboardService) {
    this.options = {
      legend: { labels: { fontColor: 'white' } },
      scales: {
        yAxes: [
          { ticks: { fontColor: 'white' } },
          // { gridLines : {tickMarkLength:'-4' , color: 'gray',  zeroLineColor: 'gray' } }
        ],
        xAxes: [
          { ticks: { fontColor: 'white' } },
          // { gridLines : { color: 'gray',  zeroLineColor: 'gray'} }
        ]
      }
    };

    this.pieOptions = {
      legend: { display: false },
      cutoutPercentage: 85
    }

  }

  ngOnInit(): void {
    this.dashboardService.getDashboardInfo().subscribe(data => {
      this.dashboardInfo = data;
      this.availabilityData = this.dashboardInfo.lowPiecesProducts;
      this.soldCategoryIncomeInfo = this.dashboardInfo.soldCategoryIncome;
      this.soldCategoryProductsData = this.dashboardInfo.soldCategoryProducts;
      this.transformChartData();
      this.transformPieChart();
    });
  }

  SetTooltipText(firstLine, secondLine) {
    return "Semana actual: " + firstLine + "\n Semana pasada: " + secondLine;
  }

  transformChartData() {
    let chartInfo = this.dashboardInfo.Chart;
    chartInfo.forEach(item => {
      this.chartLabels.push(item.period);
      this.chartIncomes.push(item.income);
      this.chartOutcomes.push(item.outcome);
    });
    this.chartData = {
      labels: this.chartLabels,
      datasets: [
        {
          label: 'Ganancias',
          data: this.chartIncomes,
          fill: false,
          borderColor: '#CAA462'
        },
        {
          label: 'Perdidas',
          data: this.chartOutcomes,
          fill: false,
          borderColor: 'gray'
        }
      ]
    };
  }

  transformPieChart() {
    this.soldCategoryIncomeInfo.forEach(item => {
      let chart = {
        labels: ['Este producto', 'Otros productos'],
        datasets: [{
          data: [item.percentage, 100 - item.percentage],
          backgroundColor: ["#CAA462", "#848484"],
          hoverBackgroundColor: ["#CAA462", "#848484"],
          borderWidth: 0
        }]
      }

      let obj = {
        chartData: chart,
        category: item.category,
        income: item.income,
        percentage: item.percentage
      }

      this.soldCategoryIncomeData.push(obj);
    })
    this.loadingStatus = false;
  }

}
