import { isNgTemplate, NullTemplateVisitor } from '@angular/compiler';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SalesService } from '../sales/sales.service';
import { UserService } from '../users/user.service';
import { InventoryService } from './inventory.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class InventoryComponent implements OnInit {

  constructor(
    private intentoryService: InventoryService,
    private messageService: MessageService,
    private userService: UserService,
    private salesService: SalesService) { }

  msg: any[] = [];

  allProducts: any;
  AvailableProducts: any;
  allAcquisitions: any;
  allWarehouses: any;
  allCurrencies: any;
  allCategories: any;
  allUsers: any;
  categoryFilter: any;
  appliedFilter: any;
  textFilter: any;
  file: any;

  productToEdit: any;
  productToDelete: any;
  categoryName: any;
  categoryToEdit: any;
  categoryToDelete: any;
  warehouseName: any;
  wharehouseOwner: any = null;
  wharehouseOwnerStatus: boolean = false;
  warehouseToEdit: any;
  warehouseToDelete: any;
  currencyToEdit: any;
  currencyToDelete: any;

  productToMove: any;
  productAcquisitions: any;
  productTotalPieces: any;
  warehousesPieces: any;
  warehouseFrom: any;
  warehouseTo: any;
  piecesToMove: any;
  maxPiecesToMove: any;


  otherFilters: any = [
    { id: 1, label: 'Mayor a Menor precio' },
    { id: 2, label: 'Menor a Mayor precio' },
    { id: 3, label: 'En Existencia' },
    { id: 4, label: 'Agotado' }
  ];

  productForm = {
    Name: '',
    Price: null,
    Category: null,
    NetContent: '',
    Photo: null
  }

  acquisitionForm = {
    id: null,
    product: { id: null, name: null },
    warehouse: { id: null, name: null },
    currency: { id: null, name: null },
    pieces: null,
    cost: null,
    admission: null,
    soldOut: null,
    acquired: null,
  }

  currencyForm = {
    id: null,
    name: '',
    abbreviation: '',
    value: null
  }



  productModalStatus: boolean = false;
  productDeleteModalStatus: boolean = false;
  productEditModalStatus: boolean = false;
  acquisitionModalStatus: boolean = false;
  acquisitionDetailsModalStatus: boolean = false;
  acquisitionEditStatus: boolean = false;
  categoriesModalStatus: boolean = false;
  categoryCreateStatus: boolean = false;
  categoryEditStatus: boolean = false;
  categoryDeleteStatus: boolean = false;
  warehousesModalStatus: boolean = false;
  warehouseCreateStatus: boolean = false;
  warehouseEditStatus: boolean = false;
  warehouseDeleteStatus: boolean = false;
  currenciesModalStatus: boolean = false;
  currencyEditStatus: boolean = false;
  currencyCreateStatus: boolean = false;
  currencyDeleteStatus: boolean = false;

  defaultImage: string = 'assets/Images/system/default.png'

  ngOnInit(): void {
    //this.intentoryService.getCategories().subscribe(data => this.allCategories = data);
    //this.intentoryService.getAllProducts().subscribe(data => this.allProducts = data);
    //this.intentoryService.getAcquisitions().subscribe(data => this.allAcquisitions = data);
    //this.salesService.getProductsAvailable().subscribe(data => this.AvailableProducts = data)
  }







}
