import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SalesService } from '../sales.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class ClientsComponent implements OnInit {

  allClients: any;
  clientRowSelected: any;
  isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  clientDetailsModalStatus: boolean = false;
  clientAddModalStatus: boolean = false;
  clientEditStatus: boolean = false;
  fullDataClient: boolean = false;

  msg: any

  clientForm = {
    Id: null,
    Client: '',
    Address: '',
    Email: '',
    ExpenseType: '',
    RFC: '',
    Location: '',
    Phone: null
  }

  constructor(private salesService: SalesService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.salesService.getClients().subscribe(data => this.allClients = data);
  }

  onRowSelect(event) {
    this.clientRowSelected = event.data;
  }

  onRowUnselect(event) {
    this.clientRowSelected = null;
  }
  sendActionNotification(data)
  {
    let notification = {
      Acción: data.name ,
      Cliente: this.clientForm.Client,
      Dirección: this.clientForm.Address,
      Teléfono: this.clientForm.Phone
    }

    this.salesService.sendNotification(notification).subscribe();
  }
  isAdmin()
  {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    let userModules = currentUser.modules
    userModules.forEach(obj => {
      if (obj == "dashboard")
      {return true}
      else
      {return false}
     });
    return false
  }
  openClientDetailsModal(item) {
    this.msg = [];
    this.clientForm = {
      Id: item.id,
      Client: item.client,
      Address: item.address,
      Email: item.email,
      ExpenseType: item.expenseType,
      RFC: item.rfc,
      Location: item.location,
      Phone: item.phone
    }
    this.clientEditStatus = false;
    this.clientDetailsModalStatus = true;
  }

  openAddClientModal() {
    this.msg = [];
    this.clientForm = {
      Id: null,
      Client: '',
      Address: '',
      Email: '',
      ExpenseType: '',
      RFC: '',
      Location: '',
      Phone: null
    }
    this.clientAddModalStatus = true;
  }

  selectClientValidation() {
    if (this.fullDataClient) {
      this.addFullClientValidation();
    } else {
      this.addClientValidation();
    }
  }

  addFullClientValidation() {
    this.msg = [];
    const { Client, Address, Email, ExpenseType, RFC, Location, Phone } = this.clientForm;
    if (Client == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'Cliente es requerido' });
    } else if (Phone == null || Phone > 9999999999 || Phone < 999999999) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Telefono es requerido (10 digitos)' });
    } else if (Location == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'Ubicación es requerida' });
    } else if (Address == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'Dirección es requerida' });
    } else if (Email == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'Email es requerido' });
    } else if (ExpenseType == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'Tipo de gasto es requerido' });
    } else if (RFC == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'RFC es requerido' });
    } else {
      this.addClient();
    }
  }

  addClientValidation() {
    this.msg = [];
    const { Client, Location, Phone } = this.clientForm;
    if (Client == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'Cliente es requerido' });
    } else if (Phone == null || Phone > 9999999999 || Phone < 999999999) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Telefono es requerido (10 digitos)' });
    } else if (Location == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'Ubicación es requerida' });
    } else {
      this.addClient();
    }
  }

  addClient() {
    let data = {
      action: 'create',
      client: this.clientForm.Client,
      address: this.clientForm.Address,
      email: this.clientForm.Email,
      expenseType: this.clientForm.ExpenseType,
      rfc: this.clientForm.RFC,
      location: this.clientForm.Location,
      phone: this.clientForm.Phone
    }
    this.salesService.ClientActions(data).subscribe(response => {
      this.clientAddModalStatus = false;
      this.salesService.getClients().subscribe(data => this.allClients = data);
      if(!this.isAdmin()) {this.sendActionNotification({name: 'Cliente Agregado'})}
    })
  }

  updateClientValidation() {
    this.msg = [];
    const { Client, Location, Phone } = this.clientForm;
    if (Client == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'Cliente es requerido' });
    } else if (Phone == null || Phone > 9999999999 || Phone < 999999999) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Telefono es requerido (10 digitos)' });
    } else if (Location == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'Ubicación es requerida' });
    } else {
      this.updateClient();
    }
  }

  updateClient() {
    let data = {
      action: 'update',
      id: this.clientForm.Id,
      client: this.clientForm.Client,
      address: this.clientForm.Address,
      email: this.clientForm.Email,
      expenseType: this.clientForm.ExpenseType,
      rfc: this.clientForm.RFC,
      location: this.clientForm.Location,
      phone: this.clientForm.Phone
    }
    this.salesService.ClientActions(data).subscribe(response => {
      this.clientDetailsModalStatus = false;
      this.salesService.getClients().subscribe(data => this.allClients = data);
      if(!this.isAdmin()) {this.sendActionNotification({name: 'Cliente Actualizado'})}
    })
  }

  deleteClient() {
    let data = {
      action: 'delete',
      id: this.clientForm.Id
    }
    this.salesService.ClientActions(data).subscribe(response => {
      this.clientDetailsModalStatus = false;
      this.salesService.getClients().subscribe(data => this.allClients = data);
      if(!this.isAdmin()) {this.sendActionNotification({name: 'Cliente Eliminado'})}
    })
  }

}
