import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { HomepageService } from '../homepage.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

  slides: any;
  topProducts: any;
  menuDisplay: boolean = false;

  logoCompleto: string = '/assets/Images/fullLogo.svg';
  sliderLine: string = '/assets/Images/homepage/slider-line.png';

  logoBaileys: string = '/assets/Images/homepage/brands/Baileys.svg';
  logoBuchanans: string = '/assets/Images/homepage/brands/Buchanans.svg';
  logoDonJulio: string = '/assets/Images/homepage/brands/DonJulio.svg';
  logoJackDaniels: string = '/assets/Images/homepage/brands/JackDaniels.svg';
  logoJonnieWalker: string = '/assets/Images/homepage/brands/JonnieWalker.svg';
  logoMoet: string = '/assets/Images/homepage/brands/Moet.svg';


  constructor(private HomeService: HomepageService, private router: Router) { }

  ngOnInit(): void {

    this.slides = [
      {
        class: "slide1",
        title: "Mision",
        description: `Ser líderes a nivel estatal en distribución de vinos y licores manteniendo 
        nuestro compromisos de suministro, calidad y precio.`
      },
      {
        class: "slide2",
        title: "Vision",
        description: `Buscamos ofrecer un extenso portafolio de productos a buen precio para ser 
        los más competentes en el mercado llevando de la mano el éxito y la felicidad de nuestros clientes.`
      },
      {
        class: "slide3",
        title: "Quienes somos",
        description: `Somos una empresa que se dedica a la comecializacion de vinos y licores,
         asi como tambien, poseemos la capacidad de obtener algun vino o licor fuera de nuestro
        catalogo.`
      }
    ]

    this.HomeService.getTopProducts().subscribe(data => { this.topProducts = data });
    
  }

  test() {
    this.router.navigate(['Catalogo']);
  }

}
