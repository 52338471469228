import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.baseUrl;

  getTopProducts() {
    return this.http.get(`${this.baseUrl}TopProducts`, { responseType: 'json' })
  }

  getCatalog() {
    return this.http.get(`${this.baseUrl}Catalog`, { responseType: 'json' })
  }

  sendQuote(data) {
    return this.http.post(`${this.baseUrl}Quotes`, data, { responseType: 'text' })
  }

  GetProductPhoto(id: any) {
    return this.http.post(`${this.baseUrl}GetProducImage`, { id: id })
  }

  getCategories() {
    return this.http.get(`${this.baseUrl}GetProductCategories`)
  }

}
