<div class="dashboardMainContainer">

    <div class="p-grid" *ngIf="!loadingStatus">

        <div class="p-col-12 topCarouselTitle">Ventas de unidades semanales</div>

        <div class="p-col-12 padding-0 topCarousel phoneHide">
            <p-carousel [value]="soldCategoryProductsData" [autoplayInterval]="4000" [numVisible]="4" [circular]="true"
                [page]="1" showIndicators="false">
                <ng-template let-slide pTemplate="item">
                    <div class="p-grid p-align-center vertical-container">
                        <div class="p-col " tooltipPosition="bottom"
                            pTooltip="{{SetTooltipText(slide.currentWeekPieces,slide.lastWeekPieces)}}">
                            <div style="background: #252933; margin: 8px;padding: 10px;">
                                <strong>{{slide.category}}</strong>
                                <div class="p-d-flex p-ai-center p-jc-between" style="height: 100%;">
                                    <i *ngIf="slide.currentWeekPieces > slide.lastWeekPieces" class="pi pi-caret-up"
                                        style="font-size: 1.5rem;color:  green;padding-left: 45px;"></i>
                                    <i *ngIf="slide.currentWeekPieces < slide.lastWeekPieces" class="pi pi-caret-down"
                                        style="font-size: 1.5rem;color:  red;padding-left: 45px;"></i>
                                    <div style="font-size: 50px;padding-right: 45px;">{{slide.currentWeekPieces}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-carousel>
        </div>
        <div class="p-col-12 padding-0 topCarousel phoneShow">
            <p-carousel [value]="soldCategoryProductsData" [autoplayInterval]="4000" [numVisible]="1" [circular]="true"
                [page]="1" showIndicators="false">
                <ng-template let-slide pTemplate="item">
                    <div class="p-grid p-align-center vertical-container">
                        <div class="p-col" tooltipPosition="bottom"
                            pTooltip="{{SetTooltipText(slide.currentWeekPieces,slide.lastWeekPieces)}}">
                            <div style="background: #252933; margin: 8px;padding: 10px;">
                                <strong>{{slide.category}}</strong>
                                <div class="p-d-flex p-ai-center p-jc-between" style="height: 100%;">
                                    <i *ngIf="slide.currentWeekPieces > slide.lastWeekPieces" class="pi pi-caret-up"
                                        style="font-size: 1.5rem;color:  green;padding-left: 45px;"></i>
                                    <i *ngIf="slide.currentWeekPieces < slide.lastWeekPieces" class="pi pi-caret-down"
                                        style="font-size: 1.5rem;color:  red;padding-left: 45px;"></i>
                                    <div style="font-size: 50px;padding-right: 45px;">{{slide.currentWeekPieces}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-carousel>
        </div>


        <div class="p-col-12 p-md-9 padding-0">
            <div class="p-grid">


                <div class="p-col-12 p-md-8">
                    <div style="background: #252933;">
                        <div class="p-grid">
                            <div class="p-col-12 sectionHeader">
                                Concentrado de ganancias y perdidas</div>
                            <div class="p-col-12 padding-0">
                                <div class="chartContainer">
                                    <div class="p-grid">
                                        <p-chart class="p-col-12" type="line" [data]="chartData" [options]="options">
                                        </p-chart>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-4">
                    <div style="background: #252933;height: 100%;">
                        <div class="p-grid">
                            <div class="p-col-12 sectionHeader">
                                Disponibilidad</div>
                            <div class="p-col-12" style="background:#252933;">
                                <div *ngFor="let item of availabilityData" style="padding: 2px 0px;">
                                    <div class="p-grid p-align-center p-jc-between">
                                        <div class="p-col-2">
                                            <i *ngIf="item.percentage < 50" class="pi pi-exclamation-triangle"
                                                style="color:yellow;"></i>
                                        </div>
                                        <div class="p-col-5 padding-0" tooltipPosition="bottom"
                                            pTooltip="Piezas existentes: {{item.totalPieces}}">
                                            {{item.name}}</div>
                                        <div class="p-col-5 padding-0">
                                            <p-progressBar *ngIf="item.percentage > 50" class="highValue"
                                                [value]="item.percentage" [showValue]="false"></p-progressBar>
                                            <p-progressBar *ngIf="item.percentage < 50" class="lowValue"
                                                [value]="item.percentage" [showValue]="false"></p-progressBar>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-col-12" style="padding: 0px 0px 10px 47px;">Ganancias por categoria esta semana</div>
                <div class="p-col-12 padding-0 topCarousel phoneHide" *ngIf="!loadingStatus">
                    <p-carousel [value]="soldCategoryIncomeData" [autoplayInterval]="4000" [numVisible]="3"
                        [circular]="true" showIndicators="false">
                        <ng-template let-slide pTemplate="item">
                            <div class="p-grid p-jc-center" style="background: #252933;text-align: center;">

                                <div class="p-col-12 padding-0">
                                    <strong>{{slide.category}}</strong>
                                    <div style="position: relative;">
                                        <div style="font-size: 40px;position: absolute;width: 100%;top: 38px;">
                                            {{slide.percentage}}%</div>
                                    </div>
                                </div>

                                <p-chart class="p-col-11" type="doughnut" [data]="slide.chartData"
                                    [options]="pieOptions"></p-chart>

                            </div>
                        </ng-template>
                    </p-carousel>
                </div>
                <div class="p-col-12 padding-0 topCarousel phoneShow" *ngIf="!loadingStatus">
                    <p-carousel [value]="soldCategoryIncomeData" [autoplayInterval]="4000" [numVisible]="1"
                        [circular]="true" showIndicators="false">
                        <ng-template let-slide pTemplate="item">
                            <div class="p-grid p-jc-center" style="background: #252933;text-align: center;">

                                <div class="p-col-12 padding-0">
                                    <strong>{{slide.category}}</strong>
                                    <div style="position: relative;">
                                        <div style="font-size: 40px;position: absolute;width: 100%;top: 30px;">
                                            {{slide.percentage}}%</div>
                                    </div>
                                </div>

                                <p-chart class="p-col-11" type="doughnut" [data]="slide.chartData"
                                    [options]="pieOptions"></p-chart>

                            </div>
                        </ng-template>
                    </p-carousel>
                </div>

            </div>
        </div>

        <div class="p-col-12 p-md-3">
            <div style="background: #252933;height: 100%;">
                <div class="p-grid">
                    <div class="p-col-12 sectionHeader">
                        Productos vendidos esta semana
                    </div>
                    <div class="p-col-12" style="background:#252933;">
                        <div *ngFor="let item of soldCategoryProductsData">
                            <div class="p-grid">
                                <div class="p-col-12 padding-0" style="position: relative;bottom: -14px;">
                                    {{item.category}}</div>
                                <div class="p-col-12"
                                    style="font-size: 12px;text-align: end;color: #e5c183;padding:0px 12px 0px 0px;">
                                    {{item.percentage}}%</div>
                                <p-progressBar class="p-col-12 highValue" [value]="item.percentage" [showValue]="false">
                                </p-progressBar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>