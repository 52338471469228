<div class="mainContainer">
    <div class="p-grid ">

        <app-header class="p-col-12 padding-0"></app-header>

        <app-menu class="p-col-2 padding-0"></app-menu>

        <div class="p-col-12 p-md-10 padding-0">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>