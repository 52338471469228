<div class="LoginContainer">
    <img class="backgroundImage" src="{{backImage}}">

    <div class="p-grid p-jc-center p-ai-center vertical-container " style="height: 100%;width: 100%;">

        <p-card styleClass="p-card-shadow p-col">
            <img src="{{Logo}}" class="logo">
            <div class="tileOne">Inicio de sesión</div>
            <div class="tileTwo">Ingrese sus credenciales</div>
            <span class="p-float-label">
                <input id="usuario" type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="user"
                    [ngClass]="validationStatus ? 'ng-invalid' : '' " class="ng-dirty">
                <label for="usuario">Usuario</label>
            </span>
            <br>
            <span class="p-float-label" style="margin-top: 5px;">
                <input id="float-input2" type="password" pInputText [style]="{width: '100%'}" [(ngModel)]="pass"
                    [ngClass]="validationStatus ? 'ng-invalid ng-dirty' : '' ">
                <label for="float-input2">Contraseña</label>
            </span>
            <div *ngIf="validationStatus" class="validationLabel">{{msg}}</div>
            <ng-template pTemplate="footer">
                <div class="p-d-flex p-jc-between">
                    <p-button label="Ir al sitio" icon="pi pi-angle-left" styleClass="p-button-secondary" (click)="goHome()"></p-button>
                    <p-button label="Ingresar" icon="pi pi-check" (click)="connect()"></p-button>
                </div>
            </ng-template>
        </p-card>

    </div>
</div>