<div *ngIf="!mobDeviceFlag" class="p-col-12 padding-0">

    <div class="menuContainer">

        <i class="pi pi-sign-out" (click)="logout()" style="position: absolute;right: 20px;top :20px;"></i>

        <div style="text-align: center;">
            <img src="{{Logo}}" style="margin: 20% 15%;">
        </div>

        <div *ngFor="let item of menuModules">

            <a *ngIf="item.id != 2 && item.id != 3" [routerLink]="item.route" routerLinkActive="active-link"
                [routerLinkActiveOptions]="{exact: false}" #menuItem="routerLinkActive">
                <div [ngClass]=" menuItem.isActive ? 'menuitemActive': 'menuitem'">
                    {{item.module}}
                </div>
            </a>

            <div *ngIf="item.id == 2">
                <div class="menuitem" (click)="SalesSubmodule=!SalesSubmodule" style="cursor: pointer;">
                    Ventas
                    <i *ngIf="!SalesSubmodule" class="pi pi-angle-right" style="float: right;"></i>
                    <i *ngIf="SalesSubmodule" class="pi pi-angle-down" style="float: right;"></i>
                </div>
                <div *ngIf="SalesSubmodule">
                    <a [routerLink]="['sales/newOrder']" routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{exact: true}" #menuItem1="routerLinkActive">
                        <div [ngClass]=" menuItem1.isActive ? 'menuitemActive': 'menuitem'" style="margin-left: 30px;">
                            Nueva Orden
                        </div>
                    </a>
                    <a [routerLink]="['sales/orderList']" routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{exact: true}" #menuItem2="routerLinkActive">
                        <div [ngClass]=" menuItem2.isActive ? 'menuitemActive': 'menuitem'" style="margin-left: 30px;">
                            Lista de ordenes
                        </div>
                    </a>
                    <a [routerLink]="['sales/clients']" routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{exact: true}" #menuItem3="routerLinkActive">
                        <div [ngClass]=" menuItem3.isActive ? 'menuitemActive': 'menuitem'" style="margin-left: 30px;">
                            Clientes
                        </div>
                    </a>
                </div>
            </div>

            <div *ngIf="item.id == 3">
                <div class="menuitem" (click)="InventorySubmodule=!InventorySubmodule" style="cursor: pointer;">
                    Inventario
                    <i *ngIf="!InventorySubmodule" class="pi pi-angle-right" style="float: right;"></i>
                    <i *ngIf="InventorySubmodule" class="pi pi-angle-down" style="float: right;"></i>
                </div>
                <div *ngIf="InventorySubmodule">
                    <a [routerLink]="['inventory/products']" routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{exact: true}" #menuItem1="routerLinkActive">
                        <div [ngClass]=" menuItem1.isActive ? 'menuitemActive': 'menuitem'" style="margin-left: 30px;">
                            Productos
                        </div>
                    </a>
                    <a [routerLink]="['inventory/acquisitions']" routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{exact: true}" #menuItem2="routerLinkActive">
                        <div [ngClass]=" menuItem2.isActive ? 'menuitemActive': 'menuitem'" style="margin-left: 30px;">
                            Adquisiciones
                        </div>
                    </a>
                    <a [routerLink]="['inventory/productManage']" routerLinkActive="active-link"
                        [routerLinkActiveOptions]="{exact: true}" #menuItem3="routerLinkActive">
                        <div [ngClass]=" menuItem3.isActive ? 'menuitemActive': 'menuitem'" style="margin-left: 30px;">
                            Existencias
                        </div>
                    </a>
                </div>
            </div>

        </div>
    </div>
</div>

<i *ngIf="mobDeviceFlag" class="pi pi-bars mobileMenuButon" style="position: absolute;top: 24px;left: 24px;"
    (click)="display=true"></i>

<p-sidebar [(visible)]="display">

    <img src="{{horizontalLogo}}" class="menuIconMobile">

    <div *ngFor="let item of menuModules">

        <a *ngIf="item.id != 2 && item.id != 3" [routerLink]="item.route" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: false}" #menuItem="routerLinkActive">
            <div [ngClass]=" menuItem.isActive ? 'menuitemActive': 'menuitem'">
                {{item.module}}
            </div>
        </a>

        <div *ngIf="item.id == 2">
            <div class="menuitem" (click)="SalesSubmodule=!SalesSubmodule" style="cursor: pointer;">
                Ventas
                <i *ngIf="!SalesSubmodule" class="pi pi-angle-right" style="float: right;"></i>
                <i *ngIf="SalesSubmodule" class="pi pi-angle-down" style="float: right;"></i>
            </div>
            <div *ngIf="SalesSubmodule">
                <a [routerLink]="['sales/newOrder']" routerLinkActive="active-link"
                    [routerLinkActiveOptions]="{exact: true}" #menuItem1="routerLinkActive">
                    <div [ngClass]=" menuItem1.isActive ? 'menuitemActive': 'menuitem'" style="margin-left: 30px;">
                        Nueva Orden
                    </div>
                </a>
                <a [routerLink]="['sales/orderList']" routerLinkActive="active-link"
                    [routerLinkActiveOptions]="{exact: true}" #menuItem2="routerLinkActive">
                    <div [ngClass]=" menuItem2.isActive ? 'menuitemActive': 'menuitem'" style="margin-left: 30px;">
                        Lista de ordenes
                    </div>
                </a>
                <a [routerLink]="['sales/clients']" routerLinkActive="active-link"
                    [routerLinkActiveOptions]="{exact: true}" #menuItem3="routerLinkActive">
                    <div [ngClass]=" menuItem3.isActive ? 'menuitemActive': 'menuitem'" style="margin-left: 30px;">
                        Clientes
                    </div>
                </a>
            </div>
        </div>

        <div *ngIf="item.id == 3">
            <div class="menuitem" (click)="InventorySubmodule=!InventorySubmodule" style="cursor: pointer;">
                Inventario
                <i *ngIf="!InventorySubmodule" class="pi pi-angle-right" style="float: right;"></i>
                <i *ngIf="InventorySubmodule" class="pi pi-angle-down" style="float: right;"></i>
            </div>
            <div *ngIf="InventorySubmodule">
                <a [routerLink]="['inventory/products']" routerLinkActive="active-link"
                    [routerLinkActiveOptions]="{exact: true}" #menuItem1="routerLinkActive">
                    <div [ngClass]=" menuItem1.isActive ? 'menuitemActive': 'menuitem'" style="margin-left: 30px;">
                        Productos
                    </div>
                </a>
                <a [routerLink]="['inventory/acquisitions']" routerLinkActive="active-link"
                    [routerLinkActiveOptions]="{exact: true}" #menuItem2="routerLinkActive">
                    <div [ngClass]=" menuItem2.isActive ? 'menuitemActive': 'menuitem'" style="margin-left: 30px;">
                        Adquisiciones
                    </div>
                </a>
                <a [routerLink]="['inventory/productManage']" routerLinkActive="active-link"
                    [routerLinkActiveOptions]="{exact: true}" #menuItem3="routerLinkActive">
                    <div [ngClass]=" menuItem3.isActive ? 'menuitemActive': 'menuitem'" style="margin-left: 30px;">
                        Existencias
                    </div>
                </a>
            </div>
        </div>

    </div>

</p-sidebar>