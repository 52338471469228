import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { LoginService } from '../../login/login.service';
import { MainComponent } from '../main/main.component'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  userId: string;
  client: any;
  items: MenuItem[];
  UserName: string;

  logoOnly = "assets/Images/logoOnly.svg";
  horizontalLogo = "assets/Images/horizontalLogo.svg";

  constructor(private loginservice: LoginService, private router: Router, private mobile: MainComponent) { }

  mobDeviceFlag: boolean = false;

  ngOnInit(): void {
    if (window.innerWidth < 768) { this.mobDeviceFlag = true }
  }

  logout() {
    this.loginservice.logout()
    this.router.navigateByUrl('');
  }

  @HostListener('window:resize', ['$event'])
  onresize(event) {
    if (window.innerWidth < 768) { this.mobDeviceFlag = true }
    else { this.mobDeviceFlag = false }
  }

}
