<div class="catalogMainContainer">


    <div class="catalogTitle">Catalogo</div>

    <div class="catalogSubContainer">

        <div *ngIf="!allProducts" style="text-align: center;color: #caa462;width: 100%;">
            <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
            <div>Cargando productos...</div>
        </div>

        <p-dataView *ngIf="allProducts" #dv [paginator]="true" [rows]="16" layout="list"
            [value]="allProducts  | productFilter : categoryFilter:appliedFilter:textFilter">
            <ng-template pTemplate="header">
                <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">

                    <p-dropdown class="filterPadding" [options]="categories" optionLabel="category"
                        [(ngModel)]="categoryFilter" placeholder="Categoria" [showClear]="true"
                        [style]="{width: '100%'}"></p-dropdown>

                    <p-dropdown class="filterPadding" [options]="otherFilters" optionLabel="label"
                        [(ngModel)]="appliedFilter" placeholder="Filtro" [showClear]="true" [style]="{width: '100%'}">
                    </p-dropdown>

                    <span class="p-input-icon-left filterPadding">
                        <i class="pi pi-search"></i>
                        <input type="search" pInputText placeholder="Buscar" [(ngModel)]="textFilter"
                            [style]="{width: '100%'}">
                    </span>

                    <div class="filterPadding">
                        <div style="float: right;">
                            <span class="viewLabel">Cambiar modo de vista:</span>
                            <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
                        </div>
                    </div>

                </div>

            </ng-template>

            <ng-template let-product pTemplate="listItem">
                <div class="p-col-12 padding-0">
                    <div style="display: flex;align-items: center;width: 100%;background: #191d28;">
                        <div class="p-col-4 p-md-2">
                            <div class="productPhotoContainer">
                                <div class="productPhotoDiv {{ProductPhoto(product)}}">
                                    <img style="height: 100%;" src="{{product.photo ? product.photo : defaultImage }}">
                                </div>
                            </div>
                        </div>
                        <div class="p-col-6 p-md-9">
                            <div class="p-grid p-ai-center vertical-container">
                                <div class="p-col-12" style="font-size: 20px;font-weight: 700;">
                                    {{product.name}}</div>
                                <div class="p-col-12 p-md-4">
                                    <i class="pi pi-tag"></i>
                                    <span style="margin-left: 5px;">
                                        {{setCategoryName(product.category)}}
                                    </span>
                                </div>
                                <div class="p-col-12 p-md-4">Cont.Net. : {{product.netcontent}} </div>
                                <div class="p-col-12 p-md-4" style="font-size: 1rem;font-weight: 700;">
                                    <i class="pi pi-money-bill"></i>
                                    <span style="margin-left: 5px;">{{product.price | currency}}</span>
                                </div>
                            </div>
                        </div>
                        <i class="pi pi-circle-on p-col-2 p-md-1"
                            [ngStyle]="{'color':product.totalpieces > 0 ? 'green' : 'red' }">
                        </i>
                    </div>
                </div>
            </ng-template>

            <ng-template let-product pTemplate="gridItem">
                <div class="p-col-6  p-md-4 p-xl-3" style="background: #0d111c;">
                    <div class="p-d-flex p-flex-column" style="margin: 5px;align-items: center;background: #191d28;">
                        <div class="p-grid p-jc-between" style="width: 100%;padding: 8px;">
                            <div>
                                <i class="pi pi-tag product-category-icon"></i>
                                <span class="product-category"
                                    style="margin-left: 5px;">{{setCategoryName(product.category)}}</span>
                            </div>
                            <i class="pi pi-circle-on" [ngStyle]="{'color':product.totalpieces > 0 ? 'green' : 'red' }">
                            </i>
                        </div>
                        <div style="padding:10px;width: 70%">
                            <div class="productPhotoContainer">
                                <div class="productPhotoDiv {{ProductPhoto(product)}}">
                                    <img style="height: 100%;" src="{{product.photo ? product.photo : defaultImage }}">
                                </div>
                            </div>
                        </div>
                        <div style="font-size: 20px;font-weight: 700;text-align: center;">{{product.name}}</div>
                        <div>{{product.netcontent}} </div>
                        <div style="font-size: 1rem;font-weight: 700;padding:5px;">{{product.price | currency}}
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-dataView>
    </div>

</div>