import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-public',
  templateUrl: './header-public.component.html',
  styleUrls: ['./header-public.component.css']
})
export class HeaderPublicComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
