<!-- <iframe style="width:100%; height: calc(100vh - 4px);border: 0px;"
    src="https://ld-wp73.template-help.com/woocommerce/prod_11134/v2/" allowfullscreen></iframe> -->

<div class="p-grid homepage">

    <div class="p-col-12 padding-0">
        <app-header-public></app-header-public>
    </div>
    <div class="p-col-12 section1">
        <div class="p-d-flex p-ai-center">
            <div class="p-col">
                <i class="pi pi-bars pointer"  style="font-size: 17px;margin-left: 20%;" (click)="menuDisplay=true"></i>
            </div>
            <img class="logo" src="{{logoCompleto}}" alt="logo Barman">
            <div class="p-col"></div>
        </div>

    </div>
    <div class="p-col-12 padding-0 homeCarousel">
        <p-carousel [value]="slides" [autoplayInterval]="4000" [numVisible]="1" [circular]="true" [page]="2">
            <ng-template let-slide pTemplate="item">
                <div class="{{slide.class}} p-grid p-align-center vertical-container">
                    <div class="p-col">
                        <div class="slideTitle"> {{slide.title}}</div>
                        <img class="slideLine" src="{{sliderLine}}" alt="slider separator">
                        <div class="slideDescription"> {{slide.description}}</div>
                    </div>
                </div>
            </ng-template>
        </p-carousel>
    </div>
    <div class="p-col-12 section2">
        <div class="p-grid">
            <div class="p-col-6 p-sm-4 p-lg-2">
                <div class="justifyContent">
                    <img class="logo" src="{{logoJonnieWalker}}" alt="logo JonnieWalker">
                </div>
            </div>
            <div class="p-col-6 p-sm-4 p-lg-2">
                <div class="justifyContent">
                    <img class="logo" src="{{logoBuchanans}}" alt="logo buchanans">
                </div>
            </div>
            <div class="p-col-6 p-sm-4 p-lg-2">
                <div class="justifyContent">
                    <img class="logo" src="{{logoBaileys}}" alt="logo baileys">
                </div>
            </div>
            <div class="p-col-6 p-sm-4 p-lg-2">
                <div class="justifyContent">
                    <img class="logo" src="{{logoJackDaniels}}" alt="logo JackDaniels">
                </div>
            </div>
            <div class="p-col-6 p-sm-4 p-lg-2">
                <div class="justifyContent">
                    <img class="logo" src="{{logoMoet}}" alt="logo Moet">
                </div>
            </div>
            <div class="p-col-6 p-sm-4 p-lg-2">
                <div class="justifyContent">
                    <img class="logo" src="{{logoDonJulio}}" alt="logo donJulio">
                </div>
            </div>
        </div>
    </div>
    <div class="p-col-12 section3">
        <div class="section3Title">Productos mas vendidos</div>
        <img class="slideLine" src="{{sliderLine}}" alt="slider separator">
        <div *ngIf="topProducts==null" style="text-align: center;color: #caa462;">
            <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
            <div>Cargando productos...</div>
        </div>
        <div class="topProductsDiv">
            <div class="p-grid">
                <div *ngFor="let product of topProducts" class="p-col-12 p-sm-3">
                    <div class="productPhotoContainer">
                        <div class="productPhotoDiv">
                            <div class="frame">
                                <img class="productPhoto" src="{{product.photo}}">
                            </div>

                        </div>
                    </div>
                    <div class="productInfo">
                        <div class="productName"> {{product.name}}</div>
                        <div class="padding-5">{{product.netcontent}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-col-12 section4 padding-0">
        <div class="catalogDiv">
            <div class="frame">
                <div class="catalogButton" (click)="test()">
                    <div class="centerButtonContent">
                        Ver ahora
                    </div>
                </div>
            </div>
        </div>
        <img class="catalogImage" src="/assets/Images/homepage/catalogImage.png" alt="">
    </div>
    <div class="p-col-12 section5">
        <div class="sectionTitle">Porque elegirnos</div>
        <img class="slideLine" src="{{sliderLine}}" alt="slider separator">
        <div class="p-grid sectionPadding">
            <div class="p-col-12 p-md-4 ">
                <i class="pi pi-check-circle" style="font-size: 2rem"></i>
                <div class="qualityTitle">Calidad</div>
                <div class="qualityText">
                    Todos nuestros productos son adnquiridos directamente con los distribuidores a travez de todo el
                    mundo. garantizamos la alta calidad.
                </div>
            </div>
            <div class="p-col-12 p-md-4">
                <i class="pi pi-clock" style="font-size: 2rem"></i>
                <div class="qualityTitle">Personal y comercial</div>
                <div class="qualityText">
                    Hacemos ventas de mayoreo y menudeo, asi como envios a travez de todo mexico con tiempos de entrega
                    eficientes, contamos con entrega inmediata en el municipio de culiacan, Sinaloa.
                </div>
            </div>
            <div class="p-col-12 p-md-4">
                <i class="pi pi-wallet" style="font-size: 2rem"></i>
                <div class="qualityTitle">Mejores precios</div>
                <div class="qualityText">
                    El precio de nuestros productos es justo y costeable. estara sorprendido una vez que se realice la
                    entrega de su paquete.</div>
            </div>
        </div>
    </div>
    <div class="p-col-12 section6 padding-0">
        <div class="p-d-flex p-jc-center">
            <div class="centerText">
                <img class="endLogo" src="/assets/Images/mainLogo.svg" alt="logo Barman">
                <div class="paddingBottom">
                    <span class="goldColor">Barman ©. </span> Todos los derechos reservados
                </div>
            </div>
        </div>
    </div>

    <p-sidebar [(visible)]="menuDisplay">
        <div class="menuList">
            <a [routerLink]="'/'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                #Home="routerLinkActive">
                <div [ngClass]=" Home.isActive ? 'menuitemActive': 'menuitem'">Inicio</div>
            </a>
            <a [routerLink]="'/Catalogo'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                #Catalog="routerLinkActive">
                <div [ngClass]=" Catalog.isActive ? 'menuitemActive': 'menuitem'">Catalogo</div>
            </a>
            <a [routerLink]="'/Contacto'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                #Contact="routerLinkActive">
                <div [ngClass]=" Contact.isActive ? 'menuitemActive': 'menuitem'">Cotizaciones</div>
            </a>
        </div>
    </p-sidebar>
</div>