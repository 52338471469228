import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { InventoryService } from '../inventory/inventory.service';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CatalogComponent implements OnInit {

  constructor(private intentoryService: InventoryService) { }

  allProducts: any;
  categories: any;
  categoryFilter: any;
  appliedFilter: any;
  textFilter: any;

  otherFilters: any = [
    { id: 1, label: 'Mayor a Menor precio' },
    { id: 2, label: 'Menor a Mayor precio' },
    { id: 3, label: 'En Existencia' },
    { id: 4, label: 'Agotado' }
  ];

  defaultImage: string = 'assets/Images/system/default.png'

  ngOnInit(): void {
    this.intentoryService.getCategories().subscribe(data => this.categories = data);
    this.intentoryService.getCatalog().subscribe(data => this.allProducts = data);
  }

  setCategoryName(id) {
    let text = "";
    this.categories.forEach(item => {
      if (id == item.id) { text = item.category }
    });
    return text;
  }

  ProductPhoto(item: any) {
    if (item.photoStatus == null) {
      this.intentoryService.GetProductPhoto(item.id).subscribe(data => {
        Object.assign(item, { photo: data });
      });
      Object.assign(item, { photoStatus: true });
    }
    return null;
  }
}
