<div class="productsMainContainer">
    <div class="p-d-flex p-flex-wrap p-flex-md-row p-flex-column p-jc-md-between productFilters"
        style="padding: 0px 8px;">
        <div>
            <p-dropdown [options]="allCategories" optionLabel="category" [(ngModel)]="categoryFilter"
                placeholder="Categoria" [showClear]="true"></p-dropdown>
        </div>
        <div>
            <p-dropdown [options]="otherFilters" optionLabel="label" [(ngModel)]="appliedFilter" placeholder="Filtro"
                [showClear]="true">
            </p-dropdown>
        </div>
        <div>
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="search" pInputText placeholder="Buscar" [(ngModel)]="textFilter">
            </span>
        </div>
        <div>
            <button pButton type="button" class="p-button-secondary phoneHide" label="Categorias"
                (click)="openCategoriesModal()"></button>
        </div>
        <div>
            <button pButton type="button" class="p-button-help phoneHide" label="Agregar Producto" icon="pi pi-plus "
                (click)="openProductModal()"></button>
        </div>
    </div>
    <p-table class="productsList" [paginator]="true" [paginatorPosition]="isMobileDevice ? 'top' : 'bottom'"
    [rows]="10" [value]="allProducts  | productFilter : categoryFilter : appliedFilter : textFilter"
        [scrollable]="true" selectionMode="single" [(selection)]="productRowSelected" dataKey="id"
        (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)">
        <ng-template pTemplate="header">
            <tr>
                <th>Nombre</th>
                <th class="phoneHide centerText">Categoria</th>
                <th class="phoneHide centerText">Cont.Net.</th>
                <th class="centerText">Precio</th>
                <th class="centerText">Disponible</th>
                <th class="phoneHide centerText">Opciones</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr [pSelectableRow]="product">
                <td>{{product.name}}</td>
                <td class="phoneHide centerText">{{setCategoryName(product.category)}}</td>
                <td class="phoneHide centerText">{{product.netcontent}}</td>
                <td class="centerText">{{product.price | currency}}</td>
                <td class="centerText"> <i class="pi pi-circle-on"
                        [ngStyle]="{'color':product.totalpieces > 0 ? 'green' : 'red' }"
                        pTooltip="{{product.totalpieces}}">
                    </i> </td>
                <td class="phoneHide centerText">
                    <span class="trashIcon" (click)="openDeleteProductModal(product)" pTooltip="Eliminar"
                        tooltipPosition="top"></span>
                    <span class="optionsIcon" (click)="openEditProductModal(product.id)" pTooltip="Modificar"
                        tooltipPosition="top" style="margin-left: 15px;"></span>
                </td>
            </tr>
        </ng-template>
    </p-table>


    <div class="phoneShow bottomBar">
        <div style="display: flex;align-items: center;height: 100%;padding: 8px;">
            <button pButton type="button" class="p-button-help" label="Agregar Producto" icon="pi pi-plus "
                (click)="openProductModal()"></button>
            <button pButton type="button" class="p-button-secondary" label="Categorias" (click)="openCategoriesModal()"
                style="margin-left: 8px;"></button>
            <button class="trashIcon" (click)="openDeleteProductModal(productRowSelected.id)" pTooltip="Eliminar"
                tooltipPosition="top" style="margin-left: 12px; height: 25px;width: 23px;"
                [ngStyle]="{'opacity': !productRowSelected ? '0.3' : '1' }" [disabled]="!productRowSelected">
            </button>
            <button class="optionsIcon" (click)="openEditProductModal(productRowSelected.id)" pTooltip="Modificar"
                tooltipPosition="top" style="margin-left: 12px;height: 25px;width: 25px;"
                [ngStyle]="{'opacity': !productRowSelected ? '0.3' : '1' }" [disabled]="!productRowSelected"></button>
        </div>
    </div>

    <p-dialog header="Agregar producto" [(visible)]="productModalStatus" [modal]="true" [baseZIndex]="10000"
        [draggable]="false" [resizable]="false">
        <p-messages [(value)]="msg"></p-messages>
        <div class="p-grid" style="padding: 8px;">
            <div class="p-col-12 p-md-4">
                <div class="inputLabel">Nombre:</div>
                <input type="text" pInputText [style]="{width: '98%'}" [(ngModel)]="productForm.Name">
            </div>
            <div class="p-col-12 p-md-4">
                <div for="Price" class="inputLabel">Precio de día:</div>
                <p-inputNumber id="Price" [style]="{width: '98%'}" [(ngModel)]="productForm.Price" mode="decimal"
                    [useGrouping]="false">
                </p-inputNumber>
            </div>
            <div class="p-col-12 p-md-4">
                <div for="PriceNight" class="inputLabel">Precio de noche:</div>
                <p-inputNumber id="PriceNight" [style]="{width: '98%'}" [(ngModel)]="productForm.PriceNight"
                    mode="decimal" [useGrouping]="false"></p-inputNumber>
            </div>
            <div class="p-col-12 p-md-4">
                <div class="inputLabel">Categoria:</div>
                <p-dropdown class="p-col-12 padding-0" [options]="allCategories" [(ngModel)]="productForm.Category"
                    optionLabel="category" [style]="{width: '98%'}">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-4">
                <div for="NetContent" class="inputLabel">Contenido neto</div>
                <input id="NetContent" type="text" pInputText [style]="{width: '98%'}"
                    [(ngModel)]="productForm.NetContent">
            </div>
            <!-- <div class="p-grid p-ai-center vertical-container" style="margin-top: 25px;padding: 15px;">
                <p-fileUpload #form mode="basic" [auto]="true" chooseLabel="Añadir foto (Opcional)" name="files[]"
                    accept="image/*" (onSelect)="onFileSelected($event, form)"
                    [disabled]="productForm.Photo ? true : false"></p-fileUpload>
                <div *ngIf="productForm.Photo"
                    style="margin-left: 10px;padding: 6px;background-color: lightslategray;border-radius: 8px;">
                    <img style="height: 50px;width: auto;" src="{{productForm.Photo}}" alt="">
                    <i class="pi pi-times" style="color: lightsalmon;margin-left: 5px;"
                        (click)="productForm.Photo=null"></i>
                </div>
            </div> -->

            <!-- <div class="p-col-12">
                <div style="display: flex; align-items: end; height: 100%;">
                    <p-fileUpload *ngIf="!productForm.Photo" #form mode="basic" [auto]="true"
                        chooseLabel="Añadir foto (Opcional)" name="files[]" accept="image/*"
                        (onSelect)="onFileSelected($event, form)" [disabled]="productForm.Photo ? true : false">
                    </p-fileUpload>
                    <div *ngIf="productForm.Photo" style="position: relative;">
                        <div class="inputLabel">Foto:</div>
                        <input type="text" pInputText style="width: 100%; text-overflow: ellipsis;padding-right: 30px;"
                            [(ngModel)]="fileName">
                        <i class="pi pi-times" style="color: lightsalmon;position: absolute;right: 10px;bottom: 7px;"
                            (click)="productForm.Photo=null"></i>
                    </div>
                </div>
            </div> -->

            <div class="p-col-12">
                <div>Foto: </div>
                <p-fileUpload #form [showUploadButton]="false" (onSelect)="onUpload($event)" (onClear)="clearImages()"
                    [multiple]="false" accept="image/*"></p-fileUpload>
            </div>

        </div>
        <ng-template pTemplate="footer">
            <p-button label="Agregar" styleClass="p-button-success" (click)="createProductValidation()">
            </p-button>
        </ng-template>
    </p-dialog>

    <p-dialog header="Borrar producto" [(visible)]="productDeleteModalStatus" [modal]="true" [baseZIndex]="10000"
        [draggable]="false" [resizable]="false">
        <div style="padding: 8px;">¿Seguro que desea eliminar el producto?</div>
        <ng-template pTemplate="footer">
            <p-button (click)="productDeleteModalStatus=false" label="No" styleClass="p-button-text">
            </p-button>
            <p-button (click)="deleteProduct()" label="Si" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>

    <p-dialog header="Editar producto" [(visible)]="productEditModalStatus" [modal]="true" [baseZIndex]="10000"
        [draggable]="false" [resizable]="false">
        <p-messages [(value)]="msg"></p-messages>
        <div class="p-grid" style="padding: 8px;">
            <div class="p-col-12 p-md-4">
                <div class="inputLabel">Nombre:</div>
                <input type="text" pInputText [style]="{width: '98%'}" [(ngModel)]="productForm.Name">
            </div>
            <div class="p-col-12 p-md-4">
                <div for="Price" class="inputLabel">Precio de día:</div>
                <p-inputNumber id="Price" [style]="{width: '98%'}" [(ngModel)]="productForm.Price" mode="decimal"
                    [useGrouping]="false">
                </p-inputNumber>
            </div>
            <div class="p-col-12 p-md-4">
                <div for="PriceNight" class="inputLabel">Precio de noche:</div>
                <p-inputNumber id="PriceNight" [style]="{width: '98%'}" [(ngModel)]="productForm.PriceNight"
                    mode="decimal" [useGrouping]="false"></p-inputNumber>
            </div>
            <div class="p-col-12 p-md-4">
                <div class="inputLabel">Categoria:</div>
                <p-dropdown class="p-col-12 padding-0" [options]="allCategories" [(ngModel)]="productForm.Category"
                    optionLabel="category" [style]="{width: '98%'}">
                </p-dropdown>
            </div>
            <div class="p-col-12 p-md-4">
                <div for="NetContent" class="inputLabel">Contenido neto</div>
                <input id="NetContent" type="text" pInputText [style]="{width: '98%'}"
                    [(ngModel)]="productForm.NetContent">
            </div>
            <!-- <div class="p-grid p-ai-center vertical-container" style="margin-top: 25px;padding: 15px;">
                <p-fileUpload #form mode="basic" [auto]="true" chooseLabel="Añadir foto (Opcional)" name="files[]"
                    accept="image/*" (onSelect)="onFileSelected($event, form)"
                    [disabled]="productForm.Photo ? true : false"></p-fileUpload>
                <div *ngIf="productForm.Photo"
                    style="margin-left: 10px;padding: 6px;background-color: lightslategray;border-radius: 8px;">
                    <img style="height: 50px;width: auto;" src="{{productForm.Photo}}" alt="">
                    <i class="pi pi-times" style="color: lightsalmon;margin-left: 5px;"
                        (click)="productForm.Photo=null"></i>
                </div>
            </div> -->
            <!-- <div class="p-col-12 p-md-4">
                <div style="display: flex; align-items: end; height: 100%;">
                    <p-fileUpload *ngIf="!productForm.Photo" #form mode="basic" [auto]="true"
                        chooseLabel="Añadir foto (Opcional)" name="files[]" accept="image/*"
                        (onSelect)="onFileSelected($event, form)" [disabled]="productForm.Photo ? true : false">
                    </p-fileUpload>
                    <div *ngIf="productForm.Photo" style="position: relative;">
                        <div class="inputLabel">Foto:</div>
                        <input type="text" pInputText style="width: 100%; text-overflow: ellipsis;padding-right: 30px;"
                            [(ngModel)]="fileName">
                        <i class="pi pi-times" style="color: lightsalmon;position: absolute;right: 10px;bottom: 7px;"
                            (click)="productForm.Photo=null"></i>
                    </div>
                </div>
            </div> -->

            <div *ngIf="productForm.Photo" class="p-col-4">
                <div>Foto: </div>
                <div class="p-d-flex p-ai-center">
                    <img style="height: 90px;width: auto;" src="{{productForm.Photo}}" alt="">
                    <i class="pi pi-times" style="color: lightsalmon;margin-left: 5px;"
                        (click)="productForm.Photo=null"></i>
                </div>
            </div>

            <div *ngIf="!productForm.Photo" class="p-col-12">
                <div>Foto: </div>
                <p-fileUpload #form [showUploadButton]="false" (onSelect)="onUpload($event)" (onClear)="clearImages()"
                    [multiple]="false" accept="image/*"></p-fileUpload>
            </div>
        </div>
        <ng-template pTemplate="footer">
            <p-button label="Guardar" styleClass="p-button-success" (click)="editProductValidation()">
            </p-button>
        </ng-template>
    </p-dialog>

    <p-dialog header="Categorias" [(visible)]="categoriesModalStatus" [modal]="true" class="categoriesModal"
        [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <div class="p-grid p-jc-end padding-0">
            <button pButton type="button" class="p-button-help" label="Crear categoria" icon="pi pi-users "
                style="margin-right: 9px;" (click)="openCreateCategory()"></button>
        </div>
        <p-table class="categoriesList" [value]="allCategories" [scrollable]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th>Cargo</th>
                    <th>Usuarios con ese cargo</th>
                    <th>Opciones</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-category>
                <tr>
                    <td>{{category.category}}</td>
                    <td>{{category.count}}</td>
                    <td>
                        <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded"
                            style="margin-right: 10px;" pTooltip="Editar" tooltipPosition="top"
                            (click)="openEditCategoryModal(category)"></button>
                        <button *ngIf="category.count == 0" pButton pRipple type="button" icon="pi pi-trash"
                            class="p-button-rounded p-button-danger" pTooltip="Eliminar" tooltipPosition="top"
                            (click)="openDeleteCategoryModal(category.id)"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <ng-template pTemplate="footer">
            <p-button (click)="categoriesModalStatus=false" label="Cerrar" styleClass="p-button-secondary">
            </p-button>
        </ng-template>
    </p-dialog>

    <p-dialog header="Crear categoria" [(visible)]="categoryCreateStatus" [modal]="true" [baseZIndex]="10001"
        [draggable]="false" [resizable]="false">
        <p-messages [(value)]="msg"></p-messages>
        <span class="p-float-label padding-0" style="margin-top: 25px;">
            <input id="categoryName" type="text" pInputText [(ngModel)]="categoryName"
                style="margin: 0px 8px 8px 8px;width: calc(100% - 16px);">
            <label for="categoryName">Nombre para nueva categoria</label>
        </span>
        <ng-template pTemplate="footer">
            <p-button (click)="categoryCreateStatus=false" label="No" styleClass="p-button-text"></p-button>
            <p-button (click)="createCategory()" label="Crear" styleClass="p-button-success"></p-button>
        </ng-template>
    </p-dialog>

    <p-dialog header="Borrar categoria" [(visible)]="categoryDeleteStatus" [modal]="true" [baseZIndex]="10001"
        [draggable]="false" [resizable]="false">
        <div style="padding: 8px;">¿Seguro que desea eliminar la categoria?</div>
        <ng-template pTemplate="footer">
            <p-button (click)="categoryDeleteStatus=false" label="No" styleClass="p-button-text"></p-button>
            <p-button (click)="deleteCategory()" label="Si" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>

    <p-dialog header="Editar categoria" [(visible)]="categoryEditStatus" [modal]="true" [baseZIndex]="10001"
        [draggable]="false" [resizable]="false">
        <p-messages [(value)]="msg"></p-messages>
        <span class="p-float-label padding-0" style="margin-top: 25px;">
            <input id="categoryName" type="text" pInputText [(ngModel)]="categoryName"
                style="margin: 0px 8px 8px 8px;width: calc(100% - 16px);">
            <label for="categoryName">Nombre para categoria</label>
        </span>
        <ng-template pTemplate="footer">
            <p-button (click)="categoryEditStatus=false" label="No" styleClass="p-button-text"></p-button>
            <p-button (click)="editCategory()" label="Guardar" styleClass="p-button-success"></p-button>
        </ng-template>
    </p-dialog>

    <p-toast position="top-right"></p-toast>
</div>
