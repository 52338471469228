import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.baseUrl;


  getCatalog() {
    return this.http.get(`${this.baseUrl}Product`, { responseType: 'json' })
  }

  getAllProducts() {
    return this.http.get(`${this.baseUrl}ProductList`, { responseType: 'json' })
  }

  getProductDetails(id) {
    return this.http.post(`${this.baseUrl}ProductDetails`, { 'id': id }, { responseType: 'json' })
  }

  ProductActions(data) {
    return this.http.post(`${this.baseUrl}Product`, data, { responseType: 'text' })
  }

  getCategories() {
    return this.http.get(`${this.baseUrl}Category`, { responseType: 'json' })
  }

  CategoryActions(data) {
    return this.http.post(`${this.baseUrl}Category`, data, { responseType: 'text' })
  }

  getLazyAcquisitions(data) {
    return this.http.post(`${this.baseUrl}Acquisitions`, data, { responseType: 'json' })
  }
  getAcquisitions() {
    return this.http.get(`${this.baseUrl}Acquisitions`, { responseType: 'json' })
  }
  AcquisitionsActions(data) {
    return this.http.post(`${this.baseUrl}Acquisitions`, data, { responseType: 'text' })
  }

  getWarehouses() {
    return this.http.get(`${this.baseUrl}Warehouse`, { responseType: 'json' })
  }

  WarehouseActions(data) {
    return this.http.post(`${this.baseUrl}Warehouse`, data, { responseType: 'text' })
  }

  getCurrencies() {
    return this.http.get(`${this.baseUrl}Currencies`, { responseType: 'json' })
  }

  CurrenciesActions(data) {
    return this.http.post(`${this.baseUrl}Currencies`, data, { responseType: 'text' })
  }

  ProductManage(data) {
    return this.http.post(`${this.baseUrl}ProductManage`, data, { responseType: 'json' })
  }

  GetProductPhoto(id: any) {
    return this.http.post(`${this.baseUrl}GetProducImage`, { id: id })
  }

  sendNotification(data) {
    return this.http.post(`${this.baseUrl}Notifications`, data, { responseType: 'json' })
  }


}
