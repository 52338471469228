<div class="contactContainer p-grid">
    <div class="p-col-12 padding-0">
        <app-header-public></app-header-public>
    </div>
    <div class="p-col-12 section1">
        <div class="p-d-flex p-ai-center">
            <div class="p-col">
                <i class="pi pi-bars pointer" style="font-size: 17px;margin-left: 20%;"
                    (click)="menuDisplay=!menuDisplay"></i>
            </div>
            <img class="logo pointer" (click)="goHome()" src="{{logoCompleto}}" alt="logo Barman">
            <div class="p-col"></div>
        </div>
    </div>

    <div class="p-col-12" style="font-size: 40px;color: #caa462;">Cotizaciones</div>

    <div class="p-col-12 section2">
        <div class="p-grid">
            <div class="p-col-12">
                Para realizar una cotización es necesario que ingrese los siguientes datos, una vez enviados los datos
                un miembro del personal lo contactara para atender todas sus dudas y brindarle el mejor apoyo.
            </div>
            <span class="p-col-12 p-md-3 p-input-icon-left">
                <i class="pi pi-user"></i>
                <input type="text" pInputText [(ngModel)]="form.Name" placeholder="Nombre">
            </span>
            <span class="p-col-12 p-md-3  p-input-icon-left">
                <i class="pi pi-phone" style="position: absolute;"></i>
                <p-inputNumber [(ngModel)]="form.Phone" mode="decimal" [useGrouping]="false" [maxlength]="10"
                    [minlength]="10" placeholder="Telefono"></p-inputNumber>
            </span>
            <div class="p-col-12">
                Escriba a continuación los detalles de la cotización (Productos, cantidad y fecha deseada de entrega) :
            </div>
            <div class="p-col-12">
                <textarea pInputTextarea autoResize="autoResize" placeholder="Descripción..."
                    [(ngModel)]="form.Message"></textarea>
            </div>
            <div class="p-col-12">
                <div class="p-d-flex p-jc-end">
                    <button pButton type="button" icon="pi pi-check" iconPos="left" label="Enviar"
                        (click)="quoteValidation()"></button>
                </div>
            </div>
        </div>
    </div>

    <p-sidebar [(visible)]="menuDisplay">
        <div class="menuList">
            <a [routerLink]="'/'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                #Home="routerLinkActive">
                <div [ngClass]=" Home.isActive ? 'menuitemActive': 'menuitem'">Inicio</div>
            </a>
            <a [routerLink]="'/Catalogo'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                #Catalog="routerLinkActive">
                <div [ngClass]=" Catalog.isActive ? 'menuitemActive': 'menuitem'">Catalogo</div>
            </a>
            <a [routerLink]="'/Contacto'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                #Contact="routerLinkActive">
                <div [ngClass]=" Contact.isActive ? 'menuitemActive': 'menuitem'">Cotizaciones</div>
            </a>
        </div>
    </p-sidebar>

    <p-toast position="top-right"></p-toast>
</div>