import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { HomepageService } from '../homepage.service';

@Component({
  selector: 'app-catalog-public',
  templateUrl: './catalog-public.component.html',
  styleUrls: ['./catalog-public.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CatalogPublicComponent implements OnInit {

  allProducts: any;
  categories: any;
  categoryFilter: any;
  appliedFilter: any;
  textFilter: any;
  menuDisplay: boolean = false;
  selectedProductStatus: boolean = false;
  selectedProduct: any = { name: '', photo: '' };
  scrollTopStatus: boolean = false;

  logoCompleto: string = '/assets/Images/fullLogo.svg';
  defaultImage: string = 'assets/Images/system/default.png'

  @HostListener('window:scroll', ['$event']) onScroll(event) {
    if (window.pageYOffset > 300) { this.scrollTopStatus = true; } else { this.scrollTopStatus = false; }
  }

  constructor(private HomeService: HomepageService, private router: Router, private scroll: ViewportScroller) { }

  ngOnInit(): void {

    this.HomeService.getCategories().subscribe(res1 => {
      this.categories = res1;
      this.HomeService.getCatalog().subscribe(res2 => this.allProducts = res2);
    });
  }

  setCategoryName(id) {
    let text = "";
    this.categories.forEach(item => {
      if (id == item.id) { text = item.category }
    });
    return text;
  }

  showProduct(item) {
    this.selectedProduct = item;
    this.selectedProductStatus = true;
  }

  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }

  goHome() {
    this.router.navigate(['']);
  }

  ProductPhoto(item: any) {
    if (item.photoStatus == null) {
      this.HomeService.GetProductPhoto(item.id).subscribe(data => {
        Object.assign(item, { photo: data });
      });
      Object.assign(item, { photoStatus: true });
    }
    return null;
  }

}
