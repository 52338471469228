import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthGuard } from '../auth.guard';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  constructor(private loginService: LoginService, private router: Router, private authentication: AuthGuard) {
    if (this.loginService.currentUserValue) { this.router.navigate(['system']); }
  }

  user: string = "";
  pass: string = "";
  msg: string;
  validationStatus: boolean = false;
  returnUrl: string;

  backImage2 = "assets/Images/system/Login/LoginBackground.jpg"
  backImage = "assets/Images/system/Background.png"
  Logo = "assets/Images/mainLogo.svg"

  ngOnInit(): void {
  }

  connect() {

    this.validationStatus = false;

    if (this.user != "" && this.pass != "") {
      let data = {
        user: this.user,
        pass: this.pass
      }
      this.loginService.login(data).pipe(first()).subscribe(res => {
        let response: any = res;
        if (response.length != 0) {
          window.location.reload();
        } else {
          this.msg = "Usuario y/o contraseña incorrectos";
          this.validationStatus = true;
        }
      }, error => {
        console.log(error);
      });
    } else {
      this.msg = "Llene ambos campos";
      this.validationStatus = true;
    }
  }
  goHome(){
    this.router.navigate(['']);
  }


}
