<div class="producManageContainer">
    <p-tabView>
        <p-tabPanel header="Existencia por Producto">
            <div class="p-grid">
                <div class="p-col-12">Seleccione el producto a mover</div>
                <span class="p-col-12 p-md-4">
                    <div>Producto:</div>
                    <p-dropdown [options]="AvailableProducts" [filter]="true" [(ngModel)]="productToMove"
                        optionLabel="name" [style]="{width: '100%'}" (onChange)="getMoveProduct()"
                        placeholder="Seleccione un producto">
                    </p-dropdown>
                </span>
                <div *ngIf="warehousesPieces" class="p-col-12 p-d-flex p-jc-end">
                    <p-button icon="pi pi-replay" styleClass="p-button-text" (click)="undoConfirmationModal=true">
                    </p-button>
                </div>
            </div>

            <p-table *ngIf="warehousesPieces" [value]="warehousesPieces" [scrollable]="false">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Almacen</th>
                        <th>Piezas</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td>{{product.warehouseName}}</td>
                        <td>{{product.pieces}}</td>
                    </tr>
                </ng-template>
            </p-table>
            <div *ngIf="warehousesPieces" class="p-grid">
                <div class="p-col-12 phoneHide">Seleccine el almancen de origen, la cantidad de piezas a mover y almacen
                    de
                    destino:</div>
                <div class="p-col-12 phoneShow">Almacen de origen:</div>
                <p-dropdown class="p-col-12  p-md-3 " [options]="warehousesPieces" [(ngModel)]="warehouseFrom"
                    optionLabel="warehouseName" [style]="{width: '100%'}" (onChange)="setMoveProduct()">
                </p-dropdown>
                <div class="p-col-12 phoneShow">Piezas a mover:</div>
                <p-inputNumber class="p-col-12  p-md-3" [(ngModel)]="piecesToMove" mode="decimal" [useGrouping]="false"
                    [style]="{width: '100%'}" [inputStyle]="{width: '100%'}">
                </p-inputNumber>
                <div class="p-col-12 phoneShow">Almacen de destino:</div>
                <p-dropdown class="p-col-12  p-md-3" [options]="allWarehouses" [(ngModel)]="warehouseTo"
                    optionLabel="name" [style]="{width: '100%'}">
                </p-dropdown>
                <p-button class="p-col-12  p-md-3" label="Mover" styleClass="p-button-text"
                    (click)="showConfirmationModal()">
                </p-button>
            </div>

        </p-tabPanel>
        <p-tabPanel header="Existencia por Almacenes">
            <div class="p-grid">
                <div class="p-col-12">Seleccione el almacen</div>
                <span class="p-col-12 p-md-4">
                    <div>Almacen:</div>
                    <p-dropdown [options]="allWarehousesToLook" [filter]="true" [(ngModel)]="warehouseToLook"
                        optionLabel="name" [style]="{width: '100%'}" (onChange)="getWarehouseProducts()"
                        placeholder="Seleccione un almacen">
                    </p-dropdown>

                </span>
            </div>

            <p-table *ngIf="warehousesProducts" [value]="warehousesProducts" [scrollable]="true" dataKey="id"
                [globalFilterFields]="['ProductName']">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Producto

                            <p-columnFilter field="ProductName" display="menu" [showMatchModes]="false"
                                [showOperator]="false" [showAddButton]="false" [hideOnClear]="true">
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-dropdown [options]="allProducts" [ngModel]="value" optionLabel="name"
                                        optionValue="name" placeholder="Any" (onChange)="filter($event.value)"
                                        [filter]="true">
                                    </p-dropdown>
                                </ng-template>
                            </p-columnFilter>
                        </th>
                        <th>Piezas</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td>{{product.ProductName}}</td>
                        <td>{{product.pieces}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
    </p-tabView>

    <p-dialog header="Confirmacion" [(visible)]="moveProductConfirmationModal" [modal]="true" [baseZIndex]="10001"
        [draggable]="false" [resizable]="false">
        <div class="">
            <div>¿Seguro que desea hacer el siguiente movimiento?</div><br>
            <strong *ngIf="moveProductConfirmationModal"> {{piecesToMove + " piezas de "+ productToMove.name + " desde "
                + warehouseFrom.warehouseName + " hacia "
                + this.warehouseTo.name }}</strong>
        </div>

        <ng-template pTemplate="footer">
            <p-button (click)="moveProductConfirmationModal=false" label="No" styleClass="p-button-text">
            </p-button>
            <p-button (click)="moveProduct()" label="Si" styleClass="p-button-success"></p-button>
        </ng-template>
    </p-dialog>

    <p-dialog header="Confirmacion" [(visible)]="undoConfirmationModal" [modal]="true" [baseZIndex]="10001"
        [draggable]="false" [resizable]="false">
        <div class="">
            <div>¿Seguro que desea deshacer cambios?</div>
        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="undoConfirmationModal=false" label="No" styleClass="p-button-text">
            </p-button>
            <p-button (click)="undoChanges()" label="Si" styleClass="p-button-success"></p-button>
        </ng-template>
    </p-dialog>

    <p-toast position="top-right"></p-toast>
</div>