<div class=" ordersMainContainer">

    <div class="OrdersTitle">Gestion de ventas</div>

    <div class="ordersSubContainer">
        <p-tabView>

            <p-tabPanel header="Cierres de venta">
                <div class="p-d-flex p-flex-wrap p-flex-md-row p-flex-column p-jc-md-between searchOptions">
                    <div class="p-d-flex p-flex-wrap p-flex-md-row p-flex-column padding-bottom">
                        <div>Seleccione el periodo : </div>
                        <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="false"
                            inputId="range" [style]="{width: '100%'}">
                        </p-calendar>
                    </div>
                    <div class="p-d-flex p-flex-wrap p-flex-md-row p-flex-column padding-bottom">
                        <div>Seleccione el Usuario : </div>
                        <p-dropdown [options]="allUsers" [filter]="true" [(ngModel)]="salesman" optionLabel="name"
                            placeholder="Todos" [showClear]="true" [style]="{width: '100%'}">
                        </p-dropdown>
                    </div>
                    <button pButton pRipple type="button" label="Buscar" (click)="searchOrders()"
                        class="padding-bottom"></button>

                    <button pButton pRipple type="button" icon="pi pi-file-pdf" class="p-button-rounded phoneHide"
                        (click)="PDFdownload()" [disabled]="!allOrders"></button>
                </div>

                <p-table *ngIf="allOrders" class="orderList" [value]="allOrders" [scrollable]="true"
                    selectionMode="single" [(selection)]="orderRowSelected" dataKey="id"
                    (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)">

                    <ng-template pTemplate="header">
                        <tr>
                            <th class="phoneHide">N. de Orden</th>
                            <th>Cliente</th>
                            <th>Vendedor</th>
                            <th>Fecha y hora</th>
                            <th class="centerText">Status</th>
                            <th class="phoneHide">Detalles</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-order>
                        <tr [pSelectableRow]="order">
                            <td class="phoneHide">{{order.id}}</td>
                            <td>{{order.client}}</td>
                            <td>{{order.salesman}}</td>
                            <td>{{order.orderDate | date:'short'}}</td>
                            <td [style]="setStatusColor(order.orderStatus)" class="centerText">
                                <i class="pi pi-circle-on"></i>
                            </td>
                            <td class="phoneHide">
                                <button pButton pRipple type="button" icon="pi pi-book" class="p-button-rounded"
                                    (click)="openOrderDetails(order)"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

                <div class="phoneShow bottomBar">
                    <div style="display: flex;align-items: center;height: 100%;padding: 8px;">
                        <button pButton pRipple type="button" icon="pi pi-book" class="p-button" label="Detalles"
                            (click)="openOrderDetails(orderRowSelected)" [disabled]="!orderRowSelected"></button>
                        <button *ngIf="allOrders" pButton pRipple type="button" icon="pi pi-file-pdf" class="p-button"
                            label="Descargar Corte" (click)="PDFdownload()" style="margin-left: 10px;"></button>
                    </div>
                </div>

                <p-dialog header="Detalles de orden" [(visible)]="orderDetailsModalStatus" [modal]="true"
                    [baseZIndex]="10000" [draggable]="false" [resizable]="false">
                    <!-- [style]="{width: '35vw'}" -->
                    <p-messages [(value)]="msg"></p-messages>
                    <div class="p-grid">
                        <div class="p-col-6"><span>ID : </span>{{orderForm.Id}}</div>
                        <div class="p-col-6"><span>ClientId : </span>{{orderForm.ClientId}}</div>
                        <div class="p-col-6"><span>Cliente : </span>{{orderForm.Client}}</div>
                        <div class="p-col-6"><span>Fecha : </span>{{orderForm.Date | date:'short'}}</div>
                        <div class="p-col-6"><span>Status : </span>
                            <span [style]="setStatusColor(orderForm.Status)">
                                <i class="pi {{setStatusIcon(orderForm.Status)}}"></i>
                                {{setOrderStatus(orderForm.Status)}}
                                <span *ngIf="orderForm.Delivery">{{getDeliveryTime()}}</span>
                            </span>

                        </div>
                        <div class="p-col-6"><span>Factura : </span>{{orderForm.Invoice}}</div>
                        <div class="p-col-6"><span>Vendedor : </span>{{orderForm.Salesman}}</div>
                        <div class="p-col-6"><span>Ubicación : </span>
                            <a *ngIf="orderForm.Location!=null && isURL" href="{{orderForm.Location}}" target="_blank"
                                style="text-decoration: none;color: #CAA462;">Abrir en Maps</a>
                            <span *ngIf="!isURL">{{orderForm.Location}}</span>
                        </div>
                        <div class="p-col-6"><span>Telefono : </span>{{orderForm.Phone}}</div>
                        <div class="p-col-6 padding-0">

                        </div>
                        <p-table [value]="orderForm.Products" styleClass="p-col-12" [scrollable]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Cantidad</th>
                                    <th>Nombre</th>
                                    <th>Precio de compra</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-order>
                                <tr>
                                    <td>{{order.quantity}}</td>
                                    <td>{{order.name}}</td>
                                    <td>{{order.priceAtTheTime}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="p-col-12 p-d-flex p-jc-between">
                            <span>Extras : {{orderForm.Extras}}</span>
                            <p-button *ngIf="orderForm.Status!=3" label="Origen de productos"
                                styleClass="p-button-secondary" (click)="productsOriginModalStatus=true">
                            </p-button>
                        </div>
                        <div class="p-col-12 padding-0">
                            <span style="float: right;">Precio sugerido : {{orderForm.SuggestedPrice}}</span>
                        </div>
                        <div class="p-col-12 padding-0">
                            <span style="float: right;font-weight: bolder;">Precio final : {{orderForm.Price}}</span>
                        </div>

                    </div>
                    <ng-template pTemplate="footer">
                        <div *ngIf="orderForm.Status==1" class="p-d-flex p-jc-between">
                            <p-button label="Cancelar Orden" styleClass="p-button-secondary" (click)="CancelOrder()">
                            </p-button>
                            <p-button label="Editar Orden" styleClass="p-button-secondary" (click)="editOrder()">
                            </p-button>
                            <p-button label="Confirmar entrega" styleClass="p-button-secondary"
                                (click)="confirmDelivery()">
                            </p-button>
                        </div>
                        <p-button *ngIf="orderForm.Status!=1" label="Cerrar ventana" styleClass="p-button-secondary"
                            (click)="orderDetailsModalStatus=false">
                        </p-button>
                    </ng-template>
                </p-dialog>

                <p-dialog header="Origen de Producto" [(visible)]="productsOriginModalStatus" [modal]="true"
                    [baseZIndex]="10000" [draggable]="false" [resizable]="false">
                    <!-- [style]="{width: '35vw'}" -->
                    <p-messages [(value)]="msg"></p-messages>
                    <div class="p-grid">

                        <p-table [value]="orderForm.Origin" styleClass="p-datatable-striped p-col-12"
                            [scrollable]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Cantidad</th>
                                    <th>Producto</th>
                                    <th>Almacen</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-order>
                                <tr>
                                    <td>{{order.substracted}}</td>
                                    <td>{{order.productName}}</td>
                                    <td>{{order.warehouseName}}</td>
                                </tr>
                            </ng-template>
                        </p-table>

                    </div>
                    <ng-template pTemplate="footer">
                        <p-button label="Cerrar ventana" styleClass="p-button-secondary"
                            (click)="productsOriginModalStatus=false">
                        </p-button>
                    </ng-template>
                </p-dialog>

                <div #PDFcontainer style="display: none;">
                    <div id="exportTable" class="exportTable">
                        <div style="width: 100%;border-bottom: 1px solid black;font-size: 25px;padding: 8px;">Corte
                        </div>
                        <div style="width: 100%;padding: 10px;">
                            <div style="display: flex;align-items: center;justify-content: space-between;">

                                <!-- <img src="assets/Images/horizontalLogo.svg" style="width: 200px;margin: 10px;"> -->
                                <div style="width: 200px;height: 42px;"></div>

                                <div>
                                    <div *ngIf="rangeDates">
                                        <strong>Periodo: </strong>
                                        {{rangeDates[0] | date:'shortDate'}} -
                                        {{rangeDates[1] | date:'shortDate'}}
                                    </div>
                                    <div *ngIf="salesman"><strong>Vendedor: </strong> {{salesman.name}} </div>
                                    <div *ngIf="!salesman"><strong>Vendedor: </strong> Todos</div>
                                </div>

                                <div>
                                    <div><strong> Corte sugerido:</strong><span>{{suggestedIncomingPrice}}</span></div>
                                    <div><strong> Total de corte:</strong><span>______________</span></div>
                                </div>
                            </div>
                        </div>
                        <table>
                            <tr>
                                <th>N. de Orden</th>
                                <th>Cliente</th>
                                <th>Vendedor</th>
                                <th>Fecha y hora</th>
                                <th>Status</th>
                                <th>Precio sugerido</th>
                                <th>Precio venta</th>
                            </tr>
                            <div *ngFor="let order of allOrders" style="display: contents;">
                                <tr *ngIf="order.orderStatus==2" style="background-color: #edd9b8;">
                                    <td>{{order.id}}</td>
                                    <td>{{order.client}}</td>
                                    <td>{{order.salesman}}</td>
                                    <td>{{order.orderDate | date:'short'}}</td>
                                    <td>{{setOrderStatus(order.orderStatus)}}</td>
                                    <td style="text-align: end;"><strong>{{order.suggestedPrice}}</strong></td>
                                    <td style="text-align: end;">{{order.price}}</td>
                                </tr>
                                <div *ngIf="order.orderStatus==2" style="display: contents;">
                                    <tr *ngFor="let product of order.products" style="background-color: #ffffff;">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style="text-align: end;">{{product.quantity}}</td>
                                        <td>{{product.name}}</td>
                                        <td style="text-align: end;">{{product.priceAtTheTime}}</td>
                                        <td></td>
                                    </tr>
                                </div>
                                <div *ngIf="order.orderStatus==2 && order.extras != null" style="display: contents;">
                                    <tr style="background-color: #ffffff;">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style="text-align: end;">Extras: </td>
                                        <td>{{order.extras}}</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </div>
                            </div>
                        </table>
                    </div>
                </div>
            </p-tabPanel>

            <p-tabPanel header="Facturas">
                <div class="p-d-flex p-flex-wrap p-flex-md-row p-flex-column p-jc-md-between searchOptions">
                    <div class="p-d-flex p-flex-wrap p-flex-md-row p-flex-column padding-bottom">
                        <span>Seleccione el periodo : </span>
                        <p-calendar [(ngModel)]="invoiceRangeDates" selectionMode="range" [readonlyInput]="false"
                            inputId="range" [style]="{width: '100%'}">
                        </p-calendar>
                    </div>

                    <div class="p-d-flex p-flex-wrap p-flex-md-row p-flex-column padding-bottom">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input type="search" pInputText placeholder="Buscar cliente"
                                [(ngModel)]="invoiceTextFilter" [style]="{width: '100%'}">
                        </span>
                    </div>

                    <button pButton pRipple type="button" label="Buscar" (click)="searchInvoiceOrders()"
                        class="padding-bottom"></button>

                </div>

                <p-table *ngIf="allInvoiceOrders" class="orderList" [value]="allInvoiceOrders" [scrollable]="true"
                    selectionMode="single" [(selection)]="orderInvoiceRowSelected" dataKey="id"
                    (onRowSelect)="onInvoiceRowSelect($event)" (onRowUnselect)="onInvoiceRowUnselect($event)">

                    <ng-template pTemplate="header">
                        <tr>
                            <th class="phoneHide">N. de Orden</th>
                            <th>Cliente</th>
                            <th>Vendedor</th>
                            <th>Fecha y hora</th>
                            <th class="centerText">Status</th>
                            <th class="phoneHide">Detalles</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-order>
                        <tr [pSelectableRow]="order">
                            <td class="phoneHide">{{order.id}}</td>
                            <td>{{order.client}}</td>
                            <td>{{order.salesman}}</td>
                            <td>{{order.orderDate | date:'short'}}</td>
                            <td [style]="setStatusColor(order.orderStatus)" class="centerText">
                                <i class="pi pi pi-circle-on"></i>
                            </td>
                            <td class="phoneHide">
                                <button *ngIf="order.orderStatus == 2" pButton pRipple type="button"
                                    icon="pi pi-file-pdf" class="p-button-rounded phoneHide"
                                    (click)="PDFdownloadInvoice(order)"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

                <div class="phoneShow bottomBar">
                    <div style="display: flex;align-items: center;height: 100%;padding: 8px;">
                        <button pButton pRipple type="button" icon="pi pi-file-pdf" class="p-button"
                            label="Descargar factura" (click)="PDFdownloadInvoice(orderInvoiceRowSelected)"
                            [disabled]="!orderInvoiceRowSelected"></button>
                    </div>
                </div>


                <div #PDFInvoice style="display: none;">
                    <div id="InvoiceOrder"
                        style="width: 595px;height: 841px;color: black;background: white;font-size: 12px;position: relative;">

                        <div
                            style="display: flex;align-items: center;justify-content: center; background: black;height: 170px;">

                            <!-- <img src="assets/Images/horizontalLogo.svg" style="width: 200px;margin: 10px;"> -->
                            <div style="width: 200px;margin: 10px;height: 42px;"></div>

                            <div style="color: white;text-align: end;margin-left: 85px;">
                                <div style="font-size: 34px;">Factura</div>
                                <div style="font-size: 15px;">Servicios Barman S.A. De C. V.</div>
                                <div>Culiacán, Sinaloa, México.</div>
                            </div>

                        </div>

                        <div style="display: flex;align-items: center;justify-content: space-between;padding: 25px;">

                            <div>
                                <strong>Cliente: </strong>
                                <div>{{clientForm.Client}}</div>
                                <strong>Dirección: </strong>
                                <div>{{clientForm.Address}}</div>
                                <strong>RFC:</strong>
                                <div>{{clientForm.RFC}}</div>
                            </div>

                            <div>
                                <strong>Tipo de gasto: </strong>
                                <div>{{clientForm.ExpenseType}}</div>
                                <strong>Correo: </strong>
                                <div>{{clientForm.Email}}</div>
                                <strong>Telefono:</strong>
                                <div>{{clientForm.Phone}}</div>
                            </div>

                            <div style="text-align: end;">
                                <strong># de Orden</strong>
                                <div>{{orderForm.Id}}</div>
                                <strong>Fecha de compra</strong>
                                <div>{{orderForm.Date | date:'shortDate'}}</div>
                                <strong>Generación de factura</strong>
                                <div>{{invoiceCreateDate | date:'shortDate'}}</div>
                            </div>

                        </div>

                        <div style="padding: 0px 25px 25px 25px;">
                            <table style="border-top: 1px solid #CAA462;text-align: end;width: 100%;">
                                <tr>
                                    <th style="text-align: left;">Producto</th>
                                    <th style="text-align: center;">Cantidad</th>
                                    <th>Precio</th>
                                    <th>IVA</th>
                                    <th>Cantidad</th>
                                </tr>
                                <tr *ngFor="let product of orderForm.Products">
                                    <td style="text-align: left;padding: 12px 0px;">{{product.name}}</td>
                                    <td style="text-align: center;">{{product.quantity}}</td>
                                    <td>{{product.priceAtTheTime * 0.84 | currency}}</td>
                                    <td>{{product.priceAtTheTime * 0.16 | currency}}</td>
                                    <td>{{product.priceAtTheTime * product.quantity | currency}}</td>
                                </tr>
                            </table>
                            <div *ngIf="orderForm.Extras" style="width: 100%;padding-top: 12px;">
                                <strong>Extras: </strong><span style="padding-left: 10px;">{{orderForm.Extras}}</span>
                            </div>
                        </div>

                        <div style="width: 100%;height: 100px;position: absolute;bottom: 40px;">
                            <div class="p-grid" style="height: 100;width: 100%;">
                                <div class="p-col-7" style="padding: 20px;background: #edd9b8;font-size: 9px;">
                                    <div>
                                        <strong>Notas:</strong>
                                        <div>El costo total, asi como el costo de los productos puede variar segun el
                                            estado
                                            del mercado al momento de compra.</div>
                                        <div>En caso de requerir productos extras fuera de nuestro catalogo, estos no
                                            tienen
                                            reflejado un costo en la presente factura.</div>
                                    </div>
                                </div>
                                <div class="p-col-5"
                                    style="padding: 20px;background: black;text-align: end;color: white;">
                                    <div>
                                        <div style="padding-right: 20px;">TOTAL</div>
                                        <div style="font-size: 25px;">{{orderForm.Price | currency}}</div>
                                        <div>precio sugerido ({{orderForm.SuggestedPrice | currency}})</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </p-tabPanel>

        </p-tabView>
    </div>
    <p-toast position=" top-right">
    </p-toast>
</div>