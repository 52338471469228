<div class="inventoryMainContainer">
    <div class="p-grid" style="justify-content: center;">

        <div class="p-col-12 inventroyTitle">Inventario</div>

        <div class="p-col-12 padding-0">
            <router-outlet></router-outlet>
        </div>

        <p-toast position="top-right"></p-toast>
    </div>
</div>