import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { UserService } from '../../users/user.service';
import { InventoryService } from '../inventory.service';

@Component({
  selector: 'app-acquisitions',
  templateUrl: './acquisitions.component.html',
  styleUrls: ['./acquisitions.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class AcquisitionsComponent implements OnInit {

  constructor(private intentoryService: InventoryService,
    private messageService: MessageService,
    private userService: UserService,) { }

  msg: any[] = [];
  isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  allProducts: any;
  allAcquisitions: any;
  allWarehouses: any;
  allWarehousesFilter: any;
  allCurrencies: any;
  allUsers: any;

  warehouseName: any;
  wharehouseOwner: any = null;
  wharehouseOwnerStatus: boolean = false;
  warehouseToEdit: any;
  warehouseToDelete: any;
  currencyToEdit: any;
  currencyToDelete: any;
  totalRecords:  any;
  isAscendingAcqui = null;
  isAscendingCost = null;

  acquisitionRowSelected:any;

  acquisitionForm = {
    id: null,
    product: { id: null, name: null },
    warehouse: { id: null, name: null },
    currency: { id: null, name: null },
    pieces: null,
    cost: null,
    admission: null,
    soldOut: null,
    acquired: null,
  }

  lazyData = {
    action: "get",
    id: null,
    product: null,
    acquired: null,
    warehouse: null,
    cost: null,
    pageSize: null,
    offsetRecords: null,
    acquisitionsOrder: null,
    costOrder: null
  }

  currencyForm = {
    id: null,
    name: '',
    abbreviation: '',
    value: null
  }

  acquisitionModalStatus: boolean = false;
  acquisitionDetailsModalStatus: boolean = false;
  acquisitionEditStatus: boolean = false;
  warehousesModalStatus: boolean = false;
  warehouseCreateStatus: boolean = false;
  warehouseEditStatus: boolean = false;
  warehouseDeleteStatus: boolean = false;
  currenciesModalStatus: boolean = false;
  currencyEditStatus: boolean = false;
  currencyCreateStatus: boolean = false;
  currencyDeleteStatus: boolean = false;

  ngOnInit(): void {
    this.intentoryService.getAllProducts().subscribe(data => this.allProducts = data);
    this.intentoryService.getWarehouses().subscribe(data => this.allWarehousesFilter = data);
  }

  // ACQUISITIONS

  getAcquisitions(data){

    this.intentoryService.getLazyAcquisitions(data).subscribe((data:any) => {
      this.allAcquisitions = data.acquisitions
      this.totalRecords = data.totalResults
    });
  }

  getLazyAcquisitions(event){
    this.lazyData.offsetRecords = event.first
    this.lazyData.pageSize = event.rows
    this.getAcquisitions(this.lazyData)

  }

  sendActionNotification(data)
  {
    let notification = {
      ID: data.id,
      Acción: data.name ,
      Producto: this.acquisitionForm.product.name,
      Almacén: this.acquisitionForm.warehouse.name,
      Piezas: this.acquisitionForm.acquired,
      Costo: this.acquisitionForm.cost,
      Divisa: this.acquisitionForm.currency.name,
      Fecha: this.acquisitionForm.admission
    }
    this.intentoryService.sendNotification(notification).subscribe();
  }

  onRowSelect(event) {
    this.acquisitionRowSelected = event.data;
  }

  onRowUnselect(event) {
    this.acquisitionRowSelected = null;
  }

  tableFilter(){
    this.getAcquisitions(this.lazyData)
  }

  isAdmin()
  {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    let userModules = currentUser.modules
    userModules.forEach(obj => {
      if (obj == "dashboard")
      {return true}
      else
      {return false}
     });
    return false
  }

  clearId(){
    this.lazyData.id = null;
    this.getAcquisitions(this.lazyData)
  }
  clearProduct(){
    this.lazyData.product = null;
    this.getAcquisitions(this.lazyData)
  }
  clearLocation(){
    this.lazyData.warehouse = null;
    this.getAcquisitions(this.lazyData)
  }
  orderDirectionAcquisitions(){
    [this.isAscendingAcqui,this.lazyData.acquisitionsOrder] = this.validateIsAscending(this.isAscendingAcqui);
    this.getAcquisitions(this.lazyData)
  }
  orderDirectionCosts(){
    [this.isAscendingCost,this.lazyData.costOrder] = this.validateIsAscending(this.isAscendingCost);
    this.getAcquisitions(this.lazyData)
  }

  validateIsAscending(isAscending)
  {
    let data = null;
    if (isAscending === null) {
      isAscending = true;
      data =  "ASC";
    } else if (isAscending === true) {
      isAscending = false;
      data =  "DESC";
    } else {
      isAscending = null;
      data =  null;
    }

    return [isAscending,data]
  }
  openCreateAcquisitions() {
    this.intentoryService.getCurrencies().subscribe(data => this.allCurrencies = data);
    this.intentoryService.getWarehouses().subscribe(data => this.allWarehouses = data);
    this.intentoryService.getAllProducts().subscribe(data => {
      this.allProducts = data;
      this.acquisitionModalStatus = true;
    });
    this.acquisitionForm = {
      id: null,
      product: null,
      warehouse: null,
      currency: null,
      pieces: null,
      cost: null,
      admission: null,
      soldOut: null,
      acquired: null,
    }
  }

  openAcquisitionsDetails(item) {
    let SelectedProduct: any;
    this.intentoryService.getCurrencies().subscribe(data => { this.allCurrencies = data; });
    this.intentoryService.getWarehouses().subscribe(data => { this.allWarehouses = data; });
    this.intentoryService.getAllProducts().subscribe(data => {
      this.allProducts = data;
      SelectedProduct = this.allProducts.findIndex(x => x.id == item.product.id);

      this.acquisitionForm = {
        id: item.id,
        product: this.allProducts[SelectedProduct],
        warehouse: item.warehouse,
        currency: item.currency,
        pieces: item.pieces,
        cost: item.cost,
        admission: item.admission,
        soldOut: item.soldOut,
        acquired: item.acquired
      };
      this.acquisitionEditStatus = true;
      this.acquisitionDetailsModalStatus = true;
    });
  }

  createAcquisitionValidation() {
    this.msg = [];
    const { product, warehouse, currency, cost, admission, acquired } = this.acquisitionForm;

    if (product == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Producto es requerido' });
    } else if (admission == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Admision es requerido' });
    } else if (cost == '' || cost == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Costo es requerido' });
    } else if (currency == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Divisa es requerida' });
    } else if (acquired == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Piezas adquiridas es requerido' });
    } else if (warehouse == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Almacen es requerido' });
    } else {
      this.createAcquisition();
    }
  }

  updateAcquisitionValidation() {
    this.msg = [];
    const { product, warehouse, currency, cost, admission, acquired, pieces } = this.acquisitionForm;

    if (product == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Producto es requerido' });
    } else if (admission == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Admision es requerido' });
    } else if (cost == '' || cost == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Costo es requerido' });
    } else if (currency == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Divisa es requerida' });
    } else if (acquired == null || acquired == 0) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Piezas adquiridas es requerido' });
    } else if (warehouse == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Almacen es requerido' });
    } else if (pieces == null || pieces == 0) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Piezas existentes es requerido' });
    } else if (pieces > acquired) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Piezas existentes debe ser menor o igual a adquiridas' });
    } else {
      this.UpdateAcquisition();
    }
  }

  createAcquisition() {
    let data = {
      action: 'create',
      productId: this.acquisitionForm.product.id,
      warehouseId: this.acquisitionForm.warehouse.id,
      acquired: this.acquisitionForm.acquired,
      cost: this.acquisitionForm.cost,
      currencyId: this.acquisitionForm.currency.id,
      admission: this.acquisitionForm.admission
    }
    this.intentoryService.AcquisitionsActions(data).subscribe(data => {
      this.acquisitionModalStatus = false;
      this.getAcquisitions(this.lazyData)
      let action = {
        id: data,
        name: 'Adquisicion Creada'
      }
      if(!this.isAdmin()){this.sendActionNotification(action)}
    });
  }

  UpdateAcquisition() {
    let data = {
      action: 'update',
      id: this.acquisitionForm.id,
      productId: this.acquisitionForm.product.id,
      warehouseId: this.acquisitionForm.warehouse.id,
      acquired: this.acquisitionForm.acquired,
      cost: this.acquisitionForm.cost,
      currencyId: this.acquisitionForm.currency.id,
      admission: this.acquisitionForm.admission,
      pieces: this.acquisitionForm.pieces
    }
    this.intentoryService.AcquisitionsActions(data).subscribe(data => {
      this.acquisitionDetailsModalStatus = false;
      this.getAcquisitions(this.lazyData)
      let action = {
        id: this.acquisitionForm.id,
        name: 'Adquisicion Actualizada'
      }

      if(!this.isAdmin()) {this.sendActionNotification(action)}
    });
  }

  deleteAcquisition() {
    let data = {
      action: 'delete',
      id: this.acquisitionForm.id,
    }
    this.intentoryService.AcquisitionsActions(data).subscribe(data => {
      this.acquisitionDetailsModalStatus = false;
      this.getAcquisitions(this.lazyData)
      let action = {
        id: this.acquisitionForm.id,
        name: 'Adquisicion Eliminada'
      }

      if(!this.isAdmin()) {this.sendActionNotification(action)}
    });
  }

  // WAREHOUSES

  openWarehousesModal() {
    this.intentoryService.getWarehouses().subscribe(data => {
      this.allWarehouses = data;
       this.intentoryService.getLazyAcquisitions(this.lazyData).subscribe((data:any) => {
          this.allAcquisitions = data.acquisitions
          this.totalRecords = data.totalResults
        this.allWarehouses.forEach(warehouse => {
          let count = 0;
          this.allAcquisitions.forEach(acquisition => {
            if (acquisition.warehouse.id == warehouse.id) { count += 1 }
          });
          Object.assign(warehouse, { "count": count });
        });
        this.userService.getUsersInformation().subscribe(data => this.allUsers = data);
        this.warehousesModalStatus = true;
      });
    });
  }

  openEditWarehouseModal(obj) {
    this.msg = [];
    this.warehouseToEdit = obj.id;
    this.warehouseName = obj.name;
    if (obj.owner != null) {
      let selectedUser
      this.allUsers.forEach(user => { if (obj.owner == user.clientId) { selectedUser = user; } });
      this.wharehouseOwner = selectedUser;
      this.wharehouseOwnerStatus = true;
    } else {
      this.wharehouseOwner = null;
      this.wharehouseOwnerStatus = false;
    }
    this.warehouseEditStatus = true;
  }

  editWarehouseValidation() {
    this.msg = [];

    if (this.warehouseName == "") {
      this.msg.push({ severity: 'error', summary: '', detail: 'Almacen es requerida' });
    } else if (this.wharehouseOwnerStatus) {
      if (this.wharehouseOwner != null) { this.editWarehouse(); }
      else { this.msg.push({ severity: 'error', summary: '', detail: 'Usuario es requerido' }); }
    } else {
      this.editWarehouse();
    }

  }

  editWarehouse() {
    let data = {
      action: "update",
      id: this.warehouseToEdit,
      warehouse: this.warehouseName
    };
    if (this.wharehouseOwnerStatus) { Object.assign(data, { owner: this.wharehouseOwner.clientId }); }
    this.intentoryService.WarehouseActions(data).subscribe(data => {
      if (data != '"0"') {
        this.openWarehousesModal();
        this.warehouseEditStatus = false;
        this.messageService.add({ severity: 'success', summary: '', detail: 'Almacen editado correctamente' });
        this.msg = [];
      } else {
        this.msg = [];
        this.msg.push({ severity: 'error', summary: '', detail: 'Nombre ya existe y/o existe uno con el mismo usuario' });
      }
    });
  }

  openCreateWarehouse() {
    this.msg = [];
    this.warehouseName = "";
    this.wharehouseOwnerStatus = false;
    this.wharehouseOwner = null;
    this.warehouseCreateStatus = true;
  }

  createWarehouseValidation() {
    this.msg = [];

    if (this.warehouseName == "") {
      this.msg.push({ severity: 'error', summary: '', detail: 'Almacen es requerida' });
    } else if (this.wharehouseOwnerStatus) {
      if (this.wharehouseOwner != null) { this.createWarehouse(); }
      else { this.msg.push({ severity: 'error', summary: '', detail: 'Usuario es requerido' }); }
    } else {
      this.createWarehouse();
    }

  }

  createWarehouse() {
    let data = {
      action: 'create',
      warehouse: this.warehouseName,
    };
    if (this.wharehouseOwnerStatus) { Object.assign(data, { owner: this.wharehouseOwner.clientId }); }
    this.intentoryService.WarehouseActions(data).subscribe(data => {
      if (data != '"0"') {
        this.openWarehousesModal()
        this.warehouseCreateStatus = false;
        this.messageService.add({ severity: 'success', summary: '', detail: 'Almacen creado correctamente' });
        this.msg = [];
      } else {
        this.msg = [];
        this.msg.push({ severity: 'error', summary: '', detail: 'Nombre ya existe y/o existe uno con el mismo usuario' });
      }
    });
  }

  openDeleteWarehouseModal(Id) {
    this.warehouseToDelete = Id;
    this.warehouseDeleteStatus = true;
  }

  deleteWarehouse() {
    let data = {
      action: "delete",
      id: this.warehouseToDelete
    };
    this.intentoryService.WarehouseActions(data).subscribe(data => {
      this.openWarehousesModal();
      this.warehouseDeleteStatus = false;
      this.messageService.add({ severity: 'success', summary: '', detail: 'almacen eliminado correctamente' });
      this.msg = [];
    });
  }

  // CURRENCIES

  openCurrenciesModal() {
    this.intentoryService.getCurrencies().subscribe(data => {
      this.allCurrencies = data;
       this.intentoryService.getLazyAcquisitions(this.lazyData).subscribe((data:any) => {
        this.allAcquisitions = data.acquisitions
        this.totalRecords = data.totalResults
        this.allCurrencies.forEach(currency => {
          let count = 0;
          this.allAcquisitions.forEach(acquisition => {
            if (acquisition.currency.id == currency.id) { count += 1 }
          });
          Object.assign(currency, { "count": count });
        });
        this.currenciesModalStatus = true;
      });
    });
  }

  openCreateCurrency() {
    this.currencyForm = {
      id: null,
      name: '',
      abbreviation: '',
      value: null
    };
    this.currencyCreateStatus = true;
  }

  createCurrencyValidation() {
    this.msg = [];
    const { name, value, abbreviation } = this.currencyForm;

    if (name == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'nombre es requerido' });
    } else if (abbreviation == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'abreviación es requerido' });
    } else if (value == null && value > 0) {
      this.msg.push({ severity: 'error', summary: '', detail: 'valor es requerida' });
    } else {
      this.createCurrency();
    }
  }

  createCurrency() {
    let data = {
      action: 'create',
      name: this.currencyForm.name,
      abbreviation: this.currencyForm.abbreviation,
      value: this.currencyForm.value
    }
    this.intentoryService.CurrenciesActions(data).subscribe(data => {
      if (data != '"0"') {
        this.openCurrenciesModal();
        this.currencyCreateStatus = false;
        this.messageService.add({ severity: 'success', summary: '', detail: 'Divisa creada correctamente' });
        this.msg = [];
      } else {
        this.msg = [];
        this.msg.push({ severity: 'error', summary: '', detail: 'Divisa o abreviación ya existe' });
      }
    });
  }

  openEditCurrencyModal(obj) {
    this.msg = [];
    this.currencyToEdit = obj
    this.currencyForm = this.currencyToEdit;
    this.currencyEditStatus = true;
  }

  editCurrencyValidation() {
    this.msg = [];
    const { name, abbreviation, value } = this.currencyForm;
    if (name == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'nombre es requerido' });
    } else if (abbreviation == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'abreviación es requerida' });
    } else if (value == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'valor es requerido' });
    } else {
      this.editCurrency();
    }
  }

  editCurrency() {
    let data = {
      action: "update",
      id: this.currencyForm.id,
      name: this.currencyForm.name,
      abbreviation: this.currencyForm.abbreviation,
      value: this.currencyForm.value
    };
    this.intentoryService.CurrenciesActions(data).subscribe(data => {
      if (data != '"0"') {
        this.openCurrenciesModal();
        this.currencyEditStatus = false;
        this.messageService.add({ severity: 'success', summary: '', detail: 'Divisa editada correctamente' });
        this.msg = [];
      } else {
        this.msg = [];
        this.msg.push({ severity: 'error', summary: '', detail: 'Divisa o abreviación ya existe' });
      }
    });
  }

  openDeleteCurrencyModal(Id) {
    this.currencyToDelete = Id;
    this.currencyDeleteStatus = true;
  }

  deleteCurrency() {
    let data = {
      action: "delete",
      id: this.currencyToDelete
    };
    this.intentoryService.CurrenciesActions(data).subscribe(data => {
      this.openCurrenciesModal();
      this.currencyDeleteStatus = false;
      this.messageService.add({ severity: 'success', summary: '', detail: 'Divisa eliminada correctamente' });
      this.msg = [];
    });
  }


}
