import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesService } from '../sales.service';


@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OrderListComponent implements OnInit {

  allOrders: any;
  allWarehouses: any;
  allProducts: any;
  allClients: any;

  orderRowSelected: any;

  productsOrigin: any = [];

  msg: any;

  productsOriginModalStatus: boolean = false;
  orderDetailsModalStatus: boolean = false;
  isURL: boolean = false;
  totalRecords: any;
  isSelectedInvoice: boolean = false;
  clientFilter: any;

  orderForm = {
    Id: null,
    Client: '',
    ClientId: null,
    Invoice: false,
    Location: '',
    Date: null,
    Status: null,
    OrderStatus: null,
    Icon: null,
    Color: null,
    Price: 0,
    Salesman: '',
    SuggestedPrice: 0,
    Extras: '',
    Phone: null,
    Delivery: null,
    Products: [],
    Origin: null
  }

  lazyData = {
    action: "getOrders",
    invoice: false,
    client: null,
    pageSize: null,
    offsetRecords: null
  }

  constructor(
    private salesService: SalesService,
    private router: Router,
    private activeroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.salesService.getClients().subscribe(data => this.allClients = data);
    this.activeroute.queryParams.subscribe(params => {
      if (params['ID']) {
        let obj = { id: parseInt(params['ID'].replace('"', '')) };
        this.openOrderDetails(obj);
      }
    });
  }

  onRowSelect(event) {
    this.orderRowSelected = event.data;
  }

  //Start Filters and Lazy data
  getOrders(data)
  {
    this.salesService.getLazyOrders(data).subscribe((data:any) => {
      this.allOrders = data.orders
      this.totalRecords = data.totalResults
    });
  }

  getLazyOrders(event){
    this.lazyData.offsetRecords = event.first
    this.lazyData.pageSize = event.rows
    this.getOrders(this.lazyData)
  }

  tableFilter(){
    this.getOrders(this.lazyData)
  }
  clearClient()
  {
    this.lazyData.client =  null;
    this.getOrders(this.lazyData)
  }

  invoiceFilter(){
    this.isSelectedInvoice = !this.isSelectedInvoice
    this.lazyData.invoice = !this.lazyData.invoice
    this.getOrders(this.lazyData)
  }

  onRowUnselect(event) {
    this.orderRowSelected = null;
  }

  openOrderDetails(item) {
    this.salesService.getOrderDetails(item.id).subscribe(data => {
      let order: any = data;
      this.orderForm = {
        Id: order.id,
        Client: order.client,
        ClientId: order.clientId,
        Invoice: order.invoice,
        Location: order.location,
        Date: order.orderDate,
        Status: order.status,
        OrderStatus: order.orderStatus,
        Icon: order.icon,
        Color: order.color,
        Price: order.price,
        Salesman: order.salesman.name,
        SuggestedPrice: order.suggestedPrice,
        Extras: order.extras,
        Delivery: order.delivery,
        Products: order.products,
        Phone: order.clientPhone,
        Origin: order.productOrigin

      }
      try { let url = new URL(order.location); this.isURL = true; } catch (_) { this.isURL = false; }

      this.orderDetailsModalStatus = true;
    });
  }


  confirmDelivery() {
    this.salesService.confirmOrderDelivery(this.orderForm.Id).subscribe(res => {
      this.salesService.getLazyOrders(this.lazyData).subscribe((data:any) => {
        if(!this.isAdmin()) {this.sendActionNotification({name: 'Orden Confirmada'})}
        this.allOrders = data.orders
        this.totalRecords = data.totalResults
        this.orderDetailsModalStatus = false;
      });
    })
  }
  sendActionNotification(data)
  {
    let notification = {
      ID: this.orderForm.Id,
      Acción: data.name ,
      Vendedor: this.orderForm.Salesman,
      Fecha: this.orderForm.Date,
      Cliente: this.orderForm.Client
    }

    this.salesService.sendNotification(notification).subscribe();
  }
  isAdmin()
  {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    let userModules = currentUser.modules
    userModules.forEach(obj => {
      if (obj == "dashboard")
      {return true}
      else
      {return false}
     });
    return false
  }

  getDeliveryTime() {
    var time = new Date(this.orderForm.Delivery).getTime() - new Date(this.orderForm.Date).getTime();

    // transform milliseconds to Minutes
    time = time / 60000;
    let units = " Min";
    var res = time.toFixed(2) + units;
    // transform Minutes to Hours and limited to 2 decimals
    if (time >= 60 && units == " Min") {
      time = time / 60;
      units = " Hrs";
      res = time.toFixed(2) + units;
    }
    // transform Hours to Days and limited to 2 decimals
    if (time >= 24 && units == " Hrs") {
      time = time / 24;
      units = " Dias";
      res = time.toFixed(2) + units;
    }
    return res;
  }

  CancelOrder() {
    let data = {
      "action": "cancel",
      "id": this.orderForm.Id
    }
    this.salesService.OrderActions(data).subscribe(res => {
      this.salesService.getLazyOrders(this.lazyData).subscribe((data:any) => {
        if(!this.isAdmin()) {this.sendActionNotification({name: 'Orden Cancelada'})}
        this.allOrders = data.orders
        this.totalRecords = data.totalResults
        this.orderDetailsModalStatus = false;
      });
    })
  }

  editOrder() {
    this.router.navigate(['/system/sales/editOrder/' + this.orderForm.Id]);
  }

}
