import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { HomepageService } from '../homepage.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class ContactComponent implements OnInit {

  menuDisplay: boolean = false;
  logoCompleto: string = '/assets/Images/fullLogo.svg';
  msg: any[] = [];
  form = { Name: '', Phone: null, Message: '' };

  constructor(private HomeService: HomepageService, private router: Router, private messageService: MessageService) { }

  ngOnInit(): void {
  }

  quoteValidation() {
    this.msg = [];
    const { Name, Phone, Message } = this.form;

    let PhoneLenght: number = 0;
    if (Phone) { let x: string = Phone.toString(); PhoneLenght = x.length; }

    if (Name == '') {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Nombre es requerido' });
    } else if (Phone == null || PhoneLenght < 10) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Telefono debe contar con 10 numeros' });
    } else if (Message == '') {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Descripcion es necesaria' });
    } else {
      this.sendMail();
    }
  }

  goHome() {
    this.router.navigate(['']);
  }

  sendMail() {
    this.HomeService.sendQuote(this.form).subscribe(res => {
      this.form = { Name: '', Phone: null, Message: '' };
      this.msg = [];
      this.messageService.add({ severity: 'success', summary: '', detail: 'Cotizacion enviada' });
    }, (error) => {
      this.msg = [];
      this.messageService.add({ severity: 'error', summary: '', detail: 'Oops ocurrio un error!!' });
    });
  }
}
