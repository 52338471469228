<div class="catalogContainer p-grid">

    <div class="scrollTopButton" *ngIf="scrollTopStatus" (click)="scrollToTop()">
        <i class="pi pi-chevron-up" style="font-size: 1.5rem"></i>
    </div>

    <div class="p-col-12 padding-0">
        <app-header-public></app-header-public>
    </div>
    
    <div class="p-col-12 section1">
        <div class="p-d-flex p-ai-center">
            <div class="p-col">
                <i class="pi pi-bars pointer" style="font-size: 17px;margin-left: 20%;" (click)="menuDisplay=true"></i>
            </div>
            <img class="logo pointer" (click)="goHome()" src="{{logoCompleto}}" alt="logo Barman">
            <div class="p-col"></div>
        </div>
    </div>

    <div class="p-col-12" style="font-size: 40px;color: #caa462;">Catalogo</div>

    <div class="p-col-12 producsContainer">
        <div class="p-grid">

            <div class="p-col-12 p-md-3 filters">
                <div class="p-grid">
                    <div class="p-col-12"> Filtrar productos </div>

                    <div class="p-col-12">Categoria : </div>
                    <p-dropdown class="p-col-12" [options]="categories" optionLabel="category"
                        [(ngModel)]="categoryFilter" placeholder="Todas" [showClear]="true"></p-dropdown>
                    <div class="p-col-12">Nombre : </div>
                    <span class="p-input-icon-left searchInput">
                        <i class="pi pi-search"></i>
                        <input type="search" pInputText style="width: 100%;" [(ngModel)]="textFilter"
                            placeholder="Buscar">
                    </span>
                </div>
            </div>

            <div class="p-col-12 p-md-9">

                <div *ngIf="allProducts==null" style="text-align: center;color: #caa462;">
                    <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i>
                    <div>Cargando lista de productos...</div>
                </div>

                <div class="p-grid" *ngIf="allProducts != null">

                    <p-dataView #dv [value]="allProducts | productFilter : categoryFilter : null : textFilter"
                    [paginator]="true" [rows]="16"  layout="list">
                        <ng-template pTemplate="header">
                            <div class="p-d-flex p-jc-end p-ai-center">
                                <div style="padding-right: 10px;">Cambiar modo de vista :</div>
                                <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
                            </div>
                        </ng-template>

                        <ng-template let-product pTemplate="listItem">
                            <div class="p-col-12 padding-0">
                                <div style="display: flex;align-items: center;width: 100%;">
                                    <div class="p-col-12">
                                        <div class="p-grid p-ai-center vertical-container">
                                            <div class="p-col-4 p-md-3 p-lg-2" (click)="showProduct(product)">
                                                <div class="productPhotoContainer" style="background-color: #282828;">
                                                    <div class="productPhotoDiv {{ProductPhoto(product)}}">
                                                        <img style="height: 100%;"
                                                            src="{{product.photo ? product.photo : defaultImage }}">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-8 p-md-9 p-lg-10">
                                                <div class="p-grid">
                                                    <div class="p-col-12 p-md-4"
                                                        style="font-size:20px;font-weight:700;">
                                                        {{product.name}}</div>
                                                    <div class="p-col-12  p-md-4">
                                                        <i class="pi pi-tag"></i>
                                                        <span style="margin-left: 5px;">
                                                            {{setCategoryName(product.category)}}
                                                        </span>
                                                    </div>
                                                    <div class="p-col-12 p-md-4">Cont.Net. : {{product.netcontent}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <ng-template let-product pTemplate="gridItem">
                            <div class="p-col-6  p-md-4 p-xl-3">
                                <div class="p-d-flex p-flex-column"
                                    style="border: grey solid 1px;margin: 8px;align-items: center;border-radius: 8px;">
                                    <div class="p-grid p-jc-start" style="width: 100%;padding: 8px;">
                                        <div>
                                            <i class="pi pi-tag product-category-icon"></i>
                                            <span class="product-category"
                                                style="margin-left: 5px;">{{setCategoryName(product.category)}}</span>
                                        </div>
                                    </div>
                                    <div (click)="showProduct(product)" style="width: 80%;">
                                        <div class="productPhotoContainer pointer" style="background-color: #282828;">
                                            <div class="productPhotoDiv {{ProductPhoto(product)}}">
                                                <img style="height: 100%;"
                                                    src="{{product.photo ? product.photo : defaultImage }}">
                                            </div>
                                        </div>
                                    </div>
                                    <div style="font-size: 20px;font-weight: 700;padding: 10px;">{{product.name}}</div>
                                    <div>{{product.netcontent}} </div>
                                    <div style="font-size: 1rem;font-weight: 700;padding:5px;">{{product.price |
                                        currency}}
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-dataView>
                </div>
            </div>

        </div>
    </div>

    <p-sidebar [(visible)]="menuDisplay">
        <div class="menuList">
            <a [routerLink]="'/'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                #Home="routerLinkActive">
                <div [ngClass]=" Home.isActive ? 'menuitemActive': 'menuitem'">Inicio</div>
            </a>
            <a [routerLink]="'/Catalogo'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                #Catalog="routerLinkActive">
                <div [ngClass]=" Catalog.isActive ? 'menuitemActive': 'menuitem'">Catalogo</div>
            </a>
            <a [routerLink]="'/Contacto'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                #Contact="routerLinkActive">
                <div [ngClass]=" Contact.isActive ? 'menuitemActive': 'menuitem'">Cotizaciones</div>
            </a>
        </div>
    </p-sidebar>

    <p-dialog header="{{selectedProduct.name}}" [(visible)]="selectedProductStatus" [modal]="true" [baseZIndex]="10001"
        [draggable]="false" [resizable]="false">
        <div class="productPhotoContainer">
            <div class="productPhotoDiv">
                <img style="height: 100%; ;" src="{{selectedProduct.photo ? selectedProduct.photo : defaultImage }}">
            </div>
        </div>
    </p-dialog>
</div>