import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { InventoryService } from '../inventory.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CompressImageService } from './compress-image.service';
import { FileUpload } from 'primeng/fileupload';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class ProductsComponent implements OnInit {

  constructor(
    private intentoryService: InventoryService,
    private messageService: MessageService,
    private compressImage: CompressImageService,
    private DomSanitizer: DomSanitizer) { }


  isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  msg: any[] = [];

  allProducts: any;
  allCategories: any;
  categoryFilter: any;
  appliedFilter: any;
  textFilter: any;
  file: any;
  fileName: any;

  productToEdit: any;
  productToDelete: any;
  categoryName: any;
  categoryToEdit: any;
  categoryToDelete: any;

  productRowSelected: any;

  otherFilters: any = [
    { id: 1, label: 'Mayor a Menor precio' },
    { id: 2, label: 'Menor a Mayor precio' },
    { id: 3, label: 'En Existencia' },
    { id: 4, label: 'Agotado' }
  ];

  productForm = {
    Name: '',
    Price: null,
    PriceNight: null,
    Category: null,
    NetContent: '',
    Photo: null
  }


  productModalStatus: boolean = false;
  productDeleteModalStatus: boolean = false;
  productEditModalStatus: boolean = false;
  categoriesModalStatus: boolean = false;
  categoryCreateStatus: boolean = false;
  categoryEditStatus: boolean = false;
  categoryDeleteStatus: boolean = false;

  defaultImage: string = 'assets/Images/system/default.png'

  ngOnInit(): void {
    this.intentoryService.getCategories().subscribe(data => this.allCategories = data);
    this.intentoryService.getAllProducts().subscribe(data => this.allProducts = data);
  }

  getBase64(event: any) {
    let image: File = event
    this.compressImage.compress(image)
      .pipe(take(1))
      .subscribe(compressedImage => {
        let me = this;
        let file = compressedImage;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          me.productForm.Photo = (reader.result);
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      });
  }
  onUpload(event: any) {
    for (let file of event.files) { this.getBase64(file); }
  }
  SanitizerURL(url: any) {
    return this.DomSanitizer.bypassSecurityTrustResourceUrl(url);
  }
  clearImages() {
    this.productForm.Photo = null;
  }

  sendActionNotification(data)
  {
    let notification = {
      ID: data.id,
      Acción: data.name ,
      Producto: this.productForm.Name,
      Categoria: this.productForm.Category.category,
      Precio: this.productForm.Price,
      "Precio Nocturno": this.productForm.PriceNight,
      "Contenido Neto": this.productForm.NetContent
    }
    this.intentoryService.sendNotification(notification).subscribe();
  }

  // PRODUCTS

  setCategoryName(id) {
    let text = "";
    if (this.allCategories != null) {
      this.allCategories.forEach(item => {
        if (id == item.id) { text = item.category }
      });
    }
    return text;
  }

  setAvailabilityStatus(totalpieces) {
    let status = 'pi-times-circle';
    if (totalpieces > 0) { status = 'pi-check-circle'; }
    return status;
  }

  onRowSelect(event) {
    this.productRowSelected = event.data;
  }

  onRowUnselect(event) {
    this.productRowSelected = null;
  }

  onFileSelected(e, form) {
    for (var i = 0; i < e.files.length; i++) {
      let file = Object.assign(e.files[i]);
      this.productForm.Photo = file;
      this.fileName = file.name;
    }
    e.originalEvent.target.value = null;
    form.clear();
    let me = this;
    let reader = new FileReader();
    reader.readAsDataURL(this.productForm.Photo);
    reader.onload = function () {
      me.productForm.Photo = reader.result;
    };
  }

  createProduct() {
    let data = {
      action: "create",
      name: this.productForm.Name,
      price: this.productForm.Price,
      priceNight: this.productForm.PriceNight,
      category: this.productForm.Category.id,
      netcontent: this.productForm.NetContent
    };
    if (this.productForm.Photo) { Object.assign(data, { photo: this.productForm.Photo }); }
    this.intentoryService.ProductActions(data).subscribe(data => {
      this.messageService.add({ severity: 'success', summary: '', detail: 'Producto agregado correctamente' });
      this.intentoryService.getAllProducts().subscribe(data => this.allProducts = data);
      this.productModalStatus = false;
      let action = {
        id: data,
        name: 'Producto Creado'
      }

      if(!this.isAdmin()) {this.sendActionNotification(action)}

    });
  }

  deleteProduct() {
    let data = {
      action: "delete",
      id: this.productToDelete,
    };
    this.intentoryService.ProductActions(data).subscribe(data => {
      this.messageService.add({ severity: 'success', summary: '', detail: 'Producto elimindado correctamente' });
      this.intentoryService.getAllProducts().subscribe(data => this.allProducts = data);
      this.productDeleteModalStatus = false;
      let action = {
        id: this.productToDelete,
        name: 'Producto Eliminado'
      }
      if(!this.isAdmin()) {this.sendActionNotification(action)}

    });
  }

  editProduct() {
    let data = {
      action: "update",
      id: this.productToEdit,
      name: this.productForm.Name,
      price: this.productForm.Price,
      priceNight: this.productForm.PriceNight,
      category: this.productForm.Category.id,
      netcontent: this.productForm.NetContent
    };
    if (this.productForm.Photo) { Object.assign(data, { photo: this.productForm.Photo }); }
    this.intentoryService.ProductActions(data).subscribe(data => {
      this.messageService.add({ severity: 'success', summary: '', detail: 'Producto actualizado correctamente' });
      this.productEditModalStatus = false;
      this.intentoryService.getAllProducts().subscribe(data => this.allProducts = data);
      let action = {
        id: this.productToEdit,
        name: 'Producto Actualizado'
      }

      if(!this.isAdmin()) {this.sendActionNotification(action)}

    });
  }

  isAdmin()
  {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    let userModules = currentUser.modules
    userModules.forEach(obj => {
      if (obj == "dashboard")
      {return true}
      else
      {return false}
     });
    return false
  }

  editProductValidation() {
    this.msg = [];
    const { Name, Category, NetContent, Price, PriceNight } = this.productForm;
    if (Name == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'Nombre es requerido' });
    } else if (Price == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Precio de día es requerido' });
    } else if (PriceNight == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Precio de noche es requerido' });
    } else if (Category == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Seleccione una categoria' });
    } else if (NetContent == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'Contenido es requerido' });
    } else {
      this.editProduct();
    }
  }

  createProductValidation() {
    this.msg = [];
    const { Name, Category, NetContent, Price, PriceNight } = this.productForm;
    if (Name == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'Nombre es requerido' });
    } else if (Price == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Precio de día es requerido' });
    } else if (PriceNight == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Precio de noche es requerido' });
    } else if (Category == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Seleccione una categoria' });
    } else if (NetContent == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'Contenido es requerido' });
    } else {
      this.createProduct();
    }
  }

  openProductModal() {
    this.msg = [];
    this.productForm = {
      Name: '',
      Price: null,
      PriceNight: null,
      Category: null,
      NetContent: '',
      Photo: null
    };
    this.productModalStatus = true;
  }

  openDeleteProductModal(product) {
    let selectedCategory
    this.allCategories.forEach(item => {
      if (item.id == product.category) { selectedCategory = item; }
    });
    this.productToDelete = product.id
    this.productForm = {
      Name: product.name,
      Price: product.price,
      PriceNight: product.priceNight,
      Category: selectedCategory,
      NetContent: product.netcontent,
      Photo: null
    };
    this.productDeleteModalStatus = true;
  }

  openEditProductModal(id) {
    this.msg = [];
    this.intentoryService.getCategories().subscribe(data => {
      this.allCategories = data;
      this.intentoryService.getProductDetails(id).subscribe(res => {
        let productInfo: any = res;

        let selectedCategory
        this.allCategories.forEach(item => {
          if (item.id == productInfo.category) { selectedCategory = item; }
        });

        if (productInfo.price == 0) { productInfo.price = null; }

        this.productToEdit = productInfo.id;

        this.productForm = {
          Name: productInfo.name,
          Price: productInfo.price,
          PriceNight: productInfo.priceNight,
          Category: selectedCategory,
          NetContent: productInfo.netcontent,
          Photo: productInfo.photo
        };
        let productName: string = productInfo.name;
        this.fileName = productName.replace(/\s/g, "") + ".jpg";
        this.productEditModalStatus = true;
      })
    });
  }

  // CATEGORIES

  openCategoriesModal() {
    this.intentoryService.getCategories().subscribe(data => {
      this.allCategories = data;
      this.intentoryService.getAllProducts().subscribe(data => {
        this.allProducts = data;
        this.allCategories.forEach(category => {
          let count = 0;
          this.allProducts.forEach(product => {
            if (product.category == category.id) { count += 1 }
          });
          Object.assign(category, { "count": count });
        });
        this.categoriesModalStatus = true;
      });
    });
  }

  openCreateCategory() {
    this.categoryName = null;
    this.categoryCreateStatus = true;
  }

  createCategory() {
    if (this.categoryName != null) {
      let data = {
        action: "create",
        category: this.categoryName
      };

      this.intentoryService.CategoryActions(data).subscribe(data => {
        if (data != '"0"') {
          this.openCategoriesModal();
          this.categoryCreateStatus = false;
          this.messageService.add({ severity: 'success', summary: '', detail: 'Categoria agregada correctamente' });
          this.msg = [];
        } else {
          this.msg = [];
          this.msg.push({ severity: 'error', summary: '', detail: 'Categoria ya existe' });
        }
      });
    } else {
      this.msg = [];
      this.msg.push({ severity: 'error', summary: '', detail: 'Categoria es requerida' });
    }
  }

  openDeleteCategoryModal(Id) {
    this.categoryToDelete = Id;
    this.categoryDeleteStatus = true;
  }

  deleteCategory() {
    let data = {
      action: "delete",
      id: this.categoryToDelete
    };
    this.intentoryService.CategoryActions(data).subscribe(data => {
      this.openCategoriesModal();
      this.categoryDeleteStatus = false;
      this.messageService.add({ severity: 'success', summary: '', detail: 'Categoria eliminada correctamente' });
      this.msg = [];
    });
  }

  openEditCategoryModal(obj) {
    this.msg = [];
    this.categoryToEdit = obj.id;
    this.categoryName = obj.category;
    this.categoryEditStatus = true;
  }

  editCategory() {
    if (this.categoryName != "") {
      let data = {
        action: "update",
        id: this.categoryToEdit,
        category: this.categoryName
      };

      this.intentoryService.CategoryActions(data).subscribe(data => {
        if (data != '"0"') {
          this.openCategoriesModal();
          this.categoryEditStatus = false;
          this.messageService.add({ severity: 'success', summary: '', detail: 'Categoria editada correctamente' });
          this.msg = [];
        } else {
          this.msg = [];
          this.msg.push({ severity: 'error', summary: '', detail: 'Categoria ya existe' });
        }
      });
    } else {
      this.msg = [];
      this.msg.push({ severity: 'error', summary: '', detail: 'Categoria es requerida' });
    }
  }

}
