import { Injectable } from '@angular/core';
import { environment } from '../../../src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  baseUrl = environment.baseUrl;

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  getUserId() {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    return this.currentUser;
  }

  login(data) {
    return this.http.post(`${this.baseUrl}UserAccess`, data, { responseType: 'json' })
      .pipe(map(res => {
        if (res.toString() != "") {
          localStorage.setItem('currentUser', JSON.stringify(res));
          this.currentUserSubject.next(data);
        }
        return res;
      }));
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

}
