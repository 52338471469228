import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { MenuService } from '../menu/menu.service';
import { UserService } from './user.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class UsersComponent implements OnInit {

  constructor(private userService: UserService, private menuService: MenuService, private loginservice: LoginService, private messageService: MessageService) { }

  isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  roles: any;
  modules: any;
  userList: any;
  userId: any;
  UserModalStatus: boolean = false;
  UserEditStatus: boolean = false;
  UserDeleteStatus: boolean = false;
  RolesModalStatus: boolean = false;
  RoleDeleteStatus: boolean = false;
  RoleCreateStatus: boolean = false;
  RoleEditStatus: boolean = false;
  RoleEditedName: any;
  changePassword: boolean = false;
  userToDelete: any;
  roleToEdit: any;
  roleToDelete: any;
  roleName: any;
  selectedClientid: any;
  msg: any[] = [];
  msgRoles: any[] = [];

  createForm = {
    Name: '',
    LastName: '',
    Username: '',
    Phone: null,
    Password: '',
    PasswordRepeat: '',
    Role: { id: null, role: null },
    Modules: []
  }

  editForm = {
    Name: '',
    LastName: '',
    Username: '',
    Phone: null,
    Password: '',
    PasswordRepeat: '',
    Role: { id: null, role: null },
    Modules: [],
    ClientId: ''
  }

  userRowSelected: any;

  ngOnInit(): void {
    this.userService.getUsersInformation().subscribe(data => { this.userList = data; });
    this.loginservice.getUserId().subscribe(res => { this.userId = res.clientid; })
  }

  onRowSelect(event) {
    this.userRowSelected = event.data;
  }

  onRowUnselect(event) {
    this.userRowSelected = null;
  }

  rolesModal() {
    this.userService.getRoles().subscribe(data => {
      this.roles = data;
      this.userService.getUsersInformation().subscribe(data => {
        this.userList = data;
        this.roles.forEach(role => {
          let count = 0;
          this.userList.forEach(user => {
            if (user.role == role.role) { count += 1 }
          });
          Object.assign(role, { "count": count });
        });
        this.RolesModalStatus = true;
      });
    });
  }

  createRole() {
    let data = {
      action: 'create',
      role: this.roleName
    };
    this.userService.createRole(data).subscribe(data => {
      if (data != '"0"') {
        this.rolesModal();
        this.roleName = '';
        this.RoleCreateStatus = false;
      } else {
        this.msg = [];
        this.msg.push({ severity: 'error', summary: '', detail: 'Ya existe ese cargo' });
      }
    });
  }

  deleteRoleModal(role) {
    this.roleToDelete = role;
    this.RoleDeleteStatus = true;
  }

  deleteRole() {
    let data = {
      action: 'delete',
      id: this.roleToDelete.id
    }
    this.userService.deleteRole(data).subscribe(data => {
      this.rolesModal();
      this.RoleDeleteStatus = false;
    });
  }

  createUserValidation() {
    this.msg = [];
    const { Name, LastName, Phone, Username, Password, PasswordRepeat, Role, Modules } = this.createForm;
    if (Name == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'Nombre es requerido' });
    } else if (LastName == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'Apellidos es requerido' });
    } else if (Phone < 999999999) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Telefono debe contar con 10 numeros' });
    } else if (Username == '' || Username.length < 6) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Nombre de usuario es requerido y debe contar con al menos 6 caracteres' });
    } else if (Password == '' || Password.length < 6) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Clave es requerida y debe contar con al menos 6 caracteres' });
    } else if (Password != PasswordRepeat) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Las claves no coinciden' });
    } else if (Role == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Seleccione un cargo para el usuario' });
    } else if (Modules == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Seleccione al menos un modulo para el usuario' });
    } else {
      this.createUser();
    }
  }

  createUser() {
    let generatedId = this.generateClientId();
    let data = {
      action: "create",
      clientid: generatedId,
      userName: this.createForm.Username,
      password: this.createForm.Password,
      name: this.createForm.Name,
      lastname: this.createForm.LastName,
      phone: this.createForm.Phone,
      role: this.createForm.Role.id,
      modules: this.createForm.Modules
    }
    this.userService.createUser(data).subscribe(res => {
      if (res.length == 3) {
        this.msg = [];
        this.msg.push({ severity: 'error', summary: '', detail: 'Ya existe ese nombre de usuario' });
      } else {
        this.UserModalStatus = false;
        this.messageService.add({ severity: 'success', summary: '', detail: 'Usuario agregado correctamente' });
        this.userService.getUsersInformation().subscribe(data => { this.userList = data; });
      }
    });
  }

  generateClientId() {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    for (var j = 0; j < 4; j++) {
      for (var i = 0; i < 5; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      if (result.length < 23) {
        result += "-"
      }
    }
    return result;
  }

  createUserModal() {
    this.msg = [];
    this.userService.getRoles().subscribe(data => this.roles = data);
    this.userService.getModules().subscribe(data => this.modules = data);
    this.createForm.Name = "";
    this.createForm.LastName = "";
    this.createForm.Phone = null;
    this.createForm.Username = "";
    this.createForm.Password = "";
    this.createForm.PasswordRepeat = "";
    this.createForm.Role = null;
    this.createForm.Modules = null;
    this.selectedClientid = "";
    this.UserModalStatus = true;
  }

  deleteUserModal(user) {
    this.UserDeleteStatus = true;
    this.userToDelete = user;
  }

  deleteUser() {
    let data = {
      action: "delete",
      username: this.userToDelete
    }
    this.userService.deleteUser(data).subscribe(res => {
      this.userService.getUsersInformation().subscribe(data => { this.userList = data; });
      this.UserDeleteStatus = false;
    })
  }

  editUser() {
    let data = {
      action: "update",
      name: this.editForm.Name,
      lastname: this.editForm.LastName,
      clientid: this.editForm.ClientId,
      phone: this.editForm.Phone,
      role: this.editForm.Role.id,
      modules: this.editForm.Modules
    };
    if (this.changePassword) { Object.assign(data, { "password": this.editForm.Password }); }
    this.userService.editUser(data).subscribe(res => {
      this.UserEditStatus = false;
      this.messageService.add({ severity: 'success', summary: '', detail: 'Usuario editado correctamente' });
      this.userService.getUsersInformation().subscribe(data => { this.userList = data; });
    });
  }

  editUserModal(user) {
    this.msg = [];
    // Set User role on Roles Dropdown
    this.userService.getRoles().subscribe(data => {
      this.roles = data;
      this.roles.forEach(item => {
        if (item.role == user.role) { this.editForm.Role = item }
      });
    });
    // Set User modules on Modules MultiSelect
    this.userService.getModules().subscribe(data => {
      this.modules = data;
      this.menuService.getUserModulesById(user.clientId).subscribe(res => {
        let data: any = res;
        let userModules = [];
        data.forEach(usermodule => {
          this.modules.forEach(item => {
            if (item.id == usermodule.id) { userModules.push(item) }
          });
        });
        this.editForm.Modules = userModules;
      });
    });
    // Set other Values
    this.editForm.Username = user.username;
    this.editForm.Name = user.name;
    this.editForm.LastName = user.lastname;
    this.editForm.Phone = user.phone;
    this.editForm.ClientId = user.clientId;
    this.editForm.Password = "";
    this.editForm.PasswordRepeat = "";
    this.changePassword = false;
    // Show modal
    this.UserEditStatus = true;
  }

  editUserValidation() {
    this.msg = [];
    const { Name, LastName, Phone, Username, Password, PasswordRepeat, Role, Modules } = this.editForm;
    if (Name == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'Nombre es requerido' });
    } else if (LastName == '') {
      this.msg.push({ severity: 'error', summary: '', detail: 'Apellidos es requerido' });
    } else if (Phone < 999999999) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Telefono debe contar con 10 numeros' });
    } else if (Username == '' || Username.length < 6) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Nombre de usuario es requerido y debe contar con al menos 6 caracteres' });
    } else if (this.changePassword == true && Password == '' || this.changePassword == true && Password.length < 6) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Clave es requerida y debe contar con al menos 6 caracteres' });
    } else if (this.changePassword == true && Password != PasswordRepeat) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Las claves no coinciden' });
    } else if (Role == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Seleccione un cargo para el usuario' });
    } else if (Modules == null) {
      this.msg.push({ severity: 'error', summary: '', detail: 'Seleccione al menos un modulo para el usuario' });
    } else {
      this.editUser();
    }
  }

  openRoleEditModal(role) {
    this.roleToEdit = role;
    this.RoleEditedName = role.role;
    this.RoleEditStatus = true;
  }

  editRole() {
    let data = {
      action: "update",
      id: this.roleToEdit.id,
      role: this.RoleEditedName
    }
    this.userService.editRole(data).subscribe(data => {
      if (data != '"0"') {
        this.rolesModal();
        this.RoleEditedName = '';
        this.RoleEditStatus = false;
      } else {
        this.msg = [];
        this.msg.push({ severity: 'error', summary: '', detail: 'Ya existe ese cargo' });
      }
    })
  }

}
