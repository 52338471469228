import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.baseUrl;

  getUserModulesById(id) {
    let data = { clientId: id };
    return this.http.post(`${this.baseUrl}MenuModules`, data, { responseType: 'json' })
  }
}
