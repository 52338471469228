<div class="clientsContainer">
    <div class="phoneHide">
        <div class="p-d-flex p-jc-between">
            <span>Clientes registrados</span>
            <div>
                <button pButton type="button" class="p-button-help" label="Agregar cliente" icon="pi pi-plus "
                    (click)="openAddClientModal()"></button>
            </div>
        </div>
    </div>

    <p-table [value]="allClients" [paginator]="true" [paginatorPosition]="isMobileDevice ? 'top' : 'bottom'"
    [rows]="9" [scrollable]="true" selectionMode="single" [(selection)]="clientRowSelected"
        dataKey="id" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)">
        <ng-template pTemplate="header">
            <tr>
                <th>Id</th>
                <th>Nombre</th>
                <th>Telefono</th>
                <th class="phoneHide">Detalles</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-client>
            <tr [pSelectableRow]="client">
                <td>{{client.id}}</td>
                <td>{{client.client}}</td>
                <td>{{client.phone}}</td>
                <td class="phoneHide">
                    <button pButton pRipple type="button" icon="pi pi-book" class="p-button-rounded"
                        (click)="openClientDetailsModal(client)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <div class="phoneShow"
        style="height: 50px;padding: 8px;position: fixed;bottom: 0px;width: 100vw;background: #0d111c;">
        <div class="p-d-flex p-ai-center" style="height: 100%;">
            <button pButton type="button" class="p-button-help" label="Agregar cliente" icon="pi pi-plus "
                (click)="openAddClientModal()"></button>
            <button pButton type="button" class="p-button" label="Detalles de cliente" style="margin-left: 10px;"
                (click)="openClientDetailsModal(clientRowSelected)" [disabled]="!clientRowSelected"></button>
        </div>
    </div>
</div>

<p-dialog header="Agregar de cliente" [(visible)]="clientAddModalStatus" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <p-messages [(value)]="msg"></p-messages>
    <div class="p-grid">
        <div class="p-col-12 p-md-6">
            <div class="inputLabel">Cliente:</div>
            <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="clientForm.Client">
        </div>
        <div class="p-col-12 p-md-6">
            <div class="inputLabel">Telefono:</div>
            <p-inputNumber [(ngModel)]="clientForm.Phone" mode="decimal" [style]="{width: '100%'}" [useGrouping]="false"
                [maxlength]="10" [minlength]="10">
            </p-inputNumber>
        </div>
        <div class="p-col-12 p-md-6">
            <div class="inputLabel">Ubicación:</div>
            <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="clientForm.Location">
        </div>
        <div class="p-col-12">
            <span style="margin: 10px;">Cliente completo:</span>
            <p-inputSwitch [(ngModel)]="fullDataClient"></p-inputSwitch>
        </div>
        <div class="p-grid padding-0" style="width: 100%;" *ngIf="fullDataClient">
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">Dirección:</div>
                <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="clientForm.Address">
            </div>
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">Email:</div>
                <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="clientForm.Email">
            </div>
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">Tipo de gasto:</div>
                <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="clientForm.ExpenseType">
            </div>
            <div class="p-col-12 p-md-6">
                <div class="inputLabel">RFC:</div>
                <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="clientForm.RFC">
            </div>
        </div>

    </div>
    <ng-template pTemplate="footer">
        <p-button label="Cancelar" styleClass="p-button-secondary" (click)="clientAddModalStatus=false">
        </p-button>
        <p-button label="Aceptar" styleClass="p-button-secondary" (click)="selectClientValidation()">
        </p-button>
    </ng-template>
</p-dialog>

<p-dialog header="Detalles de cliente" [(visible)]="clientDetailsModalStatus" [modal]="true" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <p-messages [(value)]="msg"></p-messages>
    <div class="p-grid">
        <div class="p-col-12 p-md-6">
            <div class="inputLabel">Cliente:</div>
            <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="clientForm.Client"
                [disabled]="!clientEditStatus">
        </div>
        <div class="p-col-12 p-md-6">
            <div class="inputLabel">Telefono:</div>
            <p-inputNumber [(ngModel)]="clientForm.Phone" mode="decimal" [style]="{width: '100%'}" [useGrouping]="false"
                [disabled]="!clientEditStatus" [maxlength]="10" [minlength]="10">
            </p-inputNumber>
        </div>
        <div class="p-col-12 p-md-6">
            <div class="inputLabel">Dirección:</div>
            <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="clientForm.Address"
                [disabled]="!clientEditStatus">
        </div>
        <div class="p-col-12 p-md-6">
            <div class="inputLabel">Email:</div>
            <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="clientForm.Email"
                [disabled]="!clientEditStatus">
        </div>
        <div class="p-col-12 p-md-6">
            <div class="inputLabel">Tipo de gasto:</div>
            <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="clientForm.ExpenseType"
                [disabled]="!clientEditStatus">
        </div>
        <div class="p-col-12 p-md-6">
            <div class="inputLabel">RFC:</div>
            <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="clientForm.RFC"
                [disabled]="!clientEditStatus">
        </div>
        <div class="p-col-12 p-md-6">
            <div class="inputLabel">Ubicación:</div>
            <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="clientForm.Location"
                [disabled]="!clientEditStatus">
        </div>

        <div class="p-col-6 p-md-3" style="margin-top: 15px;">
            <div style="display: flex;justify-content: center;align-items: center;height: 100%;">
                <button *ngIf="!clientEditStatus" pButton pRipple type="button" icon="pi pi-pencil" class="p-button"
                    label="Editar" (click)="clientEditStatus=true"></button>
                <button *ngIf="clientEditStatus" pButton pRipple type="button" icon="pi pi-save"
                    class="p-button p-button-success" label="Guardar" (click)="updateClientValidation()"></button>
            </div>
        </div>
        <div class="p-col-6 p-md-3" style="margin-top: 15px;">
            <div style="display: flex;justify-content: center;align-items: center;height: 100%;">
                <button pButton pRipple type="button" icon="pi pi-trash" label="Eliminar"
                    class="p-button p-button-danger" (click)="deleteClient()"></button>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button label="Cancelar" styleClass="p-button-secondary" (click)="clientDetailsModalStatus=false">
        </p-button>
    </ng-template>
</p-dialog>
