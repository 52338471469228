<div class="newOrderContainer">
    <div class="p-grid" style="padding: 8px;">

        <div class="p-col-12 p-md-3">
            Cliente existente : <p-checkbox [(ngModel)]="clientStatus" [binary]="true"></p-checkbox>
        </div>
        <div class="p-col-12 p-md-5">
            <p-dropdown [options]="allClients" [(ngModel)]="selectedClient" optionLabel="client"
                placeholder="Seleccione Usuario" [filter]="true" [style]="{width: '100%'}" [disabled]="!clientStatus"
                (onChange)="clientChange()">
            </p-dropdown>
        </div>
        <div class="p-col-12 p-md-4">
            <button pButton pRipple type="button" class="p-button-rounded" style="float: right;" label="Limpiar campos"
                (click)="clearForm()"></button>
        </div>


        <div class="p-col-12  p-md-3">
            <div class="inputLabel">Cliente*</div>
            <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="orderForm.Client">
        </div>
        <div class="p-col-12 p-md-3">
            <div class="inputLabel">Telefono</div>
            <p-inputNumber id="Phone" [style]="{width: '100%'}" [(ngModel)]="orderForm.Phone" mode="decimal"
                [useGrouping]="false" [maxlength]="10" [minlength]="10">
            </p-inputNumber>
        </div>
        <div class="p-col-12 p-md-3">
            <div class="inputLabel">Ubicación*</div>
            <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="orderForm.Location">
        </div>
        <div class="p-col-12 p-md-3">
            <div class="inputLabel">Extras</div>
            <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="orderForm.Extras">
        </div>
        <div class="p-col-12  p-md-3">
            <div class="inputLabel">Vendedor</div>
            <p-dropdown [options]="allUsers" [filter]="true" [(ngModel)]="orderForm.Salesman" optionLabel="name"
                [style]="{width: '100%'}" [disabled]="!adminStatus">
            </p-dropdown>
        </div>
        <div class="p-md-9"></div>
        <div class=" p-col-12 p-md-4">
            <div class="inputLabel">Producto</div>
            <p-dropdown [options]="availableProducts" [filter]="true" [(ngModel)]="selectedProduct" optionLabel="name"
                [style]="{width: '100%'}" placeholder="Seleccione producto a agregar" (onChange)="productChange()"
                [autofocusFilter]="false">
            </p-dropdown>
        </div>
        <div class="p-col-8 p-md-4">
            <div class="inputLabel">Cantidad</div>
            <p-inputNumber [(ngModel)]="selectedProductQuantity" mode="decimal" [style]="{width: '100%'}"
                [useGrouping]="false">
            </p-inputNumber>
        </div>
        <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded" style="margin-top: 32px;"
            (click)="addProductValidation()"></button>
        <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded phoneShow"
            style="margin-top: 32px;margin-left: 15px;" (click)="deleteProduct(productRowSelected)"
            [disabled]="!productRowSelected"></button>
    </div>
    <p-table *ngIf="orderForm.Products.length >  0" [value]="orderForm.Products" [scrollable]="true"
        selectionMode="single" [(selection)]="productRowSelected" dataKey="id" (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)">
        <ng-template pTemplate="header">
            <tr>
                <th>Piezas</th>
                <th>Producto</th>
                <th>Cont.Net.</th>
                <th>Precio</th>
                <th class="phoneHide">Opciones</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr [pSelectableRow]="product">
                <td>{{product.pieces}}</td>
                <td>{{product.name}}</td>
                <td>{{product.netcontent}}</td>
                <td>{{product.price}}</td>
                <td class="phoneHide"><button pButton pRipple type="button" icon="pi pi-trash"
                        (click)="deleteProduct(product)"></button>
                </td>

            </tr>
        </ng-template>
    </p-table>


    <div class="p-col-12">
        Factura : <p-inputSwitch [(ngModel)]="orderForm.Invoice"></p-inputSwitch>
    </div>
    <div class="p-col-12">
        Precio sugerido {{orderForm.SuggestedPrice}}
    </div>
    <div class="p-col-12">
        Precio Final :
        <p-inputNumber id="Price" [(ngModel)]="orderForm.Price" mode="decimal" [useGrouping]="false"
            placeholder="Precio Final">
        </p-inputNumber>
    </div>
    <span class="p-col-12">
        <button pButton type="button" style="float:right;margin:20px" label="Crear orden"
            (click)="createOrderValidation()"></button>
    </span>

    <p-toast position="top-right" closable="true"></p-toast>
</div>