import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.baseUrl;

  getProductsAvailable() {
    return this.http.post(`${this.baseUrl}ProductList`, { "action": "getAvailability" }, { responseType: 'json' })
  }

  getLazyOrders(data) {
    return this.http.post(`${this.baseUrl}Order`, data, { responseType: 'json' })
  }

  getOrders() {
    return this.http.get(`${this.baseUrl}Order`, { responseType: 'json' })
  }

  getOrderDetails(Id) {
    let data = { action: "getOrderDetails", id: Id }
    return this.http.post(`${this.baseUrl}Order`, data, { responseType: 'json' })
  }

  getOrderStatus() {
    return this.http.get(`${this.baseUrl}OrderStatus`, { responseType: 'json' })
  }

  confirmOrderDelivery(Id) {
    let data = { action: "confirmOrderDelivery", id: Id }
    return this.http.post(`${this.baseUrl}OrderStatus`, data, { responseType: 'json' })
  }

  OrderActions(data) {
    return this.http.post(`${this.baseUrl}Order`, data, { responseType: 'text' })
  }

  getClients() {
    return this.http.get(`${this.baseUrl}Client`, { responseType: 'json' })
  }

  ClientActions(data) {
    return this.http.post(`${this.baseUrl}Client`, data, { responseType: 'text' })
  }

  getClientDetails(Id) {
    let data = { action: "getDetails", id: Id }
    return this.http.post(`${this.baseUrl}Client`, data, { responseType: 'json' })
  }

  sendNotification(data) {
    return this.http.post(`${this.baseUrl}Notifications`, data, { responseType: 'json' })
  }

}
