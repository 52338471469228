import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.baseUrl;

  getOrders(data) {
    return this.http.post(`${this.baseUrl}SalesmanOrders`, data, { responseType: 'json' })
  }

  getInvoiceOrders(data) {
    return this.http.post(`${this.baseUrl}Order`, data, { responseType: 'json' })
  }
}
