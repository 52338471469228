import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SalesService } from '../sales/sales.service';
import { UserService } from '../users/user.service';
import { OrdersService } from './orders.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class OrdersComponent implements OnInit {

  constructor(
    private orderService: OrdersService,
    private userService: UserService,
    private salesService: SalesService,
    private router: Router,
    private messageService: MessageService) { }

  msg: any;

  rangeDates: Date[];
  salesman: any;
  allUsers: any;
  allStatus: any;

  invoiceRangeDates: Date[];
  invoiceTextFilter: any;
  invoiceCreateDate: Date = new Date();
  invoiceTotal: any;

  allInvoiceOrders: any;
  orderInvoiceRowSelected: any;

  allOrders: any;
  orderRowSelected: any;
  suggestedIncomingPrice: any;
  searchData: any;

  orderForm = {
    Id: null,
    Client: '',
    ClientId: null,
    Invoice: false,
    Location: '',
    Date: null,
    Status: null,
    Price: 0,
    Salesman: '',
    SuggestedPrice: 0,
    Extras: '',
    Phone: null,
    Delivery: null,
    Products: [],
    Origin: null
  }

  clientForm = {
    Id: null,
    Client: '',
    Address: '',
    Email: '',
    ExpenseType: '',
    RFC: '',
    Location: '',
    Phone: null
  }

  productsOriginModalStatus: boolean = false;
  orderDetailsModalStatus: boolean = false;
  isURL: boolean = false;

  ngOnInit(): void {
    this.userService.getUsersInformation().subscribe(data => this.allUsers = data);
    this.salesService.getOrderStatus().subscribe(data => this.allStatus = data);
  }

  onRowSelect(event) {
    this.orderRowSelected = event.data;
  }

  onRowUnselect(event) {
    this.orderRowSelected = null;
  }

  setStatusIcon(id) {
    if (id == 1) { return "pi-clock" }
    else if (id == 2) { return "pi-check-circle" }
    else { return "pi-times-circle" }
  }

  setStatusColor(id) {
    if (id == 1) { return "color:#ffff94" }//YELLOW
    else if (id == 2) { return "color:#6eb16e" }//GREEN
    else { return "color:#ff7171" } //RED
  }

  setOrderStatus(id) {
    let res = "";
    if (this.allStatus != null) {
      this.allStatus.forEach(item => {
        if (item.id == id) { res = item.name }
      });
    }
    return res
  }

  openOrderDetails(item) {
    this.msg = [];
    this.salesService.getOrderDetails(item.id).subscribe(data => {
      let order: any = data;
      this.orderForm = {
        Id: order.id,
        Client: order.client,
        ClientId: order.clientId,
        Invoice: order.invoice,
        Location: order.location,
        Date: order.orderDate,
        Status: order.orderStatus,
        Price: order.price,
        Salesman: order.salesman.name,
        SuggestedPrice: order.suggestedPrice,
        Extras: order.extras,
        Delivery: order.delivery,
        Products: order.products,
        Phone: order.clientPhone,
        Origin: order.productOrigin
      }
      try { let url = new URL(order.location); this.isURL = true; } catch (_) { this.isURL = false; }
      this.orderDetailsModalStatus = true;
    });
  }

  searchOrders() {
    this.orderRowSelected = null;
    if (!this.rangeDates) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Seleccione un rango de fechas' });
    } else if (this.rangeDates[1] == null) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Seleccione una segunda fecha' });
    } else {
      let data = {
        dateFrom: this.rangeDates[0],
        dateTo: this.rangeDates[1]
      };
      if (this.salesman) { Object.assign(data, { salesman: this.salesman.clientId }) }
      this.searchData = data
      this.orderService.getOrders(data).subscribe(res => { this.allOrders = res; this.updateIncomingPrice() });
    }
  }

  confirmDelivery() {
    this.salesService.confirmOrderDelivery(this.orderForm.Id).subscribe(res => {
    this.orderService.getOrders(this.searchData).subscribe(res => { this.allOrders = res; this.updateIncomingPrice(); this.orderDetailsModalStatus = false; });
    })
  }

  getDeliveryTime() {
    var time = new Date(this.orderForm.Delivery).getTime() - new Date(this.orderForm.Date).getTime();

    // transform milliseconds to Minutes
    time = time / 60000;
    let units = " Min";
    var res = time.toFixed(2) + units;
    // transform Minutes to Hours and limited to 2 decimals
    if (time >= 60 && units == " Min") {
      time = time / 60;
      units = " Hrs";
      res = time.toFixed(2) + units;
    }
    // transform Hours to Days and limited to 2 decimals
    if (time >= 24 && units == " Hrs") {
      time = time / 24;
      units = " Dias";
      res = time.toFixed(2) + units;
    }
    return res;
  }

  CancelOrder() {
    let data = {
      "action": "cancel",
      "id": this.orderForm.Id
    }
    this.salesService.OrderActions(data).subscribe(res => {
    this.orderService.getOrders(this.searchData).subscribe(res => { this.allOrders = res; this.updateIncomingPrice(); this.orderDetailsModalStatus = false; });
    })
  }

  editOrder() {
    this.router.navigate(['/system/sales/editOrder/' + this.orderForm.Id]);
  }

  updateIncomingPrice() {
    this.suggestedIncomingPrice = 0;
    this.allOrders.forEach(item => {
      if (item.orderStatus == 2) {
        this.suggestedIncomingPrice += item.suggestedPrice;
      }
    });
  }

  PDFdownload() {
    let doc = new jsPDF('p', 'pt', 'a4');
    let content = document.getElementById('exportTable');
    doc.html(content, {
      callback: (doc) => {
        var img = new Image();
        img.src = 'assets/Images/horizontalLogo.png';
        doc.addImage(img, 'png', 25, 65, 200, 42)
        doc.save('Corte.pdf');
      }
    });
  }

  ////////////////////////////////////////////INVOICE///////////////////////////////////////////

  onInvoiceRowSelect(event) {
    if (event.data.orderStatus == 2) { this.orderInvoiceRowSelected = event.data; }
    else { this.orderInvoiceRowSelected = null; }
  }

  onInvoiceRowUnselect(event) {
    this.orderInvoiceRowSelected = null;
  }

  searchInvoiceOrders() {
    this.orderInvoiceRowSelected = null;
    if (!this.invoiceRangeDates) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Seleccione un rango de fechas' });
    } else if (this.invoiceRangeDates[1] == null) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Seleccione una segunda fecha' });
    } else {
      let data = {
        action: "getInvoiceOrders",
        dateFrom: this.invoiceRangeDates[0],
        dateTo: this.invoiceRangeDates[1]
      };
      if (this.invoiceTextFilter) { Object.assign(data, { textFilter: this.invoiceTextFilter }) }
      this.orderService.getInvoiceOrders(data).subscribe(res => { this.allInvoiceOrders = res; });
    }
  }

  PDFdownloadInvoice(order) {

    this.orderForm = {
      Id: order.id,
      Client: order.client,
      ClientId: order.clientId,
      Invoice: order.invoice,
      Location: order.location,
      Date: order.orderDate,
      Status: order.orderStatus,
      Price: order.price,
      Salesman: order.salesman.name,
      SuggestedPrice: order.suggestedPrice,
      Extras: order.extras,
      Delivery: order.delivery,
      Products: order.products,
      Phone: order.clientPhone,
      Origin: null
    }

    if (this.orderForm.ClientId != null) {
      this.salesService.getClientDetails(this.orderForm.ClientId).subscribe(data => {
        let res: any = data;
        this.clientForm = {
          Id: res.id,
          Client: res.client,
          RFC: res.rfc,
          Email: res.email,
          Address: res.address,
          ExpenseType: res.expenseType,
          Location: res.location,
          Phone: res.phone
        };
      });
    } else {
      this.clientForm = {
        Id: "N/A",
        Client: this.orderForm.Client,
        RFC: "N/A",
        Email: "N/A",
        Address: "N/A",
        ExpenseType: "N/A",
        Location: "N/A",
        Phone: this.orderForm.Phone
      };
    }

    setTimeout(() => {
      let doc = new jsPDF('p', 'pt', 'a4');
      let content = document.getElementById('InvoiceOrder');
      doc.html(content, {
        callback: (doc) => {
          var img = new Image();
          img.src = 'assets/Images/horizontalLogo.png';
          doc.addImage(img, 'png', 62, 65, 200, 42)
          doc.save('Factura.pdf');
        }
      });
    }, 3000);


  }

}
