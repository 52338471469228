import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productFilter'
})
export class ProductPipe implements PipeTransform {

  transform(list: any, category: any, other: any, searchText: string): any {

    let filterResults = [];

    const checkCategoryFilter = (product) => {
      let isCorrect = true;
      if (category) {
        isCorrect = product.category === category.id;
      }
      return isCorrect;
    }

    const checkOthersFilter = (product) => {
      let isCorrect = true;
      if (other) {
        switch (other.id) {
          case 3: {
            //En Existencia; 
            isCorrect = product.totalpieces > 0;
            break;
          }
          case 4: {
            //Agotado; 
            isCorrect = product.totalpieces == 0;
            break;
          }
        }

      }
      return isCorrect;
    }

    const checkTextFilter = (product) => {
      let isCorrect = true;
      if (searchText) {
        isCorrect = product.name.toLowerCase().includes(searchText.toLowerCase())
      }
      return isCorrect;
    }

    list && list.forEach(item => {
      const validation =
        checkCategoryFilter(item) &&
        checkOthersFilter(item) &&
        checkTextFilter(item);
      if (validation) { filterResults.push(item); }
    });

    if (other) {
      switch (other.id) {
        case 1: {
          //Mayor a menor precio; 
          filterResults.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
          break;
        }
        case 2: {
          //Menor a mayor precio; 
          filterResults.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
          break;
        }
        case 3: {
          //Existencia; 
          filterResults.sort((a, b) => parseFloat(b.totalpieces) - parseFloat(a.totalpieces));
          break;
        }
      }
    }

    return filterResults;

  }

}
