// Angular modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Components
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './system/main/main.component';
import { HeaderComponent } from './system/header/header.component';
import { HomeComponent } from './homepage/home/home.component';
import { UsersComponent } from './system/users/users.component';
import { InventoryComponent } from './system/inventory/inventory.component';
import { DashboardComponent } from './system/dashboard/dashboard.component';
import { SalesComponent } from './system/sales/sales.component';
import { OrdersComponent } from './system/orders/orders.component';
import { MenuComponent } from './system/menu/menu.component';
import { CatalogComponent } from './system/catalog/catalog.component';
import { CatalogPublicComponent } from './homepage/catalog-public/catalog-public.component';
import { ContactComponent } from './homepage/contact/contact.component';
import { HeaderPublicComponent } from './homepage/header-public/header-public.component';
import { NewOrderComponent } from './system/sales/new-order/new-order.component';
import { ClientsComponent } from './system/sales/clients/clients.component';
import { OrderListComponent } from './system/sales/order-list/order-list.component';
import { EditOrderComponent } from './system/sales/edit-order/edit-order.component';
import { ProductsComponent } from './system/inventory/products/products.component';
import { ProductsManageComponent } from './system/inventory/products-manage/products-manage.component';
import { AcquisitionsComponent } from './system/inventory/acquisitions/acquisitions.component';

// NgPrime modules
import { ChartModule } from 'primeng/chart';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { DataViewModule } from 'primeng/dataview';
import { TagModule } from 'primeng/tag';
import { ScrollTopModule } from 'primeng/scrolltop';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressBarModule } from 'primeng/progressbar';

// Other Libraries
import { JwtInterceptor, } from './jwt.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { ProductPipe } from './system/catalog/product.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    MainComponent,
    HeaderComponent,
    HomeComponent,
    UsersComponent,
    InventoryComponent,
    DashboardComponent,
    SalesComponent,
    OrdersComponent,
    MenuComponent,
    ProductPipe,
    CatalogComponent,
    CatalogPublicComponent,
    ContactComponent,
    HeaderPublicComponent,
    NewOrderComponent,
    ClientsComponent,
    OrderListComponent,
    EditOrderComponent,
    ProductsComponent,
    ProductsManageComponent,
    AcquisitionsComponent
  ],
  imports: [
    FormsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    ChartModule,
    ButtonModule,
    CardModule,
    InputTextModule,
    BrowserAnimationsModule,
    TableModule,
    TooltipModule,
    DialogModule,
    InputNumberModule,
    DropdownModule,
    MultiSelectModule,
    InputSwitchModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    SidebarModule,
    TabViewModule,
    DataViewModule,
    TagModule,
    ScrollTopModule,
    FileUploadModule,
    CalendarModule,
    CarouselModule,
    InputTextareaModule,
    CheckboxModule,
    ProgressBarModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
