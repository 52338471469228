import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SalesService } from '../../sales/sales.service';
import { InventoryService } from '../inventory.service';

@Component({
  selector: 'app-products-manage',
  templateUrl: './products-manage.component.html',
  styleUrls: ['./products-manage.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class ProductsManageComponent implements OnInit {

  constructor(
    private intentoryService: InventoryService,
    private messageService: MessageService,
    private salesService: SalesService) { }

  msg: any[] = [];

  AvailableProducts: any;
  allWarehouses: any;
  allProducts: any;

  productToMove: any;
  productAcquisitions: any;
  productTotalPieces: any;
  warehousesPieces: any;
  warehouseFrom: any;
  warehouseTo: any;
  piecesToMove: any;
  maxPiecesToMove: any;

  warehouseToLook: any;
  allWarehousesToLook: any;
  warehousesProducts: any;

  moveProductConfirmationModal: boolean = false;
  undoConfirmationModal: boolean = false;

  defaultImage: string = 'assets/Images/system/default.png'

  ngOnInit(): void {
    this.salesService.getProductsAvailable().subscribe(data => this.AvailableProducts = data);
    this.intentoryService.getWarehouses().subscribe(data => this.allWarehousesToLook = data);
    this.intentoryService.getAllProducts().subscribe(data => this.allProducts = data);
  }

  getWarehouseProducts() {
    let data = {
      action: "getWarehousePices",
      id: this.warehouseToLook.id
    }
    this.intentoryService.ProductManage(data).subscribe(data => this.warehousesProducts = data);

  }

  sendActionNotification(data)
  {
    console.log(this.warehouseFrom);
    let notification = {
      ID: this.productToMove.id,
      Acción: data ,
      Cantidad: this.piecesToMove,
      "Almacén de destino": this.warehouseFrom.warehouseName,
      "Almacén de origen": this.warehouseTo.name
    }
    this.intentoryService.sendNotification(notification).subscribe();
  }
  isAdmin()
  {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    let userModules = currentUser.modules
    userModules.forEach(obj => {
      if (obj == "dashboard")
      {return true}
      else
      {return false}
     });
    return false
  }
  // PRODUCT MANAGEMENT

  getMoveProduct() {
    let data = {
      action: "getProductPices",
      id: this.productToMove.id
    }
    this.intentoryService.ProductManage(data).subscribe(data => {
      this.warehousesPieces = data;
      if (this.warehousesPieces != null) {
        this.warehouseFrom = this.warehousesPieces[0];
        this.intentoryService.getWarehouses().subscribe(data => {
          this.allWarehouses = data;
          this.maxPiecesToMove = this.warehousesPieces[0].pieces;
          this.piecesToMove = this.maxPiecesToMove;
          let item: any;
          this.allWarehouses.forEach(element => {
            if (element.id == this.warehousesPieces[0].warehouseId) { item = element; }
          });
          const index = this.allWarehouses.indexOf(item, 0);
          if (index > -1) { this.allWarehouses.splice(index, 1); }
          this.warehouseTo = this.allWarehouses[0];
        });
      }
    });
  }

  setMoveProduct() {
    this.maxPiecesToMove = this.warehouseFrom.pieces;
    this.piecesToMove = this.maxPiecesToMove;
    this.intentoryService.getWarehouses().subscribe(data => {
      this.allWarehouses = data;
      let item: any;
      this.allWarehouses.forEach(element => {
        if (element.id == this.warehouseFrom.warehouseId) { item = element; }
      });
      const index = this.allWarehouses.indexOf(item, 0);
      if (index > -1) { this.allWarehouses.splice(index, 1); }
      this.warehouseTo = this.allWarehouses[0];
    });
  }

  moveProduct() {
    this.moveProductConfirmationModal = false;
    let data = {
      action: "move",
      productId: this.productToMove.id,
      quantity: this.piecesToMove,
      warehouseFromId: this.warehouseFrom.warehouseId,
      warehouseToId: this.warehouseTo.id
    };
    this.intentoryService.ProductManage(data).subscribe(data => {
      this.getMoveProduct();
      this.salesService.getProductsAvailable().subscribe(data => this.AvailableProducts = data)
      this.msg = [];
      if(!this.isAdmin()) {this.sendActionNotification('Piezas Movidas')}
      this.messageService.add({ severity: 'success', summary: '', detail: 'Piezas movidas correctamente' });
    });
  }

  undoChanges() {
    this.intentoryService.ProductManage({ action: "undo" }).subscribe(res => {
      this.undoConfirmationModal = false;
      this.getMoveProduct();
      this.msg = [];
      this.messageService.add({ severity: 'success', summary: '', detail: 'Cambios deshechos correctamente' });
    }, (error) => {
      this.undoConfirmationModal = false;
      this.msg = [];
      this.messageService.add({ severity: 'error', summary: '', detail: 'No hay cambios por deshacer' });
    });
  }

  showConfirmationModal() {
    if (this.piecesToMove <= this.maxPiecesToMove) {
      this.moveProductConfirmationModal = true;
    } else {
      this.msg = [];
      this.messageService.add({ severity: 'error', summary: '', detail: 'Piezas a mover excede las existentes' });
    }
  }
}
