<div class="acquisitionsContainer">
  <div class="p-d-flex p-jc-between">
    <span>Productos Ingresados</span>
    <div>
      <button pButton type="button" label="Almacenes" class="p-button phoneHide" style="margin-right: 10px;"
        (click)="openWarehousesModal()"></button>
      <button pButton type="button" label="Divisas" class="p-button phoneHide" style="margin-right: 10px;"
        (click)="openCurrenciesModal()"></button>
      <button pButton type="button" class="p-button-help" label="Ingresar adquisición" icon="pi pi-plus "
        (click)="openCreateAcquisitions()"></button>
    </div>
  </div>
  <p-table class="acquisitionsList" [paginator]="true" [paginatorPosition]="isMobileDevice ? 'top' : 'bottom'"
    [rows]="9" [value]="allAcquisitions" [scrollable]="true" selectionMode="single" [lazy]="true"
    (onLazyLoad)="getLazyAcquisitions($event)" [totalRecords]="totalRecords" [(selection)]="acquisitionRowSelected"
    dataKey="id" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)">
    <ng-template pTemplate="header">
      <tr>
        <th class="phoneHide">Id
          <p-columnFilter type="text" matchMode="in" field="id" display="menu" [showMatchModes]="false"
            [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <input type="text" pInputText [(ngModel)]="lazyData.id" placeholder="Buscar" />
              <div class="p-column-filter-buttonbar">
                <button pButton class="p-button-outlined idFilter" (click)="clearId()">Clear</button>
                <button pButton class="idFilter" (click)="tableFilter()">Apply</button>
              </div>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          Producto
          <p-columnFilter display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"
            [hideOnClear]="true" [showClearButton]="false" [showApplyButton]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown [options]="allProducts" [(ngModel)]="lazyData.product" optionLabel="name" optionValue="id"
                placeholder="Any" [filter]="true" (onChange)="tableFilter()">
              </p-dropdown>
              <div class="p-column-filter-buttonbar">
                <button pButton class="p-button-outlined idFilter" (click)="clearProduct()">Clear</button>
              </div>
            </ng-template>
          </p-columnFilter>
        </th>
        <th  style="display: flex;align-items: center;">
          Existencia / Adquiridas
          <button pButton type="button" icon="pi pi-sort" (click)="orderDirectionAcquisitions()" *ngIf="isAscendingAcqui === null">
          </button>
          <button pButton type="button" *ngIf="isAscendingAcqui !== null" (click)="orderDirectionAcquisitions()">
           <i class="pi pi-sort-up" *ngIf="isAscendingAcqui"></i>
           <i class="pi pi-sort-down" *ngIf="!isAscendingAcqui"></i>
          </button>
        </th>
        <th>
          Ubicacion
          <p-columnFilter display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"
            [hideOnClear]="true" [showClearButton]="false" [showApplyButton]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown [options]="allWarehousesFilter" [(ngModel)]="lazyData.warehouse" optionLabel="name"
                optionValue="id" placeholder="Any" [filter]="true" (onChange)="tableFilter()">
              </p-dropdown>
              <div class="p-column-filter-buttonbar">
                <button pButton class="p-button-outlined idFilter" (click)="clearLocation()">Clear</button>
              </div>
            </ng-template>
          </p-columnFilter>
        </th>
        <th  style="display: flex;align-items: center;">
          Costos / Precio de compra
          <button pButton type="button" icon="pi pi-sort" (click)="orderDirectionCosts()" *ngIf="isAscendingCost === null">
          </button>
          <button pButton type="button" *ngIf="isAscendingCost !== null" (click)="orderDirectionCosts()">
           <i class="pi pi-sort-up" *ngIf="isAscendingCost"></i>
           <i class="pi pi-sort-down" *ngIf="!isAscendingCost"></i>
          </button>
        </th>
        <th class="phoneHide">Detalles</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-acquisition>
      <tr [pSelectableRow]="acquisition">
        <td class="phoneHide">{{acquisition.id}}</td>
        <td>{{acquisition.product.name}}</td>
        <td class="centerText">{{acquisition.pieces}}/{{acquisition.acquired}}</td>
        <td>{{acquisition.warehouse.name}}</td>
        <td>{{acquisition.cost}} {{acquisition.currency.name}}</td>
        <td class="phoneHide"> <button pButton pRipple type="button" icon="pi pi-book" class="p-button-rounded"
            (click)="openAcquisitionsDetails(acquisition)"></button> </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="phoneShow" style="height: 50px;padding:8px;position: fixed;bottom: 0px;width: 100vw;background: #0d111c;">
    <div class="p-d-flex p-ai-center" style="height: 100%;">
      <button pButton type="button" label="Almacenes" class="p-button" style="margin-right: 10px;"
        (click)="openWarehousesModal()"></button>
      <button pButton type="button" label="Divisas" class="p-button" style="margin-right: 10px;"
        (click)="openCurrenciesModal()"></button>
      <button pButton pRipple type="button" icon="pi pi-book" label="Detalles" class="p-button"
        (click)="openAcquisitionsDetails(acquisitionRowSelected)" [disabled]="!acquisitionRowSelected"></button>
    </div>
  </div>

  <p-toast position="top-right"></p-toast>
</div>

<p-dialog header="Detalles de compra" [(visible)]="acquisitionDetailsModalStatus" [modal]="true" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false">
  <p-messages [(value)]="msg"></p-messages>
  <div class="p-grid">
    <div class="p-col-12 p-md-3">
      <div class="inputLabel">Id:</div>
      <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="acquisitionForm.id" [disabled]="true">
    </div>
    <div class="p-col-12 p-md-5">
      <div class="inputLabel">Producto:</div>
      <p-dropdown class="p-col-12 padding-0" [options]="allProducts" [filter]="true"
        [(ngModel)]="acquisitionForm.product" optionLabel="name" [style]="{width: '100%'}"
        [disabled]="acquisitionEditStatus">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-4">
      <div class="inputLabel">Admision:</div>
      <p-calendar [(ngModel)]="acquisitionForm.admission" [style]="{width: '100%'}"
        [disabled]="acquisitionEditStatus"></p-calendar>
    </div>
    <div class="p-col-12 p-md-2">
      <div class="inputLabel">Costo:</div>
      <p-inputNumber [(ngModel)]="acquisitionForm.cost" mode="decimal" [style]="{width: '100%'}"
        [inputStyle]="{width: '100%'}" [useGrouping]="false" [disabled]="acquisitionEditStatus">
      </p-inputNumber>
    </div>
    <div class="p-col-12 p-md-3">
      <div class="inputLabel">Divisa:</div>
      <p-dropdown class="p-col-12 padding-0" [options]="allCurrencies" [(ngModel)]="acquisitionForm.currency"
        optionLabel="name" placeholder="Divisa" [style]="{width: '100%'}" [disabled]="acquisitionEditStatus">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-3">
      <div class="inputLabel">Piezas existentes:</div>
      <p-inputNumber [(ngModel)]="acquisitionForm.pieces" mode="decimal" [style]="{width: '100%'}"
        [inputStyle]="{width: '100%'}" [useGrouping]="false" [disabled]="acquisitionEditStatus">
      </p-inputNumber>
    </div>
    <div class="p-col-12 p-md-4">
      <div class="inputLabel">Piezas adquiridas:</div>
      <p-inputNumber [(ngModel)]="acquisitionForm.acquired" mode="decimal" [style]="{width: '100%'}"
        [inputStyle]="{width: '100%'}" [useGrouping]="false" [disabled]="acquisitionEditStatus">
      </p-inputNumber>
    </div>
    <div class="p-col-12 p-md-6">
      <div class="inputLabel">Almacen:</div>
      <p-dropdown class="p-col-12 padding-0" [options]="allWarehouses" [(ngModel)]="acquisitionForm.warehouse"
        optionLabel="name" placeholder="Almacen" [style]="{width: '100%'}" [disabled]="acquisitionEditStatus">
      </p-dropdown>
    </div>
    <div class="p-col-6 p-md-3" style="margin-top: 25px;">
      <div style="display: flex;justify-content: center;align-items: center;height: 100%;">
        <button *ngIf="acquisitionEditStatus" pButton pRipple type="button" icon="pi pi-pencil" class="p-button"
          label="Editar" (click)="acquisitionEditStatus=false"></button>
        <button *ngIf="!acquisitionEditStatus" pButton pRipple type="button" icon="pi pi-save"
          class="p-button p-button-success" label="Guardar" (click)="updateAcquisitionValidation()"></button>
      </div>
    </div>
    <div class="p-col-6 p-md-3" style="margin-top: 25px;">
      <div style="display: flex;justify-content: center;align-items: center;height: 100%;">
        <button pButton pRipple type="button" icon="pi pi-trash" label="Borrar" class="p-button p-button-danger"
          (click)="deleteAcquisition()"></button>
      </div>

    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button label="Cancelar" styleClass="p-button-secondary" (click)="acquisitionDetailsModalStatus=false">
    </p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Registrar compra" [(visible)]="acquisitionModalStatus" [modal]="true" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false">
  <p-messages [(value)]="msg"></p-messages>
  <div class="p-grid">
    <div class="p-col-12 p-md-6">
      <div class="inputLabel">Producto: </div>
      <p-dropdown class="p-col-12 padding-0" [options]="allProducts" [filter]="true" optionLabel="name"
        [(ngModel)]="acquisitionForm.product" [style]="{width: '100%'}" placeholder="Seleccione">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6">
      <div class="inputLabel">Admision: </div>
      <p-calendar [(ngModel)]="acquisitionForm.admission" [style]="{width: '100%'}"> </p-calendar>
    </div>
    <div class="p-col-12 p-md-6">
      <div class="inputLabel">Costo: </div>
      <p-inputNumber [(ngModel)]="acquisitionForm.cost" mode="decimal" [style]="{width: '100%'}" [useGrouping]="false">
      </p-inputNumber>
    </div>
    <div class="p-col-12 p-md-6">
      <div class="inputLabel">Divisa: </div>
      <p-dropdown class="p-col-12 padding-0" [options]="allCurrencies" [(ngModel)]="acquisitionForm.currency"
        optionLabel="name" placeholder="Seleccione" [style]="{width: '100%'}"> </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6">
      <div class="inputLabel">Piezas adquiridas: </div>
      <p-inputNumber [(ngModel)]="acquisitionForm.acquired" mode="decimal" [style]="{width: '100%'}"
        [useGrouping]="false"> </p-inputNumber>
    </div>
    <div class="p-col-12 p-md-6">
      <div class="inputLabel">Almacen: </div>
      <p-dropdown class="p-col-12 padding-0" [options]="allWarehouses" [(ngModel)]="acquisitionForm.warehouse"
        optionLabel="name" placeholder="Seleccione" [style]="{width: '100%'}"> </p-dropdown>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button label="Cancelar" styleClass="p-button-secondary" (click)="acquisitionModalStatus=false">
    </p-button>
    <p-button label="Agregar" styleClass="p-button-success" (click)="createAcquisitionValidation()">
    </p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Almacenes" [(visible)]="warehousesModalStatus" [modal]="true" class="categoriesModal"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <div class="p-grid p-jc-end padding-0">
    <button pButton type="button" class="p-button-help" label="Crear almacen" icon="pi pi-users "
      style="margin-right: 9px;" (click)="openCreateWarehouse()"></button>
  </div>
  <p-table class="warehouseList" [value]="allWarehouses" [scrollable]="true">
    <ng-template pTemplate="header">
      <tr>
        <th>Almacen</th>
        <th>Adquisiciones con este almacen</th>
        <th>Opciones</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-warehouse>
      <tr>
        <td>{{warehouse.name}}</td>
        <td>{{warehouse.count}}</td>
        <td>
          <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded" style="margin-right: 10px;"
            pTooltip="Editar" tooltipPosition="top" (click)="openEditWarehouseModal(warehouse)"></button>
          <button *ngIf="warehouse.count == 0" pButton pRipple type="button" icon="pi pi-trash"
            class="p-button-rounded p-button-danger" pTooltip="Eliminar" tooltipPosition="top"
            (click)="openDeleteWarehouseModal(warehouse.id)"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <ng-template pTemplate="footer">
    <p-button (click)="warehousesModalStatus=false" label="Cerrar" styleClass="p-button-secondary">
    </p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Crear almacen" [(visible)]="warehouseCreateStatus" [modal]="true" [baseZIndex]="10001"
  [draggable]="false" [resizable]="false">
  <p-messages [(value)]="msg"></p-messages>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="inputLabel">Nombre para nuevo almacen:</div>
      <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="warehouseName">
    </div>
    <div class="p-col-6 p-md-3">Almacen de usuario :</div>
    <p-inputSwitch class="p-col-6 p-md-9" [(ngModel)]="wharehouseOwnerStatus"></p-inputSwitch>
    <p-dropdown class="p-col-12" [options]="allUsers" [filter]="false" [(ngModel)]="wharehouseOwner" optionLabel="name"
      [style]="{width: '100%'}" [disabled]="!wharehouseOwnerStatus" placeholder="Seleccione al usuario">
    </p-dropdown>
  </div>
  <ng-template pTemplate="footer">
    <p-button (click)="warehouseCreateStatus=false" label="No" styleClass="p-button-text">
    </p-button>
    <p-button (click)="createWarehouseValidation()" label="Crear" styleClass="p-button-success">
    </p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Editar almacen" [(visible)]="warehouseEditStatus" [modal]="true" [baseZIndex]="10001"
  [draggable]="false" [resizable]="false">
  <p-messages [(value)]="msg"></p-messages>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="inputLabel">Nombre para almacen:</div>
      <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="warehouseName">
    </div>
    <div class="p-col-6 p-md-3">Almacen de usuario :</div>
    <p-inputSwitch class="p-col-6 p-md-9" [(ngModel)]="wharehouseOwnerStatus"></p-inputSwitch>
    <p-dropdown class="p-col-12" [options]="allUsers" [filter]="false" [(ngModel)]="wharehouseOwner" optionLabel="name"
      [style]="{width: '100%'}" [disabled]="!wharehouseOwnerStatus" placeholder="Seleccione al usuario">
    </p-dropdown>
  </div>
  <ng-template pTemplate="footer">
    <p-button (click)="warehouseEditStatus=false" label="No" styleClass="p-button-text"></p-button>
    <p-button (click)="editWarehouse()" label="Guardar" styleClass="p-button-success"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Borrar almacen" [(visible)]="warehouseDeleteStatus" [modal]="true" [baseZIndex]="10001"
  [draggable]="false" [resizable]="false">
  <div style="padding: 8px;">¿Seguro que desea eliminar el almacen?</div>
  <ng-template pTemplate="footer">
    <p-button (click)="warehouseDeleteStatus=false" label="No" styleClass="p-button-text">
    </p-button>
    <p-button (click)="deleteWarehouse()" label="Si" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Divisas" [(visible)]="currenciesModalStatus" [modal]="true" class="categoriesModal"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <div class="p-grid p-jc-end padding-0">
    <button pButton type="button" class="p-button-help" label="Crear divisa" icon="pi pi-users "
      style="margin-right: 9px;" (click)="openCreateCurrency()"></button>
  </div>
  <p-table class="currenciesList" [value]="allCurrencies" [scrollable]="true">
    <ng-template pTemplate="header">
      <tr>
        <th>Divisa</th>
        <th>Valor</th>
        <th>Aquisiciones con esta divisa</th>
        <th>Opciones</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-currency>
      <tr>
        <td>{{currency.name}} ({{currency.abbreviation}})</td>
        <td>{{currency.value}}</td>
        <td>{{currency.count}}</td>
        <td>
          <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded" style="margin-right: 10px;"
            pTooltip="Editar" tooltipPosition="top" (click)="openEditCurrencyModal(currency)"></button>
          <button *ngIf="currency.count == 0" pButton pRipple type="button" icon="pi pi-trash"
            class="p-button-rounded p-button-danger" pTooltip="Eliminar" tooltipPosition="top"
            (click)="openDeleteCurrencyModal(currency.id)"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <ng-template pTemplate="footer">
    <p-button (click)="currenciesModalStatus=false" label="Cerrar" styleClass="p-button-secondary">
    </p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Crear divisa" [(visible)]="currencyCreateStatus" [modal]="true" [baseZIndex]="10001"
  [draggable]="false" [resizable]="false">
  <p-messages [(value)]="msg"></p-messages>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="inputLabel">Nombre: </div>
      <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="currencyForm.name">
    </div>
    <div class="p-col-12 p-md-6">
      <div class="inputLabel">Abreviación: </div>
      <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="currencyForm.abbreviation">
    </div>
    <div class="p-col-12 p-md-6">
      <div class="inputLabel">Valor: </div>
      <p-inputNumber id="Price" [(ngModel)]="currencyForm.value" mode="currency" currency="USD" locale="en-US"
        [useGrouping]="false" [style]="{width: '100%'}" [inputStyle]="{width: '100%'}">
      </p-inputNumber>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button (click)="currencyCreateStatus=false" label="No" styleClass="p-button-text">
    </p-button>
    <p-button (click)="createCurrencyValidation()" label="Crear" styleClass="p-button-success">
    </p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Editar divisa" [(visible)]="currencyEditStatus" [modal]="true" [baseZIndex]="10001"
  [draggable]="false" [resizable]="false">
  <p-messages [(value)]="msg"></p-messages>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="inputLabel">Nombre: </div>
      <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="currencyForm.name">
    </div>
    <div class="p-col-12 p-md-6">
      <div class="inputLabel">Abreviación: </div>
      <input type="text" pInputText [style]="{width: '100%'}" [(ngModel)]="currencyForm.abbreviation">
    </div>
    <div class="p-col-12 p-md-6">
      <div class="inputLabel">Valor: </div>
      <p-inputNumber id="Price" [(ngModel)]="currencyForm.value" mode="currency" currency="USD" locale="en-US"
        [useGrouping]="false" [style]="{width: '100%'}" [inputStyle]="{width: '100%'}">
      </p-inputNumber>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button (click)="currencyEditStatus=false" label="No" styleClass="p-button-text">
    </p-button>
    <p-button (click)="editCurrencyValidation()" label="Guardar" styleClass="p-button-success">
    </p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Borrar divisa" [(visible)]="currencyDeleteStatus" [modal]="true" [baseZIndex]="10001"
  [draggable]="false" [resizable]="false">
  <div style="padding: 8px;">¿Seguro que desea eliminar la divisa?</div>
  <ng-template pTemplate="footer">
    <p-button (click)="currencyDeleteStatus=false" label="No" styleClass="p-button-text">
    </p-button>
    <p-button (click)="deleteCurrency()" label="Si" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
