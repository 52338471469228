import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor(private loginservice: LoginService, private router: Router) { }

  mobDeviceFlag: boolean = false;

  ngOnInit(): void {
    if (window.innerWidth < 768) { this.mobDeviceFlag = true }
  }

  @HostListener('window:resize', ['$event'])
  onresize(event) {
    if (window.innerWidth < 768) { this.mobDeviceFlag = true }
    else { this.mobDeviceFlag = false }
  }

}
